<template>
  <div class="user-card-container" :style="{backgroundColor: role == 2 ? '#FFDBBC' : role == 4 ? '#F3F3F3' : '#c5c5c5'}">
    <div class="user-card-container__content">
        <img :src="imageUrl ? imageUrl : getImageUrl()" :alt="name" v-if="role == 4">
        <div class="user-card-container__content__text">
            <b>{{ name }}</b>
            <span v-if="role == 4" style="font-weight: 900;">{{ category }}</span>
            <span v-if="role == 4" style="font-size: 1.7rem;"><img src="@/assets/icons/geo-alt.svg" alt="">{{ city }}</span>
            <div class="line-text" v-if="role == 2 || role == 5">
                <span style="font-weight: 900;">{{ category }}</span>
                <span style="font-size: 1.7rem;"><img src="@/assets/icons/geo-alt-orange.svg" alt="">{{ city }}</span>
            </div>
            <span v-if="role == 2 || role == 5">{{ email }}</span>
        </div>
    </div>
    <button @click="() => $router.push(`/browse/user/${id}`)">Wizytówka</button>
  </div>
</template>

<script>
export default{
  name:'UserCard',
  data(){
    return{

    }
  },
  props:['imageUrl', 'name', 'category', 'city', 'id', 'role', 'email'],
  methods:{
    getImageUrl() {
        return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
    },
  }
}
</script>

<style scoped lang='scss'>
.user-card-container{
    box-shadow: -1px 1px 7px 0 #c9c9c9;
    width: 350px;
    border-radius: 23px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    &__content{
        display: flex;
        align-items: center;
        gap: 20px;
        img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }
        &__text{
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            min-height: 140px;
            b{
                font-size: 2.2rem;
                margin-bottom: 5px;
                text-align: center;
            }
            span{
                font-size: 1.9rem;
                display: flex;
                align-items: center;
                gap: 7px;
                img{
                    width: 20px;
                    height: 25px;
                }
            }
            .line-text{
                display: flex;
                gap: 10px;
            }
        }
    }
    button{
        background-color: #F2790F;
        border: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
    }
}
</style>