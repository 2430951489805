<template>
    <div class="price-list-edit-cont" v-if="currentUser">
        <h2>Podgląd cennika</h2>
        <PriceListVue/>
    </div>
  </template>
  
  <script>

import PriceListVue from '@/components/HomeComponents/biz/PriceList.vue';

  export default{
    name: "PriceListPreview",
    data() {
        return {
            
        };
    },
    props: ['isVisible'],
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {

    },
    components: { PriceListVue }
}
  </script>
  
  <style scoped lang="scss">

  </style>