<template>
  <header class="biz-hero-container">
    <div class="biz-hero-container__inner--left">
        <h2>Zacznij razem z nami</h2>
        <p>Prowadzisz gabinet weterynaryjny albo salon groomerski? Załóż konto usługodawcy w naszym serwisie. m2VET dla biznesu to narzędzie, które pozwoli Ci stworzyć swoją własną wizytówkę. Ponadto za pośrednictwem serwisu będziesz mógł/mogła prowadzić kalendarz, przez który twoi klienci będą mogli umawiać się na wizyty.</p>
        <img src="@/assets/laptop.png" class="biz-hero-container__inner--left__laptop-mobile" alt="">
        <div class="biz-hero-container__inner--left__scroll-down">
            <span>Zobacz naszą ofertę</span>
            <img src="@/assets/icons/arrow-down-short.svg" alt="">
        </div>
    </div>
    <div class="biz-hero-container__inner--right">
        <img src="@/assets/laptop.png" alt="">
    </div>
  </header>
</template>

<script>
export default{
  name:'BizHeroVue',
  data(){
    return{

    }
  },
  props:{

  }
}
</script>

<style scoped lang='scss'>
.biz-hero-container{
    display: flex;
    margin-top: 60px;
    &__inner{
        &--left{
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: center;
            align-items: center;
            &__laptop-mobile{
                display: none;
            }
            &__scroll-down{
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    width: 35px;
                }
            }
        }
        &--right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 80%;
            }
        }
    }
}

@media(max-width: 900px){
    .biz-hero-container{
        &__inner{
            &--left{
                width: 100%;
                text-align: center;
                &__laptop-mobile{
                    display: block;
                    width: 100%;
                    max-width: 500px;
                    margin-top: 30px;
                }
            }
            &--right{
                display: none;
            }
        }
    }
}
</style>