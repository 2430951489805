<template>
  <h3 style="color: #F2790F;">Uwaga! <br><span style="font-size: 1.8rem; color: #000;">Podane kwoty są kwotami netto</span></h3>
  <div class="price-list-container">
    <table class="price-list-container__table">
        <thead>
            <tr>
                <th>Opłaty i usługi <br> w abonamnecie</th>
                <th>Abonament free</th>
                <th class="wdth1" v-for="item, index in names" :key="index" v-html="item"></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>opłata abonamentowa</th>
                <td>-</td>
                <th class="wdth1" v-for="item, index in subscriptionsM" :key="index">{{ item.calc }} zł<br><span class="element-price">{{ item.unit }} zł / {{ item.unitPeriod ? item.unitPeriod : 'm-s' }}</span></th>
            </tr>
            <tr>
                <th>darmowych użytkowników w abonamencie:</th>
                <td>-</td>
                <th class="wdth1" v-for="item, index in freeUserses" :key="index">{{ item }}</th>
            </tr>
            <tr>
                <th>darmowych SMSów w abonamencie</th>
                <td>-</td>
                <th class="wdth1" v-for="item, index in freeSMSes" :key="index">{{ item }}</th>
            </tr>
            <tr>
                <th>dodatkowy użytkownik przypisany do konta</th>
                <td>-</td>
                <th class="wdth1" v-for="item, index in additionalUsersM" :key="index">{{ item.calc }} zł<br><span class="element-price">{{ item.unit }} zł / {{ item.unitPeriod ? item.unitPeriod : 'm-s' }}</span></th>
            </tr>
            <tr>
                <th>dodatkowe SMSy przypominające</th>
                <td>-</td>
                <th class="wdth1" v-for="item, index in additionalSMSes" :key="index">{{ Math.floor(item) }}zł i {{ item*100 % 100 }}gr / sms</th>
            </tr>
            <tr>
                <th>kalendarze i harmonogram pracy</th>
                <td>-</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
            <tr>
                <th>przypomnienia sms i e-mail</th>
                <td>-</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
            <tr>
                <th>profil firmy (wizytówka)</th>
                <td>tak</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
            <tr>
                <th>informacja o usługach</th>
                <td>-</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
            <tr>
                <th>możliwość rejestracji klientów poprzez profil firmy</th>
                <td>-</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
            <tr>
                <th>rejestracja online 24h / dobę</th>
                <td>-</td>
                <td v-for="x in names.length" :key="x">tak</td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
export default{
  name:'PriceList',
  data(){
    return{
        ids: [],
        names: [],
        subscriptionsM: [],
        additionalUsersM: [],
        additionalSMSes: [],
        freeUserses: [],
        freeSMSes: []
    }
  },
  props:{

  },
  components:{
    
  },
  mounted(){
    this.getSubscriptions()
  },
  methods:{
    getSubscriptions(){
        this.ids = []
        this.names =  []
        this.subscriptionsM =  []
        this.additionalUsersM =  []
        this.additionalSMSes =  []
        this.freeUserses =  []
        this.freeSMSes =  []

        axios.post('/SubscriptionSearch', {
            page: 1,
            limit: 10 
        }).then(res => {
            res.data.items.forEach(item => {
                this.ids.push(item.id)
                if(item.id == 1 || item.id == 6){
                    this.names.push(`${item.name}<br>(${item.period} mies)<br><i style="font-size: 1.4rem;color: #7a7a7a;">oferta ograniczona ilościowo i czasowo</i>`)
                }else{
                    this.names.push(`${item.name}<br>(${item.period} mies)`)
                }
                if(item.id == 3){
                    this.subscriptionsM.push({calc: item.subscriptionM*item.period, unit: item.subscriptionM*12, unitPeriod: '12m-cy'})
                    this.additionalUsersM.push({calc: item.additionalUserM*item.period, unit: item.additionalUserM*12, unitPeriod: '12m-cy'})
                }else{
                    this.subscriptionsM.push({calc: item.subscriptionM*item.period, unit: item.subscriptionM})
                    this.additionalUsersM.push({calc: item.additionalUserM*item.period, unit: item.additionalUserM})
                }
                this.additionalSMSes.push(item.additionalSMS)
                this.freeUserses.push(item.freeUsers)
                this.freeSMSes.push(item.freeSMS)
            })
        }).catch(() => {
            
        })
    }
  }
}
</script>

<style scoped lang='scss'>
.price-list-container{
    overflow-x: scroll;
    &__table td, th{
        border: 1px solid rgb(189, 189, 189);
    }
    &__table{
        min-width: 1500px;
        border-spacing: 10px;
        tr{
            td, th{
                padding: 8px 5px;
                text-align: center;
                margin: 5px;
                border-radius: 8px;
            }
            td:hover{
                border: 1px solid #F2790F;
            }
            th:hover{
                border: 1px solid #F2790F;
            }
        }
    }
}

.wdth1{
    width: 190px;
}

.price-list-container::-webkit-scrollbar{
    width: 3px;
}
.price-list-container::-webkit-scrollbar-track {
background: #fff; 
}
.price-list-container::-webkit-scrollbar-thumb {
background: #888; 
border-radius: 4px;
}
span.element-price{
    font-size: 1.7rem;
    color: #7a7a7a;
}
</style>