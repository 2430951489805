<template>
    <div v-if="currentUser && reportData" class="report-preview-admin">
        <h2>Szczegóły zgłoszenia</h2>
        <div class="report-preview-admin__separate-line"></div>
        <b>Komentarz</b>
        <p class="report-preview-admin__comment">{{ reportData.comment }}</p>
        <div class="report-preview-admin__separate-line"></div>
        <b>Zgłaszający</b>
        <div class="report-preview-admin__sender" v-if="sender">
            <span><b>użytkownik:</b> {{ sender.role.id == 3 ? 'prywatny' : 'biznesowy' }}</span>
            <span><b>email:</b> {{ sender.email }}</span>
            <span><b>telefon:</b> {{ sender.phone ? sender.phone : 'brak' }}</span>
        </div>
        <div class="report-preview-admin__separate-line"></div>
        <b>{{ reportData.reportLabel }}</b>
        <ServiceModal/>
        <div v-if="reportItemData" class="report-preview-admin__report-item">  
            <AnnouncementCardVue :show-active="true" v-if="reportData.announcement_id" :key="reportItemData.id" :contact="reportItemData.contact" :target="reportItemData.target" :category="reportItemData.category" :img="reportItemData.photo_url" :longContent="reportItemData.description" :id="reportItemData.id" :title="reportItemData.title" :content="reportItemData.short_description" :photo="reportItemData.photo" :active="reportItemData.active" />
            <ServiceCardVue v-if="reportData.service_id" @openServiceModal="() => $router.push({path: `/admin-profile/reports/${reportData.id}`, query: {serviceID: reportItemData.id}})" :price="reportItemData.price" :key="reportItemData.id" :is-advance="reportItemData.advanceAmount ? true : false" :img="reportItemData.photo_url" :name="reportItemData.name" :state="states.filter(state => state.value == reportItemData.state_id)[0].name" :ishighlighted="reportItemData.active"/>
            <!-- :category="categories.filter(category => category.id == reportItemData.user.category_id)[0].name" -->
        </div>
    </div>
</template>

<script>
import AnnouncementCardVue from '@/components/SpecialComponents/AnnouncementCard.vue';
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import authHeader from '@/services/auth-header';
import axios from 'axios';

export default{
    name: "AdminReportPreview",
    data() {
        return {
            reportData: null,
            reportItemData: null,
            states: [],
            categories: [],
            sender: null
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
        this.getReportData();
        this.getStates()
        this.getCategories()
    },
    methods: {
        getReportData() {
            axios.get(`/Report/${this.$route.params.reportId}`, authHeader()).then(res => {
                this.reportData = res.data;
                if (res.data.announcement_id) {
                    this.reportData.reportItemId = res.data.announcement_id;
                    this.reportData.reportUrl = `/Announcement`;
                    this.reportData.reportLabel = `Ogłoszenie`;
                }
                else {
                    this.reportData.reportItemId = res.data.service_id;
                    this.reportData.reportUrl = `/Service`;
                    this.reportData.reportLabel = `Usługa`;
                }
            }).then(() => {
                axios.get(`${this.reportData.reportUrl}/${this.reportData.reportItemId}`).then(res => {
                    this.reportItemData = res.data
                });
            }).then(() => {
                axios.get(`/User/${this.reportData.sender_id}`).then(res => {
                    this.sender = res.data
                })
            }).catch(() => {
                
            })
        },
        getStates(){
        axios.get('/State').then(res => {
            res.data.forEach((item) => {
                const ob = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.states.push(ob)
            })
        })
    },
    getCategories(){
      axios.get('/Categories').then(res => {
        res.data.forEach((item) => {
            const ob  = {
                name: item.name,
                id: `${item.id}`
            }
            this.categories.push(ob)
        })
      })
    }
    },
    components: { AnnouncementCardVue, ServiceCardVue, ServiceModal }
}
</script>

<style scoped lang="scss">
.report-preview-admin{
    display: flex;
    align-items: center;
    flex-direction: column;
    b{
        font-size: 2.2rem;
    }
    &__separate-line{
        width: 90%;
        max-width: 500px;
        height: 1px;
        background-color: #000;
        margin: 10px 0;
    }
    &__comment{
        text-align: center;
    }
    &__report-item{
        margin: 10px 0;
    }
    &__sender{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 15px;
    }
}
</style>