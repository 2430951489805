<template>
    <div v-if="currentUser" class="regulations-edit-wrapper">
        <h2>Edycja regulaminów</h2>
        <select class="regulations-edit-wrapper__document-type-select" name="documentType" id="documentType" v-model="documentType">
            <option value="regulations">Regulamin</option>
            <option value="privacy">Polityka Prywatności</option>
            <option value="cookies">Polityka Plików cookies</option>
        </select>
        <div class="regulations-edit-wrapper__document-container" v-if="documents.cookies && documents.regulations && documents.privacy">
            <!-- <h3>{{ documentType == 'regulations' ? 'Regulamin' : documentType == 'privacy' ? 'Polityka prywatności' : 'Polityka plików cookie' }}</h3> -->
            <button @click="editRegulations" class="regulations-edit-wrapper__document-container__btn">zatwierdź zmiany</button>
            <ckeditor :editor="editor" v-model="documents[documentType][0].content" :config="editorConfig"></ckeditor>
            <button @click="editRegulations" class="regulations-edit-wrapper__document-container__btn">zatwierdź zmiany</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic'
import { Font } from '@ckeditor/ckeditor5-font'
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { List } from '@ckeditor/ckeditor5-list';
import { Image, ImageInsert } from '@ckeditor/ckeditor5-image';
import authHeader from '@/services/auth-header';

export default{
    name:"RegulationsEdit",
    data(){
        return{
            documentType: 'regulations',
            documents:{
                regulations: null,
                privacy: null,
                cookies: null
            },
            editor: ClassicEditor,
            editorConfig: {
                plugins: [ Font, Essentials, Bold, Italic, Link, Paragraph, Heading, BlockQuote, List, ImageInsert, Image ],
                fontColor:{
                    colors: [
                        {
                            color: '#ff0000',
                            label: 'red'
                        },
                        {
                            color: '#00ff00',
                            label: 'green'
                        },
                        {
                            color: '#0000ff',
                            label: 'blue'
                        }
                    ]
                },
                toolbar: [
                    'undo', 'redo', 'heading', 'bold', 'italic', 'fontColor', 'blockQuote', 'bulletedList', 'numberedList', 'link', 'insertImage',
                ]
            }
        }
    },
    props:['isVisible'],
    mounted(){
        if (!this.currentUser) {
            this.$router.push('/login');
        }else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
        this.getDocuments()
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods:{
        getDocuments(){
            axios.get('/CmsAll').then(res => {
                this.documents.regulations = res.data.filter(item => item.title == 'Regulamin')
                this.documents.privacy = res.data.filter(item => item.title == 'Polityka prywatności')
                this.documents.cookies = res.data.filter(item => item.title == 'Polityka plików cookie')
            })
        },
        editRegulations(){
            const ob = {
                id: this.documents[this.documentType][0].id,
                content: this.documents[this.documentType][0].content,
                title: this.documents[this.documentType][0].title
            }
            axios.post('/Cms', ob, authHeader()).then(() => {
                this.$store.commit('setIsSuccess', `Pomyślnie zmieniono: ${this.documents[this.documentType][0].title}`)
            })
        }
    }  
}
</script>

<style lang="scss" scoped> 
.regulations-edit-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__document-type-select{
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #b4b4b4;
    }
    &__document-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        &__btn{
            padding: 5px 10px;
            border-radius: 5px;
            border: none;
            background-color: #F27A07;
        }
    }
}
</style>