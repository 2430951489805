<template>
  <div class="alert-container active-bounce-in-fwd" v-if="$store.state.isAlert">
    <span>{{ $store.state.alertContent ? $store.state.alertContent : 'Wystąpił błąd. Spróbuj ponownie później.' }}</span>
    <img src="@/assets/icons/exclamation-triangle-fill.svg" alt="">
  </div>
</template>

<script>
export default{
  name:'AlertVue',
  data(){
    return{

    }
  },
  props:{

  },
}
</script>

<style scoped lang='scss'>
.alert-container{
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #BB2D3B;
    padding: 8px 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 400px;
    box-shadow: 0px 0px 5px 0px #4a4a4a;
    z-index: 20000;
    img{
        width: 25px;
        height: 25px;
    }
    span{
      font-size: 1.6rem;
    }
}

.active-bounce-in-fwd{
    animation: bounce-in-fwd 1.1s both;
}

@keyframes bounce-in-fwd {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: scale(1);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: scale(0.7);
    animation-timing-function: ease-in;
  }
  72% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  81% {
    transform: scale(0.84);
    animation-timing-function: ease-in;
  }
  89% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  95% {
    transform: scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
</style>