<template>
    <div class="page-reviews" v-if="currentUser">
        <h2>Oceny serwisu</h2>
        <span>wybierz opinię</span>
        <DropdownVue :items="cards" placeholder="kafelek" id="serviceReviewsDropdown" @setChecked="(e) => setCurrentReview(e)" :border="true"/>
        <form class="page-reviews__review-card-preview" v-if="currentReview" @submit.prevent="editReview">
            <input type="text" id="title" name="title" placeholder="tytuł" maxlength="50" minlength="10" v-model="currentReview.title" required>
            <textarea id="content" name="content" cols="30" rows="10" maxlength="300" minlength="10" v-model="currentReview.content" required></textarea>
            <div>
                <input type="number" name="rate" id="rate" max="6" min="1" v-model="currentReview.rate">
                <span> / 6</span>
            </div> 
            <button>Zatwierdź</button>
        </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import DropdownVue from '@/components/Dropdown.vue';
  import authHeader from '@/services/auth-header';
  export default{
    name:"PageReviews",
    data(){
        return{
            cards:[
                {
                    name: 'kafelek 1',
                    value: '1'
                },
                {
                    name: 'kafelek 2',
                    value: '2'
                },
                {
                    name: 'kafelek 3',
                    value: '3'
                },
                {
                    name: 'kafelek 4',
                    value: '4'
                }
            ],
            card: '',
            currentReview: null,
            reviews: []
        }
    },
    props:['isVisible'],
    mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
      this.getReviews()
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    methods:{
        getReviews(){
            axios.get('/CmsAll').then(res => {
                this.reviews = res.data.filter(item => item.title.includes('Opinia'))
                this.currentReview = {
                    title: this.reviews[0].content.split('|')[0],
                    content: this.reviews[0].content.split('|')[2],
                    rate: this.reviews[0].content.split('|')[1],
                    id: this.reviews[0].id,
                    independentTitle: this.reviews[0].title
                }
            }).catch(() => {
                
            })
        },
        setCurrentReview(e){
            if(e !== 'placeholder'){
                this.currentReview = {
                    title: this.reviews.filter(rev => rev.title.includes(e))[0].content.split('|')[0],
                    content: this.reviews.filter(rev => rev.title.includes(e))[0].content.split('|')[2],
                    rate: this.reviews.filter(rev => rev.title.includes(e))[0].content.split('|')[1],
                    id: this.reviews.filter(rev => rev.title.includes(e))[0].id,
                    independentTitle: this.reviews.filter(rev => rev.title.includes(e))[0].title
                }
            }
        },
        editReview(){
            if(this.currentReview){
                if(this.currentReview.title.includes('|') || this.currentReview.content.includes('|')){
                    this.$store.commit('setIsAlert', 'żadne z pól nie może zawirać znaku: |')
                }else{
                    axios.post('/Cms', {
                        id: this.currentReview.id,
                        title: this.currentReview.independentTitle,
                        content: `${this.currentReview.title}|${this.currentReview.rate}|${this.currentReview.content}`
                    }, authHeader()).then(res => {
                        if(String(res.status).charAt(0) == '2'){
                            this.reviews.filter(item => item.id == this.currentReview.id)[0].content = `${this.currentReview.title}|${this.currentReview.rate}|${this.currentReview.content}`
                            this.$store.commit('setIsSuccess', `Zaktualizowano kafelek ${this.currentReview.independentTitle.slice(7)}`)
                        }
                    }).catch(() => {
                        
                    })
                }
            }
        }
    },
    components:{
        DropdownVue
    }
  }
  </script>
  
  <style scoped lang="scss">
    .page-reviews{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        &__review-card-preview{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            *:not(span):not(div){
                border: 1px solid #b1b1b1;
                padding: 8px;
                border-radius: 8px;
            }
            textarea{
                width: 400px;
                height: 200px;
                resize: vertical;
            }
        }
    }
  </style>