import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import axios from 'axios';
import { createMetaManager } from 'vue-meta';
import { createStore } from 'vuex';
import { auth } from './store/auth.module';
import CKEditor from '@ckeditor/ckeditor5-vue';

const user = JSON.parse(localStorage.getItem('userm2VET'));

const store = createStore({
  state() {
    return {
      isBusiness: false,
      isAlert: false,
      alertContent: '',
      isSuccess: false,
      successContent: '',
      resetPassEmail: '',
      isSubscribed: false,
    };
  },
  mutations: {
    setIsBusiness(state, value) {
      if (value == 'true') {
        state.isBusiness = false;
      } else if (value == 'false') {
        state.isBusiness = true;
      }
      router.push(`${state.isBusiness ? '/dla-biznesu' : '/'}`);
    },
    setIsAlert(state, value) {
      state.isAlert = true;
      state.alertContent = value;
      setTimeout(() => {
        state.isAlert = false;
      }, 5000);
    },
    setIsSuccess(state, value) {
      state.isSuccess = true;
      state.successContent = value;
      setTimeout(() => {
        state.isSuccess = false;
      }, 5000);
    },
    setResetPassEmail(state, value) {
      state.resetPassEmail = value;
    },
    setIsSubscribed(state, user) {
      // console.log('subscription tst');
      axios.get(`/User/${user.data.user_id}`).then((res) => {
        state.isSubscribed = res.data.subscription ? true : false;
      });
    },
  },
  modules: {
    auth,
  },
});

if (user) {
  store.commit('setIsSubscribed', user);
}

// axios.create({
//     baseURL: process.env.VUE_APP_API_BASE_URL,
// })

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.response.use(
  (response) => {
    if (response.config.url.includes('/login') && response.status == 200) {
      store.commit('setIsSubscribed', response.data)
    }
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.config.url.includes('/login')) {
        store.commit(
          'setIsAlert',
          'Twoje konto jest nieaktywne. Możesz je aktywować za pomocą linku wysłanego na podany adres email'
        );
      } else {
        router.push('/login').then(() => {
          store.commit('setIsAlert', 'Inna sesja jest aktywna. Wyloguj się');
          localStorage.removeItem('userm2VET');
          store.commit('auth/logout');
        });
      }
    } else if (error.response.status === 400) {
      if(error.config.url.includes('/CalendarItemAddUser')){
        store.commit('setIsAlert', 'Wystąpił błąd, bądź usługodawca nie może przyjąć zaliczki. Skontaktuj się z nim.')
      }else if(error.config.url.includes('/AddBusinessToFirm')){
        store.commit('setIsAlert', 'Nie znaleziono specjalisty, bądź użytkownik otrzymał już zaproszenie.')
      }else{
        store.commit('setIsAlert');
      }
    } else if (error.response.status === 429) {
      store.commit('setIsAlert', 'Błąd, spróbuj ponownie później');
    } else if (
      error.response.status === 404 &&
      error.config.url.includes('/login')
    ) {
      store.commit('setIsAlert', 'Zły adres e-mail lub hasło');
    } else if (error.response.status === 500){
      if(error.config.url.includes('/AddBusinessToFirmConfirmation')){
        store.commit('setIsAlert', 'Niepoprawny numer zaproszenia.');
      }else{
        store.commit('setIsAlert');
      }
    }
    // store.commit('setIsAlert');
  }
);

createApp(App)
  .use(router)
  .use(createMetaManager())
  .use(store)
  .use(CKEditor)
  .mount('#app');
