<template>
    <div class="not-found-cont">
        <img src="@/assets/logo.png" alt="M2Vet">
        <h1>Nie znaleziono strony, którą szukasz</h1>
        <h3>Upewnij się, że wpisałeś/aś prawidłowy adres</h3>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
export default{
    name:"NotFound",
    setup () {
        useMeta({title: '404'})
    },
    data(){
        return{

        }
    },
    props:{

    },
    mounted(){

    },
    methods:{

    }  
}
</script>

<style scoped>
.not-found-cont{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found-cont > img{
    width: 150px;
}
</style>