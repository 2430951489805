<template>
  <div class="sub-plan-card-cont">
    <div class="sub-plan-card-cont__btns" v-if="!admin">
        <button @click="turnToEditMode" v-if="!editMode"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
        <button @click="deleteSubPlan"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
    </div>
    <span v-if="!editMode" class="sub-plan-card-cont__title">{{ name }}</span>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>opłata abonamentowa</span>
        <span>{{ subscriptionM }}zł / miesiąc</span>
    </div>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>opłata za dodatkowego przypisanego użytkownika</span>
        <span>{{ additionalUserM }}zł / miesiąc</span>
    </div>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>opłata za wysłanie dodatkowego SMSa</span>
        <span>{{ Math.floor(additionalSMS) }}zł i {{ additionalSMS*100 % 100 }}gr / miesiąc</span>
    </div>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>ilość darmowych użytkowników</span>
        <span>{{ freeUsers }}</span>
    </div>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>ilość darmowych SMSów</span>
        <span>{{ freeSMS }}</span>
    </div>
    <div v-if="!editMode" class="sub-plan-card-cont__price">
        <span>czas trwania (w miesiącach)</span>
        <span>{{ period }}</span>
    </div>
    <button v-if="!editMode && admin" @click="() => $router.push({path: `${$route.path}`, query: {subId: id}})" class="sub-plan-card-cont__btn">Wybieram</button>
    <form @submit.prevent="editSubPlan" v-if="editMode">
        <label for="name">Nazwa planu</label>
        <input type="text" id="name" v-model="editSubData.name" name="name" required>

        <label for="subscription">Opłata abonamentowa (na miesiąc)</label>
        <input type="number" step="0.01" v-model="editSubData.subscriptionM" id="subscription" name="subscription" required>

        <label for="additionalUser">Opłata za dodatkowego użytkownika (na miesiąc)</label>
        <input type="number" step="0.01" v-model="editSubData.additionalUserM" id="additionalUser" name="additionalUser" required>

        <label for="additionalSMS">Opłata za wysłanie SMSa (w złotówkach)</label>
        <input type="number" step="0.01" v-model="editSubData.additionalSMS" id="additionalSMS" name="additionalSMS" required>

        <label for="freeUsers">Ilość darmowych użytkowników przypisanych do konta</label>
        <input type="number" v-model="editSubData.freeUsers" id="freeUsers" name="freeUsers" required>

        <label for="freeSMS">Ilość darmowych SMSów do wysłania</label>
        <input type="number" v-model="editSubData.freeSMS" id="freeSMS" name="freeSMS" required>

        <label for="period">Czas trwania subskrybcji (w miesiącach). <br> W cenniku wyświetlana będzie kwota <br> pomnożona przez podaną tutaj ilość miesięcy.</label>
        <input type="number" v-model="editSubData.period" id="period" name="period" required>

        <button type="submit" class="sub-plan-card-cont__btn">Edytuj</button>
    </form>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
  name:'SubPlanCard',
  data(){
    return{
        editMode: false,
        editSubData:{
            id: '',
            name: '',
            subscriptionM: '',
            additionalUserM: '',
            additionalSMS: '',
            freeUsers: '',
            freeSMS: '',
            period: ''
        }
    }
  },
  props:['id', 'name', 'subscriptionM', 'additionalUserM', 'additionalSMS', 'freeUsers', 'freeSMS', 'period', 'admin'],
  mounted(){
    
  },
  methods:{
    turnToEditMode(){
        this.editMode = true
        this.editSubData.id = this.id
        this.editSubData.name = this.name
        this.editSubData.subscriptionM = this.subscriptionM
        this.editSubData.additionalUserM = this.additionalUserM
        this.editSubData.additionalSMS = this.additionalSMS
        this.editSubData.freeUsers = this.freeUsers
        this.editSubData.freeSMS = this.freeSMS
        this.editSubData.period = this.period
    },
    editSubPlan(){
        axios.post('/Subscription', this.editSubData, authHeader()).then(() => {
            this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano plan')
            this.editMode = false
            this.$emit('refreshSubscriptions')
        }).catch(() => {
            
        })
    },
    deleteSubPlan(){
        const question = prompt('Czy na pewno? Wpisz "tak", jeśli tak.')
        if(question == 'tak'){
            axios.delete(`/Subscription/${this.id}`, authHeader()).then(() => {
                this.$store.commit('setIsSuccess', 'Pomyślnie usunięto plan')
                this.$emit('refreshSubscriptions')
            }).catch(() => {
                
            })
        }
    }
  }
}
</script>

<style scoped lang='scss'>
.sub-plan-card-cont{
    background-color: #F3F3F3;
    box-shadow: -1px 1px 7px 0px #c9c9c9;
    max-width: 270px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    &__btns{
        display: flex;
        justify-content: space-evenly;
        button{
            background: none;
            border: none;
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    &__price{
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        span:first-child{
            font-size: 1.7rem;
        }
        span:last-child{
            font-weight: 900;
        }
    }
    &__title{
        text-align: center;
        font-size: 2.5rem;
    }
    &__btn{
        background-color: #F27A07;
        border: none;
        padding: 10px 30px;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 20px;
    }
    form{
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        input{
            margin-bottom: 10px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #b1b1b1;
            max-width: 400px;
            width: 100%;
        }
        input[type="number"]{
            width: 100px;
        }
        label{
            text-align: center;
        }
    }
}
</style>