<template>
    <div v-if="currentUser" class="usefultips-container">
      <h2>Porady</h2>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  export default{
    name:'UsefulTips',
    setup () {
      useMeta({title: 'Porady'})
    },
    data(){
      return{

      }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
      },
      mounted() {
        if (!this.currentUser) {
          this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 2) {
          this.$router.push('/login');
        }
        if(!this.$store.state.isSubscribed){
          this.$router.push('/dla-biznesu/profile/user-data')
        }
      },
    props:{
  
    },
    methods:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
  </style>