<template>
    <h2>Dodaj ogłoszenie</h2>
    <div class="add-announcement-wrapper">        
        <form class="add-announcement-wrapper__form" @submit="(e) => addAnnouncement(e)">
            <input type="text" placeholder="tytuł" v-model="announcement.title" name="title" required maxlength="50" minlength="3">
            <textarea placeholder="krótki opis" v-model="announcement.short_description" name="short_description" required maxlength="350" minlength="20"></textarea>
            <textarea placeholder="treść" v-model="announcement.content" name="content" required maxlength="3000" minlength="80"></textarea>
            <input type="email" placeholder="adres e-mail do kontaktu" autocomplete="email" v-model="announcement.contact" maxlength="50" required>
            <label>odbiorcy i kategoria</label>
            <select name="target" id="target" v-model="announcement.target" required>
              <option>do wszystkich</option>
              <option>do specjalistów</option>
              <option>do właścicieli zwierząt</option>
            </select>
            <select name="category" id="category" v-model="announcement.category" required>
              <option>sprzedaż</option>
              <option>współpraca</option>
              <option>wymiana</option>
              <option>inne</option>
            </select>
            <label for="announcementImage">zdjęcie wyróżniające</label>
            <input type="file" id="announcementImage" @change="(e) => onFileUpload(e)" accept="image/png, image/jpeg">
            <button type="submit">Zatwierdź</button>
        </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMeta } from 'vue-meta';
  import authHeader from '@/services/auth-header';
  export default{
    name:'AddAnnouncementVue',
    setup () {
      useMeta({ title: 'Dodaj ogłoszenia' });
    },
    data(){
      return{
        announcement:{
            title:'',
            short_description: '',
            content: '',
            contact: '',
            photo: null,
            target: 'do wszystkich',
            category: 'sprzedaż'
        }
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 3) {
        this.$router.push('/login');
      }
    },
    methods:{
        onFileUpload (e) {
            this.announcement.photo = e.target.files[0]
        },
        addAnnouncement(e){
            e.preventDefault()
            if(this.announcement.photo){
              const ob = {
                title: this.announcement.title,
                short_description: this.announcement.short_description,
                description: this.announcement.content,
                photo: this.announcement.photo,
                contact: this.announcement.contact,
                target: this.announcement.target,
                category: this.announcement.category,
                active: 0
              }
              axios.post('/Announcement', ob,{ 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }).then(res => {
                if(String(res.status).charAt(0) == '2'){
                  this.$store.commit('setIsSuccess', 'Pomyślnie dodano ogłoszenie')
                  this.$router.push('/profile/announcements/')
                }
              })
            }else{
              this.$store.commit('setIsAlert', 'wgraj zdjęcie')
            }
        }
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .add-announcement-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 500px;
            gap: 10px;
            input{
                width: 100%;
                border: 1px solid #B1B1B1;
                padding: 8px;
                border-radius: 8px;
            }
            select{
              width: 100%;
              border: 1px solid #B1B1B1;
              padding: 8px;
              border-radius: 8px;
            }
            textarea{
                resize: vertical;
                width: 100%;
                border: 1px solid #B1B1B1;
                padding: 8px;
                border-radius: 8px;
            }
            label{
                margin-top: 20px;
            }
            button{
                margin-top: 20px;
                background-color: #f2790f;
                padding: 8px 15px;
                border-radius: 8px;
                border: none;
                cursor: pointer;
            }
        }
    }
  </style>