<template>
    <div>
        <div class="statute-body">
            <h2>Regulamin serwisu</h2>
            <div v-html="statute" v-if="statute">

            </div>
            <div v-else>
                <span>Ładowanie...</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useMeta } from 'vue-meta';
export default{
    name:"StatuteVue",
    setup () {
    useMeta({
      title: 'Strona główna',
      meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
    })
  },
    data(){
        return{
            statute: ''
        }
    },
    props:{

    },
    mounted(){
        this.getStatuteData()
    },
    methods:{
        getStatuteData(){
            window.scrollTo(0,0)
            axios.get(`${process.env.VUE_APP_API_BASE_URL}CmsAll`)
                .then(res => {
                    this.statute = res.data.filter(item => item.title == 'Regulamin')[0].content
                })
        }
    }  
}
</script>

<style scoped>
.statute-body{
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
    min-height: 500px;
}
</style>