import axios from 'axios';
import authHeader from './auth-header';

class AuthService {
  login(user) {
    return axios
      .post(process.env.VUE_APP_API_BASE_URL + 'login', {
        login: user.login,
        password: user.password
      })
      .then(response => {
        if (response.data.data.token) {
          localStorage.setItem('userm2VET', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    axios.get(process.env.VUE_APP_API_BASE_URL + 'logout', authHeader()).then(() => {
      localStorage.removeItem('userm2VET');
    }).catch(() => {
      localStorage.removeItem('userm2VET');
    })
  }

  register(user) {
    return axios.post(process.env.VUE_APP_API_BASE_URL + 'User', {
      firstname: user.firstname,
      lastname: user.lastname,
      name: user.name,
      email: user.email,
      role_id: user.role_id,
      phone: user.phone,
      category_id: user.category_id,
      password: user.password,
      recommender_email: user.recommender_email
    });
  }
}

export default new AuthService();