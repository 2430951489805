<template>
  <div class="announcement-container" :class="removable || editable ? 'add-padding-right' : ''">
    <button class="announcement-container__remove-btn" v-if="removable" @click="() => $emit('deleteAnnouncement', id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
    <button class="announcement-container__edit-btn" v-if="editable" @click="() => $emit('showEditModal', {titleValue:title, contentValue:longContent, shortDescriptionValue: content, id:id, target: target, contact: contact, category: category})"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
    <button class="announcement-container__active-tag" v-if="showActive"><img src="@/assets/icons/eye-fill.svg" alt="aktywne" v-if="active"><img src="@/assets/icons/eye-slash-fill.svg" alt="nieaktywne" v-if="!active"></button>
    <button class="announcement-container__edit-btn" v-if="showVerify && !active" @click="() => $emit('verifyAnnouncement', {id: id})"><img src="@/assets/icons/check.svg" alt="weryfikuj"></button>
    <img :src="img" alt="">
    <div class="announcement-container__inner">
      <span class="announcement-container__inner__tag">{{target}}</span>
      <span class="announcement-container__inner__tag">{{category}}</span>
        <b>{{ title }}&nbsp;&nbsp;<ButtonVue :text="isContentVisible ? 'mniej' : 'więcej'" bg-color="#f2790f" color="#000" style="font-size: 1.5rem;" @click="checkUser()"/></b>
        <span>{{ content }}</span>
        <p v-if="isContentVisible">{{ longContent }}<br><br><span class="report-btn" role="button" @click="() => showContact = !showContact">{{ showContact ? contact : 'pokaż adres e-mail' }}</span><br><br><span class="report-btn" v-if="currentUser && (currentUser.data.role == 3 || currentUser.data.role == 2)" role="button" @click="() => report()">zgłoś</span></p>
    </div>
  </div>
</template>

<script>
import ButtonVue from '../Button.vue';
export default{
  name:'AnnouncementCardVue',
  data(){
    return{
      isContentVisible: false,
      showContact: false
    }
  },
  computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
  },
  methods:{
    report(){
      const route = this.$router.resolve({path: '/report', query: {label: `ogłoszenie: ${this.title}`, id: this.id, property: 'announcement_id'}})
      window.open(route.href, '_blank')
    },
    checkUser(){
      if(this.currentUser){
        this.isContentVisible = !this.isContentVisible
      }else{
        this.$router.push('/login')
      }
    }
  },
  props:['img', 'title', 'content', 'removable', 'id', 'editable', 'longContent', 'contact', 'target', 'category', 'active', 'showActive', 'showVerify'],
  components:{
    ButtonVue
  },
}
</script>

<style scoped lang='scss'>
.announcement-container{
    display: flex;
    background-color: #fff;
    box-shadow: -1px 1px 7px 0px #c9c9c9;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    width: 95%;
    max-width: 750px;
    img{
        width: 25%;
        height: 150px;
        object-fit: cover;
    }
    &__inner{
        width: 75%;
        padding: 15px;
        b{
            font-size: 2.5rem;
            display: block;
            margin-bottom: 10px;
            margin-top: 10px;
            button{
              margin: 10px 0;
            }
        }
        span{
            font-size: 1.7rem;
        }
        p{
          font-size: 1.7rem;
          margin: 10px 0;
        }
        &__tag{
          background-color: #77B43F;
          padding: 5px;
          margin: 5px;
          border-radius: 5px;
        }
    }
    &__remove-btn{
      position: absolute;
      background: none;
      border: none;
      top: 10px;
      right: 10px;
      cursor: pointer;
      img{
        width: 25px;
        height: 25px;
      }
    }
    &__edit-btn{
      position: absolute;
      background: none;
      border: none;
      bottom: 10px;
      right: 10px;
      cursor: pointer;
      img{
        width: 20px;
        height: 20px;
      }
    }
    &__active-tag{
      position: absolute;
      background: none;
      border: none;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      img{
        width: 20px;
        height: 20px;
      }
    }
}

.add-padding-right{
  padding-right: 30px;
}

@media (max-width: 670px){
  .announcement-container{
    align-items: center;
    flex-direction: column;
    img{
      width: 180px;
    }
    &__inner{
      .b-mobile{
        display: inline-block !important;
        font-size: 1.8rem;
      }
    }
    &__remove-btn{
      img{
        width: 25px;
        height: 25px;
      }
    }
    &__edit-btn{
      img{
        width: 25px;
        height: 25px;
      }
    }
  }
  .add-padding-right{
    padding-right: 0;
  }
}
.report-btn{
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem !important;
}
</style>