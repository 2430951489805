<template>
    <div class="price-list-edit-cont" v-if="currentUser">
        <h2>Cennik</h2>
        <div>
            <button v-if="subscriptions.length <= 10" @click="$router.push('/admin-profile/price-list/add')" class="price-list-edit-cont__add-btn">Dodaj plan</button>
            <button @click="$router.push('/admin-profile/price-list-preview')" class="price-list-edit-cont__preview-btn">Podgląd cennika</button>
        </div>
        <div class="price-list-edit-cont__plans" v-if="subscriptions.length > 0">
            <SubPlanCard v-for="sub in subscriptions" @refreshSubscriptions="getSubscriptions" :id="sub.id" :name="sub.name" :key="sub.id" :subscription-m="sub.subscriptionM" :additional-s-m-s="sub.additionalSMS" :additional-user-m="sub.additionalUserM" :free-users="sub.freeUsers" :free-s-m-s="sub.freeSMS" :period="sub.period" />
        </div>
    </div>
  </template>
  
  <script>
import SubPlanCard from '@/components/SpecialComponents/SubPlanCard.vue';
import axios from 'axios';

//   import axios from 'axios';
//   import authHeader from '@/services/auth-header';
  export default{
    name: "SepcsEdit",
    data() {
        return {
            subscriptions: []
        };
    },
    props: ['isVisible'],
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
        this.getSubscriptions()
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        getSubscriptions(){
            axios.post('/SubscriptionSearch', {
               page: 1,
               limit: 10 
            }).then(res => {
                this.subscriptions = res.data.items
            }).catch(() => {
                
            })
        }
    },
    components: { SubPlanCard }
}
  </script>
  
  <style scoped lang="scss">
    .price-list-edit-cont{
        &__add-btn{
            background-color: #F27A07;
            border: none;
            padding: 10px 30px;
            border-radius: 8px;
            cursor: pointer;
        }
        &__preview-btn{
            background-color: #5EA01A;
            border: none;
            padding: 10px 30px;
            border-radius: 8px;
            cursor: pointer;
            margin-left: 10px;
        }
        &__plans{
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            justify-content: center;
            margin-top: 30px;
        }
    }
  </style>