<template>
  <div class="email-preview-modal-container" v-if="isVisible">
    <span>{{ email ? email : null }}</span>
    <button @click="() => $emit('closeEmailModal')"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
  </div>
</template>

<script>
export default{
  name:'EmailPreviewModal',
  data(){
    return{

    }
  },
  props: ['email', 'isVisible'],
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped lang='scss'>
.email-preview-modal-container{
    border-radius: 0 0 10px 10px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    background-color: #fff;
    padding: 15px;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #000;
    overflow-x: scroll;
    width: 80%;
    max-width: 360px;
    button{
        background: none;
        border: none;
        cursor: pointer;
        img{
            width: 25px;
        }
    }
    @media(max-width: 430px){
        justify-content: flex-start;
    }
}
.email-preview-modal-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.email-preview-modal-container::-webkit-scrollbar-track {
  width: 5px;
}
 
/* Handle */
.email-preview-modal-container::-webkit-scrollbar-thumb {
  background: #888; 
  width: 5px;
}

/* Handle on hover */
.email-preview-modal-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>