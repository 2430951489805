<template>
  <form class="contact-form-container" @submit="sendMessage">
    <input type="text" class="contact-form-container__input" name="name" id="name" placeholder="Imię*" v-model="contactName" required>
    <input type="email" class="contact-form-container__input" name="email" id="email" placeholder="Adres e-mail*" v-model="contactEmail" required>
    <input type="tel" class="contact-form-container__input" name="tel" id="tel" placeholder="Numer telefonu" v-model="contactPhone">
    <input type="text" class="contact-form-container__input" name="subject" id="subject" placeholder="Temat wiadomości" v-model="contactSubject">
    <textarea name="content" id="content" class="contact-form-container__ta" placeholder="Treść wiadomości*" v-model="contactMessage" required></textarea>
    <div class="contact-form-container__alert-button">        
        <span ref="contactFormAlert"></span>
        <button type="submit">Wyślij wiadomość</button>
    </div>
</form>
</template>

<script>
import axios from 'axios'

export default{
  name:'ContactForm',
  data(){
    return{
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        contactSubject: '',
        contactMessage :''
    }
  },
  props:{

  },
  methods:{
    sendMessage(e){
        e.preventDefault()
        this.$refs.contactFormAlert.textContent = ''
        if(this.contactName, this.contactEmail, this.contactMessage){
            const ob = {
                from_id: 2,
                to_id: 1,
                title: `${this.contactName} | ${this.contactEmail}`,
                content: `${this.contactSubject} | ${this.contactPhone} | ${this.contactMessage}`
            }
            axios.post(`/Message`, ob)
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.$refs.contactFormAlert.textContent = 'Pomyślnie wysłano wiadomość. Wkrótce się z Tobą skontaktujemy.'
                        this.$refs.contactFormAlert.style = 'color: #5ea01a;'
                        this.contactName = ''
                        this.contactPhone = ''
                        this.contactEmail = ''
                        this.contactSubject = ''
                        this.contactMessage = ''
                    }
                })
        }else{
            this.$refs.contactFormAlert.style = 'color: #dc3545;'
            this.$refs.contactFormAlert.textContent = 'Wprowadź dane poprawnie'
        }
    }
  }
}
</script>

<style scoped lang='scss'>
.contact-form-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    &__input{
        width: 275px;
        padding: 5px;
        border: none;
        border-bottom: 2px solid #F2790F;
    }
    &__ta{
        max-width: 600px;
        height: 200px;
        border: none;
        border-bottom: 2px solid #F2790F;
        resize: vertical;
        padding: 10px;
        width: 100%;
    }
    button{
        background-color: #F2790F;
        padding: 8px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }
    &__alert-button{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        span{
            font-size: 1.6rem;
        }
    }
    @media (max-width: 650px) {
        .contact-form-container{
            &__input{
                width: 100%;
            }
        }
    }
}
</style>