<template>
  <div class="users-add-container">
      <h2>Dodawanie seryjne użytkowników</h2>
      <button v-if="!newUsers" @click="importDb()">importuj bazę</button>
      <button v-if="newUsers" @click="sendUsers()">Start!</button>
      <button v-if="!loading" @click="stop = true">Stop</button>
      <img v-if="loading" class="loading" src="@/assets/icons/loading.gif" alt="">
      <span>{{ doneUsers }} z {{ todoUsers }}</span>
      <span>zostało: {{ Math.floor(leftTime/60) }} min. {{ leftTime%60 }} sek.</span>
      <span v-if="newUsers">obecnie dodawany rekord: {{newUsers[0] ? newUsers[0].email : ''}}</span>
      <span>nie dodano:</span>
      <textarea name="errorRecords" id="errorRecords" cols="30" rows="10" v-model="errorRecords"></textarea>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import axios from 'axios'
export default{
  name:'UsersAdd',
  setup () {
    useMeta({title: 'Dodaj użytkowników'})
  },
  data(){
    return{
        loading: false,
        doneUsers: 0,
        todoUsers: 0,
        leftTime: 0,
        stop: false,
        newUsers: null,
        states: null,
        errorRecords: ''
    }
  },
  props:{

  },
  mounted(){
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
    this.getStates()
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  methods:{
    sendUsers(){
        if(this.newUsers.length > 0 && !this.stop){
                this.leftTime -= 2
                this.doneUsers += 1
                const newUser = {
                    firstname: this.newUsers[0].firstname ? this.newUsers[0].firstname : null,
                    lastname: this.newUsers[0].lastname ? this.newUsers[0].lastname : null,
                    role_id: 5,
                    no_mail: true,
                    email: this.newUsers[0].email ? this.newUsers[0].email : null,
                    phone_2: this.newUsers[0].phone_2 ? this.newUsers[0].phone_2 : null,
                    phone: this.newUsers[0].phone ? this.newUsers[0].phone : null,
                    name: this.newUsers[0].name ? this.newUsers[0].name : null,
                    address: this.newUsers[0].address ? this.newUsers[0].address : null,
                    postcode: this.newUsers[0].postcode ? this.newUsers[0].postcode : null,
                    state_id: this.newUsers[0].state_id ? this.newUsers[0].state_id : null,
                    password: "",
                    category_id: this.newUsers[0].category_id ? this.newUsers[0].category_id : null,
                    nip: this.newUsers[0].nip ? this.newUsers[0].nip : null,
                }
                if(newUser.email && newUser.name){
                  axios.post('/User',newUser).then(res => {
                      if(String(res.status).charAt(0) == '2'){
                          this.newUsers.shift()
                          setTimeout(() => {
                            this.sendUsers()
                          }, 4000)
                      }
                  }).catch((err) => {
                    this.newUsers.shift()
                    this.errorRecords = `${this.errorRecords}, ${newUser.email}`
                    this.sendUsers()
                    console.log(err)
                  })
                }else{
                  this.newUsers.shift()
                  this.errorRecords = `${this.errorRecords}, ${newUser.email}`
                  this.sendUsers()
                }
        }else{
            this.$store.commit('setIsSuccess', 'pomyślnie zaczytano użytkowników')
            this.loading = false
        }
    },
    importDb(){
        fetch('http://localhost:3000')
            .then((res) => {
                res.json().then(resp => {
                    this.newUsers = resp
                    this.todoUsers = resp.length
                    this.leftTime = resp.length*4
                })
            })
    },
    getStates(){
      axios.get('/State').then(res => {
        this.states = res.data
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.users-add-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .loading{
        width: 40px;
    }
}
</style>