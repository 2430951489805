<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | m2VET` : `m2VET` }}</template>
  </metainfo>
  <AlertVue/>
  <SuccessAlert/>
  <div class="wrapper" ref="wrapper">
    <NavigationVue @toggleBlur="toggleBlur"/>
    <router-view/>
  </div>
  <div class="footer-wrapper">
    <Footer/>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import NavigationVue from './components/Navigation.vue';
import AlertVue from './components/AlertVue.vue';
import Footer from './components/Footer.vue';
import SuccessAlert from './components/SuccessAlert.vue';

export default {
  name: 'App',
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'pl' },
      description: 'Portal dla twojego pupila',
      meta: [{ name: 'robots', Name: 'robots', content: 'none' }]
    })
  },
  components: {
    NavigationVue,
    AlertVue,
    Footer,
    SuccessAlert
  },
  mounted(){
    if(!this.$store.state.auth.user){
      localStorage.removeItem('userm2VET')
    }else{
      this.$store.commit('setIsSubscribed', JSON.parse(localStorage.getItem('userm2VET')));
    }
  },
  methods:{
    toggleBlur(){
      this.$refs.wrapper.classList.add('active-blur')
      setTimeout(() => {
        this.$refs.wrapper.classList.remove('active-blur')
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
@use '../node_modules/normalize.css/normalize.css';

@font-face {
  font-family: 'Roboto';
  src: url("./font/Roboto/Roboto-Regular.ttf") format("truetype");
  src: url("./font/Roboto/Roboto-Bold.ttf") format("truetype");
  src: url("./font/Roboto/Roboto-Light.ttf") format("truetype");
}

#app {
  font-family: 'Roboto';
  color: #000000;
  font-size: 2rem;
}

html{
  font-size: 62.5%;
}

.wrapper{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: calc(100vh - 200px);
  z-index: 0;
}

.active-blur{
  animation-name: blur;
  animation-duration: .5s;
}

.footer-wrapper{
  background-color: #F2F2F2;
  margin-top: 50px;
}

@keyframes blur {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}
</style>
