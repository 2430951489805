<template>
  <div class="registration-page-2-wrapper">
    <h2>Aktywacja konta</h2>
    <img src="@/assets/icons/loading.gif" alt="">
  </div>
</template>

<script>
import axios from 'axios';
import { useMeta } from 'vue-meta';
export default{
  name:'RegistrationPage2',
  setup () {
    useMeta({title: 'Potwierdź konto'})
  },
  data(){
    return{

    }
  },
  props:{

  },
  mounted(){
    if(!this.$route.query.register_code){
        this.$router.push('/register')
    }else{
        this.confirmUser()
    }
  },
  methods:{
    confirmUser(){
        axios.post('/UserConfirmation', {
            register_code: this.$route.query.register_code
        }).then(() => {
            this.$store.commit('setIsSuccess', 'Pomyślnie założono konto. Zaloguj się!')
            this.$router.push('/login')
        }).catch(() => {
            this.$store.commit('setIsAlert', 'Nieprawidłowy kod uwierzytelniający.')
            setTimeout(() => {
                this.$router.push('/register')
            }, 1000)
        })
    }
  }
}
</script>

<style scoped lang='scss'>
.registration-page-2-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 70px;
    }
}
</style>