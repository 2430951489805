<template>
    <h2>Wiadomości</h2>
    <div class="messages-container" v-if="userMessages.length > 0 && currentUser">
        <div class="messages-container__list">
            <div class="messages-container__list__top-bar">
                <span><span class="messages-container__list__top-bar__num--orange">{{ userMessages.length }}</span>&nbsp;konwersacje</span>
            </div>
            <div class="messages-container__list__list">
                <div class="messages-container__list__list__item" v-for="item, index in userMessages" :key="index">
                    <div>
                        <b>{{ item.userName }}</b>
                        <span :class="item.messages[item.messages.length-1].from_id !== currentUser.data.user_id ? 'messages-container__list__list__item__missed' : ''">{{ item.messages[item.messages.length-1].content.length > 20 ? item.messages[item.messages.length-1].content.slice(null, -(item.messages[item.messages.length-1].content.length-20)) : item.messages[item.messages.length-1].content }}...</span>
                    </div>
                    <button @click="() => setCurrentMessage(item)"><img src="@/assets/message-arrow.png" alt=">"></button>
                </div>
            </div>
        </div>
        <div class="messages-container__panel">
            <div class="messages-container__panel__top-bar">
                <router-link :to="`/browse/user/${currentMessage.user_id}`">{{ currentMessage.userName }}</router-link>
                <span>ostatnia aktywność: <b>{{ currentMessage.messages[currentMessage.messages.length-1].date }}</b></span>
            </div>
            <div class="messages-container__panel__messages" ref="messageCont">
                <span :class="`message ${message.from_id == currentUser.data.user_id ? 'messages-container__panel__messages__message--from-me' : 'messages-container__panel__messages__message--to-me'}`" v-for="message in currentMessage.messages" :key="message.id">{{ message.content }}</span>
            </div> 
            <form class="messages-container__panel__input-bar" @submit="(e) => sendMessage(e)">
                <textarea placeholder="Treść wiadomości" v-model="messageInput" name="message"></textarea>
                <button type="submit"><img src="@/assets/send-icon.png" alt="wyślij"></button>
            </form>
        </div>
    </div>
    <div v-else>
        <h4>Brak wyników</h4>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
import { useMeta } from 'vue-meta'
export default{
  name:'PrivProfileMessagesVue',
  setup () {
    useMeta({title: 'wiadomości'})
  },
  data(){
    return{
        messagesToMe: [],
        messagesFromMe: [],
        messages: [],
        messageInput: '',
        currentMessage: null,
        userMessages: [],
    }
  },
  props:{

  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 3) {
      this.$router.push('/login');
    }
    this.getMessages()
  },
  methods: {
    getMessages(){
        this.userMessages = []
        this.currentMessage = null
        this.messagesFromMe = []
        this.messagesToMe = []
        axios.get(`/MessageInBox/${this.currentUser.data.user_id}`, authHeader())
            .then(res => {
                this.messagesToMe = res.data
                axios.get(`/MessageSend/${this.currentUser.data.user_id}`, authHeader())
                    .then(res => {
                        this.messagesFromMe = res.data
                        this.messages = this.messagesToMe.concat(this.messagesFromMe)
                        let fromIds = []
                        this.messagesToMe.forEach(x => {
                            if(!fromIds.includes(x.from_id)){
                                fromIds.push(x.from_id)
                            }
                        })
                        fromIds.forEach(id => {
                            const ob = {
                                user_id: id,
                                messages: this.messages.filter(item => item.from_id == id || item.to_id == id).sort(function(a,b){
                                    return new Date(a.created_at) - new Date(b.created_at);
                                }),
                                userName: this.messages.filter(item => item.from_id == id)[this.messages.filter(item => item.from_id == id).length-1].title
                            }
                            this.userMessages.push(ob)
                        })
                        this.setCurrentMessage(this.userMessages[0])
                    })
            })

    },
    setCurrentMessage(item){
        this.currentMessage = item
        if(this.currentMessage){
            this.$nextTick(function(){
                this.$refs.messageCont.scrollTop = this.$refs.messageCont.scrollHeight
            })
        }
    },
    sendMessage(e){
        e.preventDefault()
        if(this.currentMessage){
                const ob = {
                    title: this.currentUser.data.name,
                    content: this.messageInput,
                    from_id: this.currentUser.data.user_id,
                    to_id: this.currentMessage.user_id
                }
                axios.post('/Message', ob, authHeader())
                    .then(res => {
                        if(String(res.status).charAt(0) === '2'){
                            this.messageInput = ''
                            this.getMessages()
                        }
                    })
            }
    }
  }
}
</script>

<style scoped lang='scss'>
.messages-container{
    display: flex;
    gap: 20px;
    height: 500px;
    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 20px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
    &__list{
        display: flex;
        flex-direction: column;
        width: 250px;
        border-radius: 15px;
        border: 2px solid #808080;
        overflow-y: scroll;
        /* width */
        &__top-bar{
            border-bottom: 2px solid #808080;
            font-size: 1.6rem;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 25px 22px;
            &__num{
                &--orange{
                    background-color: #F2790F;
                    padding: 3px;
                    border-radius: 20px;
                }
            }
        }
        &__list{
            display: flex;
            flex-direction: column;
            padding: 8px;
            &__item{
                display: flex;
                justify-content: space-between;
                margin: 8px 0;
                border-bottom: 2px solid #808080;
                padding-bottom: 10px;
                div{
                    font-size: 1.7rem;
                    display: flex;
                    flex-direction: column;
                    span{
                        font-size: 1.5rem;
                    }
                }
                button{
                    border: none;
                    background: none;
                    cursor: pointer;
                    img{
                        width: 20px;
                        transform: rotate(180deg);
                    }
                }
                &__missed{
                    font-weight: 600;
                }
            }
        }
    }
    &__panel{
        display: flex;
        flex-direction: column;
        width: calc(100% - 270px);
        border-radius: 15px;
        border: 2px solid #808080;
        overflow: hidden;
        &__top-bar{
            border-bottom: 2px solid #808080;
            font-size: 1.6rem;
            display: flex;
            justify-content: space-between;
            gap: 15px;
            padding: 22px;
            b{
                font-size: 2rem;
            }
            span{
                b{
                    font-size: 1.6rem;
                }
            }
        }
        &__messages{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 22px;
            overflow-y: scroll;
            height: 100%;
            span{
                max-width: 50%;
            }
            &__message{
                &--to-me{
                    align-self: flex-start;
                    color: #5EA01A;
                    text-align: left;
                }
                &--from-me{
                    align-self: flex-end;
                    color: #F2790F;
                    text-align: right;
                }
            }
        }
        &__input-bar{
            display: flex;
            width: 100%;
            border-top: 2px solid #808080;
            textarea{
                width: calc(100% - 50px);
                padding: 8px;
                resize: none;
                border: none;
            }
            textarea:focus{
                outline-color: #F2790F;
            }
            button{
                border: none;
                background: none;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 730px){
    .messages-container{
        flex-direction: column;
        height: auto;
        &__panel{
            width: 100%;
            height: 500px;
            &__messages{
                span{
                    font-size: 1.6rem;
                }
            }
            &__input-bar{
                textarea{
                    font-size: 1.6rem;
                }
            }
        }
        &__list{
            height: 300px;
        }
    }
}
</style>