<template>
    <h2>Umów się na wizytę</h2>
    <div v-if="currentUser && $route.query.serviceid" class="make-an-appointment">
      <h3>umawiane zwierzę</h3>
      <div class="make-an-appointment__pets" v-if="pets">
        <div :key="pet.id" v-for="pet in pets" class="make-an-appointment__pets__pet-card">
          <img :src="pet.photo_url ? pet.photo_url : getImageUrl()" alt="">
          <span :style="{textDecoration: currentPet == pet.id ? 'underline' : 'none'}" @click="() => {currentPet = pet.id; getServiceCalendar(weeksCounter)}">{{ pet.pet_name }}</span>
        </div>
      </div>
      <h3>terminy</h3>
      <div class="make-an-appointment__calendar" v-if="currentPet">
        <div>
          <button @click="() => {
            if(weeksCounter > 0){
              weeksCounter -= 1
              getServiceCalendar(weeksCounter)
            }
          }"><img src="@/assets/icons/arrow-left-short.svg" alt="7 dni do tyłu"></button>
          <button @click="() => {
              weeksCounter += 1
              getServiceCalendar(weeksCounter)
          }"><img src="@/assets/icons/arrow-right-short.svg" alt="7 dni do przodu"></button>
        </div>
        <table id="appointmentCalendar">
          <tr>
            <th v-for="day, index in weekDays" :key="index">{{ day }}</th>
          </tr>
          <tr>
            <td v-for="day, index in weekDays" :key="index" :data-day="day"></td>
          </tr>
        </table>
      </div>
      <div class="make-an-appointment__summary" v-if="currentDate && currentDateTime && currentItemId && currentPet">
        <span><b>wybrane zwierzę: </b>{{pets.find(pet => pet.id == currentPet).pet_name}}</span>
        <span><b>wybrana godzina: </b>{{currentDateTime}}</span>
        <span><b>wybrana data: </b>{{currentDate}}</span>
        <button @click="makeAnAppointment">Umów się</button>
      </div>
    </div>
    <div v-else>
      <h4>Ładowanie...</h4>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'MakeAppointment',
    setup () {
      useMeta({ title: 'Umów się na wizytę' });
    },
    data(){
      return{
        pets: null,
        currentDate: '',
        currentDateTime: '',
        currentPet: '',
        currentItemId: '',
        weekDays: [],
        weekItems: {},
        weeksCounter: 0
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 3) {
        this.$router.push('/login');
      }

      if(!this.$route.query.serviceid){
        this.$router.push('/');
      }
      this.getPets()
    },
    methods:{
        getPets(){
          axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            this.pets = res.data.pets
          })
        },
        getImageUrl() {
            return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
        },
        makeAnAppointment(){
          if(this.currentPet){
            const ob = {
              calendar_id: this.currentItemId,
              user_id: this.currentUser.data.user_id,
              employee_id: 0,
              pet_id: this.currentPet
            }
            axios.post('/CalendarItemAddUser', ob, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie umówiono na wizytę. Sprawdź swoją skrzynkę e-mail')
                setTimeout(() => {
                  this.$router.push('/profile/visits')
                }, 500)
              }
            }).catch(() => {
              
            })
          }else{
            this.$store.commit('setIsAlert', 'podaj, które zwierzę umawiasz')
          }
        },
        getServiceCalendar(week){
          this.weekDays = []
          this.weekItems = []
          setTimeout(() => {
          const daysInTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          let currentDate = new Date()
          currentDate.setDate(new Date().getDate()-(daysInTheWeek.indexOf(new Date().toLocaleDateString('en-US', { weekday: 'long' }))))
          currentDate.setDate(currentDate.getDate()+(week*7))
          for(let x = 0; x < 7; x++){
              this.weekDays.push(this.getNextDays(currentDate, x).toISOString().slice(0, 10))
          }
            axios.get(`/CalendarItemsForService/${this.$route.query.serviceid}`).then(res => {
                const items = res.data
                this.weekDays.forEach(day => {
                  this.weekItems[day] = []
                })
                items.forEach(item => {
                  if(this.weekDays.includes(item.date)){
                    this.weekItems[item.date].push(item)
                  }
                })
                document.querySelector('#appointmentCalendar').querySelectorAll('td[data-day]').forEach(el => {
                    this.weekItems[el.dataset.day].forEach(calItem => {
                      const chooseBtn = document.createElement('button')
                      const br = document.createElement('br')
                      if(!calItem.user_id){
                        chooseBtn.textContent = calItem.time
                        chooseBtn.dataset.day = calItem.date
                        chooseBtn.dataset.hour = calItem.time
                        chooseBtn.dataset.itemId = calItem.id
                        chooseBtn.style = `
                        margin: 10px;
                    border: none;
                    border-radius: 8px;
                    padding: 8px;
                    background-color: #f2790f;
                    cursor: pointer;`
                    chooseBtn.addEventListener('click', (e) => {
                      this.currentDate = e.target.dataset.day
                      this.currentDateTime = e.target.dataset.hour
                      this.currentItemId = e.target.dataset.itemId
                    })
                      }else{
                        chooseBtn.textContent = calItem.time
                        chooseBtn.style = `
                        margin: 10px;
                    border: none;
                    border-radius: 8px;
                    padding: 8px;
                    background-color: #787878;
                    text-decoration: line-through;
                        `
                      }
                      el.appendChild(chooseBtn)
                      el.appendChild(br)
                    })
                  })
            }).catch(() => {
                
            })
          },200)
        },
        getNextDays(currentDate = new Date(), daysToAdd = 1){
            const nextDate = new Date(currentDate)
            nextDate.setDate(currentDate.getDate() + daysToAdd)
            return nextDate
        },
    }
  }
  </script>
  
  <style scoped lang='scss'>
    .make-an-appointment{
        display: flex;
        flex-direction: column;
        align-items: center;
        &__calendar{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 20px 0;
            overflow-x: scroll;
            width: 100%;
            div:first-child{
              display: flex;
              gap: 20px;
              button{
                border: none;
                background-color: #f2790f;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                cursor: pointer;
                img{
                  width: 30px;
                }
              }
            }
            table{
              border-collapse: collapse;
              min-width: 990px;
              tr, td, th{
                border: 1px solid black;
              }
              td,th{
                width: 130px;
                min-height: 200px;
              }
            }
        }
        &__pets{
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          &__pet-card{
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border: 2px solid #fff;
            border-radius: 20px;
            padding: 10px 0;
            img{
              width: 120px;
              height: 120px;
              object-fit: cover;
              border-radius: 50%;
            }
            span{
              border: none;
            }
          }
        }
        &__summary{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          button{
            border: none;
            border-radius: 8px;
            padding: 8px;
            background-color: #f2790f;
            cursor: pointer;
          }
        }
    }
  </style>