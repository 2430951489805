<template>
    <div class="confirm-newsletter-wrapper">
        <img v-if="loading" src="@/assets/icons/loading.gif" alt="">
        <h2 v-if="showMessage">{{ message }}</h2>
    </div>
  </template>
  
  <script>
import axios from 'axios';
import { useMeta } from 'vue-meta';

  export default {
    name: "ConfirmNewsletter",
    setup () {
        useMeta({
          title: '',
          // meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
        })
    },
    components: {

    },
    data() {
      return {
        message: '',
        loading: true,
        showMessage: false
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    mounted () {
        this.confirmNewsletter()
    },
    methods: {
        confirmNewsletter(){
            if(this.$route.query.newsletterId && (this.$route.query.status || this.$route.query.active)){
                const ob = {
                  newsletterId: this.$route.query.newsletterId
                }
                if(this.$route.query.status){
                  ob.status = this.$route.query.status == 'true' ? true : false
                  this.message = this.$route.query.status == 'true' ? 'Anulowano newsletter dla tego konta' : 'Pomyślnie usunięto konto'
                }else if(this.$route.query.active){
                  ob.active = this.$route.query.active == 'true' ? true : false
                  this.message = this.$route.query.active == 'true' ? 'Pomyślnie aktywowano konto' : 'Wystąpił błąd'
                }
                axios.post('/ConfirmNewsletter', ob).then(() => {
                    this.loading = false
                    this.showMessage = true
                }).catch(() => {
                    this.showMessage = true
                    this.loading = false
                })
            }else{
                // this.$store.commit('setIsSuccess', 'Zaloguj się i kliknij jeszcze raz link z maila')
                this.loading = false
                this.message = "Zaloguj się i kliknij jeszcze raz link z maila"
            }
        }
    },
  };
  </script>
  
  <style lang="scss">
.confirm-newsletter-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 70px;
    }
}
</style>