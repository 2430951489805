<template>
    <footer class="main-footer-container">
        <div class="footer-col-1-info">
            <img src="@/assets/logo.png" alt="m2VET" class="main-footer-logo">
            <p v-html="footerFirstCol.text"></p>
            <br>
            <span v-html="footerFirstCol.copyright"></span>
        </div>
        <div class="footer-col-2-nav">
            <strong>Nawigator</strong>
            <br>
            <a href="#">Strona główna</a>
            <RouterLink to="/announcements">Ogłoszenia</RouterLink>
            <RouterLink to="/blog">Blog</RouterLink>
            <br>
            <!-- statute -->
            <RouterLink to="/statute">Regulamin</RouterLink>
            <!-- privacy -->
            <RouterLink to="/privacy">Polityka prywatności</RouterLink>
            <!-- cookies -->
            <RouterLink to="/cookies">Polityka plików cookie</RouterLink>
        </div>
        <div class="footer-col-3-con">
            <strong>Kontakt</strong>
            <br>
            <span>Obsługa klienta</span>
            <span>tel: {{ footerThirdCol.telNum }}</span>
            <span>email: {{ footerThirdCol.email }}</span>
            <br>
            <span>Dane kontaktowe</span>
            <span>{{ footerThirdCol.address1 }}</span>
            <span>{{ footerThirdCol.address2 }}</span>
        </div>
        <div class="footer-col-4-find">
            <strong>Znajdź nas</strong>
            <div class="footer-find-social">
                <a :href="social.url" v-for="social in footerSecondCol" :key="social.id"><button><img :src="social.icon_url" :alt="social.name"></button></a>
            </div>
            <ButtonVue v-if="isBiz" :text="isBiz=='true' ? 'dla usługobiorcy' : 'dla biznesu'" bgColor="#f27a07" color="#000" @click="() => {
        $emit('toggleBlur')
        this.$store.commit('setIsBusiness', isBiz)
        }"/>
        </div>
    </footer>
</template>

<script>
import axios from 'axios';
import ButtonVue from './Button.vue';
import { useRoute } from 'vue-router';
export default{
    name:"FooterVue",
    data(){
        return{
            footerFirstCol:{
                copyright: '&copy; m2VET sp. z.o.o. Wszelkie prawa zastrzeżone',
                logo_url: '',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ex ipsum, tempor id turpis a, consectetur scelerisque lacus. Suspendisse tempus, quam et interdum facilisis, enim arcu convallis eros, ut rutrum.'
            },
            footerSecondCol:[
                {
                    name: 'facebook',
                    icon_url: '',
                    url: 'https://facebook.com'
                },
                {
                    name: 'instagram',
                    icon_url: '',
                    url: 'https://instagram.com'
                }
            ],
            footerThirdCol:{
                telNum: 'brak',
                email: 'brak',
                address1: 'brak',
                address2: 'brak'
            }
        }
    },
    props:{

    },
    mounted(){
        this.getFooterData()
    },
    components:{
    ButtonVue
    },
    computed:{
        isBiz(){
      if(useRoute().fullPath == '/'){
        return 'false'
      }else if(useRoute().fullPath.includes('/dla-biznesu')){
        return 'true'
      }else{
        return false
      }
    }
    },
    methods:{
        getFooterData(){
            const ob = {
                page: 1,
                limit: 50
            }
            axios.get(`/FooterSettingsGet`)
                .then(res => {
                    this.footerFirstCol = res.data
                })

            axios.post(`/SocialIconSearch`, ob)
                .then(res => {
                    this.footerSecondCol = res.data.items
                })

            axios.get(`/ContactGet`)
                .then(res => {
                    this.footerThirdCol.telNum = res.data.phone
                    this.footerThirdCol.email = res.data.email
                    this.footerThirdCol.address1 = res.data.address_1
                    this.footerThirdCol.address2 = res.data.address_2
                })
        },
        scrollSiteUp(){
            window.scrollTo(0,0)
        }
    }  
}
</script>

<style scoped>
.main-footer-container{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
}
.main-footer-container > div{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
}
.main-footer-logo{
    width: 150px;
}
.footer-col-1-info{
    width: 33%;
    min-width: 290px;
}
.footer-col-2-nav{
    width: 22%;
    min-width: 191px;
}
.footer-col-3-con{
    width: 22%;
    min-width: 191px;
}
.footer-col-4-find{
    width: 22%;
    min-width: 191px;
}
.footer-col-2-nav > a{
    color: #000;
    text-decoration: none;
}
.footer-find-social > a{
    margin: 5px 10px;
}
.footer-find-social > a > button{
    border: none;
    padding: 5px 7px;
}
.footer-find-social > a > button > img{
    width: 20px;
}
.footer-col-4-find > strong{
    margin-bottom: 10px;
}
.footer-find-social{
    margin-bottom: 20px;
}
</style>