<template>
    <h2>Pracownicy</h2>
    <ButtonVue v-if="$store.state.isSubscribed" color="#000" text="Dodaj pracownika" bgColor="#f2790f" @click="() => $router.push('/dla-biznesu/profile/employees/add')"/>
    <div v-if="currentUser && employees.length > 0" class="services-grid">
      <table>
        <thead>
          <tr>
            <th>imię</th>
            <th>nazwisko</th>
            <th>numer telefonu</th>
            <th>email</th>
            <th>akcje</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in employees" :key="employee.id">
            <td>{{ employee.firstname }}</td>
            <td>{{ employee.lastname }}</td>
            <td>{{ employee.phone }}</td>
            <td>{{ employee.email }}</td>
            <td class="action-col"><button @click="() => deleteEmployee(employee.id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button><button @click="() => $router.push(`/browse/user/${employee.id}`)"><img src="@/assets/icons/eye-fill.svg" alt="zobacz"></button></td>
          </tr>
        </tbody>
      </table>
      <ButtonVue v-if="employees.length > 10" color="#000" text="Załaduj więcej..." bgColor="#f2790f" @click="() => {
        employeesLimit += 5
        getEmployees(employeesLimit)
      }"/>
    </div>
    <div v-else>
      <h4 class="no-services-info">Brak pracowników</h4>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMeta } from 'vue-meta';
  import ButtonVue from '@/components/Button.vue';
  import authHeader from '@/services/auth-header';
  export default{
    name:'BizProfileEmployees',
    setup () {
      useMeta({title: 'Pracownicy'})
    },
    data(){
      return{
        employees:[],
        employeesLimit: 10
      }
    },
    props:{
  
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    components:{
      ButtonVue,
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      this.getEmployees(this.employeesLimit)
    },
    methods:{
      getEmployees(limit){
        axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: limit}, authHeader())
            .then(res => {
                this.employees = res.data.items
            })
        },

      deleteEmployee(id){
        axios.delete(`/User/${id}`, authHeader()).then(res => {
          if(String(res.status).charAt(0) == '2'){
            this.$store.commit('setIsSuccess', 'Pomyślnie usunięto pracownika')
            this.getEmployees(this.employeesLimit)
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .no-services-info{
    text-align: center;
  }
  .services-grid{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    table{
      // border: 1px solid #B1B1B1;
      border: none;
      border-collapse: collapse;
      thead{
        border-bottom: 1px solid #B1B1B1;
      }
      th,td{
        padding: 5px 20px;
        text-align: center;
      }
    }
  }
  .action-col{
    button{
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  @media(max-width: 830px){
    .services-grid{
      align-items: flex-start;
      width: 100%;
      overflow: scroll;
    }
  }
  </style>