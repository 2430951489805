<template>
    <div v-if="currentUser && messages" class="mail-box-wrapper">
        <div class="mailbox-header">
            <h2>Skrzynka odbiorcza</h2>
            <button @click="() => getMessages()"><img src="@/assets/icons/arrow-clockwise.svg" alt="odśwież"></button>
        </div>
        <div class="mailbox-search">
            <input type="text" placeholder="wyszukaj wiadomości" v-model="messageSearchInput">
            <button @click="searchMessages">Szukaj</button>
            <button @click="getMessages">Resetuj</button>
        </div>
        <div class="mail-box-container">
            <div class="mailList">
                <div v-if="messages.length == 0" class="mail-item">
                    <b>Brak wiadomości</b>
                </div>
                <div class="mail-item" v-for="message in messages" :key="message.id" @click="() => chooseMessage(message.id)">
                    <b>{{ message.title.split('|')[0] }}</b>
                    <span>{{ message.title.split('|')[1] }}</span>
                </div>
            </div>
            <div class="mailPreview">
                <div class="mail-header">
                    <button v-if="currentMessage" @click="() => deleteMessage()"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
                    <span><b>Temat:</b> {{ currentMessage ? currentMessage.content.split('|')[0] : '' }}</span>
                    <span><b>Adres email:</b> {{ currentMessage ? currentMessage.title.split('|')[1] : '' }}</span>
                    <span><b>Numer telefonu:</b> {{ currentMessage ? currentMessage.content.split('|')[1] : '' }}</span>
                    <span><b>Wiadomość:</b></span>
                    <p>{{ currentMessage ? currentMessage.content.split('|')[2] : '' }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
    name:"MailBox",
    data(){
        return{
            messages: null,
            currentMessage: null,
            messageSearchInput: ''
        }
    },
    props:['isVisible'],
    mounted(){
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
        this.getMessages()
    },
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    methods:{
        getMessages(){
            axios.get(`${process.env.VUE_APP_API_BASE_URL}MessageInBox/1`, authHeader())
                .then(res => {
                    this.messages = res.data
                })
        },
        chooseMessage(id){
            this.currentMessage = this.messages.filter(item => item.id == id)[0]
        },
        deleteMessage(){
            axios.delete(`${process.env.VUE_APP_API_BASE_URL}Message/${this.currentMessage.id}`, authHeader())
                .then(res  => {
                    if(String(res.status).charAt(0) === '2'){
                        this.currentMessage = null
                        this.getMessages()
                    }
                })
        },
        searchMessages(){
            if(this.messageSearchInput.length > 2){
                this.messages = this.messages.filter(message => message.title.toLowerCase().includes(this.messageSearchInput.toLowerCase()) || message.content.toLowerCase().includes(this.messageSearchInput.toLowerCase()))
                this.messageSearchInput = ''
            }else{
                this.$store.commit('setIsAlert', 'wpisz co najmniej 3 znaki')
            }
        }
    }  
}
</script>

<style scoped>
.mail-box-wrapper{
    display: flex;
    max-width: 1100px;
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
.mail-box-container{
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}
.mailList{
    width: calc(25% - 10px);
    min-width: 250px;
    height: 600px;
    overflow-y: scroll;
}
.mailPreview{
    width: calc(75% - 10px);
    min-width: 250px;
    background-color: #f2790f73;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin: 7px 0;
    height: 600px;
    overflow-y: scroll;
}
.mail-item{
    display: flex;
    flex-direction: column;
    background-color: #f2790f73;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin: 7px 0;
    cursor: pointer;
    overflow: hidden;
}
.mail-item > span{
    font-size: 1.6rem;
}
.mail-header{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.mail-header > button{
    align-self: flex-end;
    background: none;
    border: none;
    cursor: pointer;
}
.mailbox-header{
    display: flex;
    align-items: center;
    gap: 20px;
}
.mailbox-header > button{
    background: none;
    border: none;
    cursor: pointer;
}
@media (max-width: 1115px){
    .mailList{
        width: 100%;
        height: 300px;
    }
    .mailPreview{
        width: 100%;
    }
}
</style>