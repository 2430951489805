<template>
  <div>
      <div v-if="isVisible" class="modal-container-bg"></div>
      <div class="modal-container" v-if="isVisible">
        <div class="modal-container__top-bar">
            <span>{{ title }}</span>
            <button @click="() => $emit('closeModal')"><img src="@/assets/icons/x.svg" alt=""></button>
        </div>
        <form @submit="(e) => sendData(e)">
            <div :key="index" v-for="field, index in fields">
                <label :for="field.name">{{ field.label }}</label>
                <input :type="field.type" v-if="field.tag == 'input'" :name="field.name" :id="field.name" :placeholder="field.placeholder" :ref="field.name" :minlength="field.minlength" :maxlength="field.maxlength" :required="field.req" accept="image/png, image/jpeg" :value="field.value" :pattern="field.htmlPattern">
                <textarea v-if="field.tag == 'ta'" :name="field.name" :id="field.name" :placeholder="field.placeholder" :ref="field.name" :required="field.req" :minlength="field.minlength" :maxlength="field.maxlength" :value="field.value"></textarea>
                <select v-if="field.tag == 'select'" :name="field.name" :id="field.name" :ref="field.name" v-model="field.value">
                    <option :value="item.value" :key="item.value" v-for="item in field.options">{{ item.name }}</option>
                </select>
            </div>
            <button type="submit">{{ buttonText }}</button>
        </form>
      </div>
  </div>
</template>

<script>
export default{
  name:'ModalVue',
  data(){
    return{

    }
  },
  props:['fields', 'title', 'buttonText', 'isVisible', 'itemId'],
  methods: {
    sendData(e){
        e.preventDefault()
        const data = {}
        Object.keys(this.$refs).forEach(item => {
            if(item == 'photo'){
                data[item] = this.$refs[item][0].files[0] 
            }else{
                data[item] = this.$refs[item][0].value
            }
        })
        data.id = this.itemId
        this.$emit('sendData', data)
        setTimeout(() => {
            this.$emit('closeModal')
        }, 500)
    }
  }
}
</script>

<style scoped lang='scss'>
.modal-container-bg{
    position: fixed;
    width: 100vw;
    height: calc(100vh + 10px);
    background-color: #000;
    opacity: 0.8;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.modal-container{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #B1B1B1;
    z-index: 100;
    &__top-bar{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #B1B1B1;
        padding: 10px;
        align-items: center;
        button{
            background: none;
            border: none;
            cursor: pointer;
            img{
                width: 30px;
                height: 30px;
            }
        }
    }
    form{
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin: 20px 0;
        height: 70vh;
        max-height: 600px;
        overflow-y: scroll;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            input{
                border: 1px solid #B1B1B1;
                border-radius: 8px;
                padding: 10px;
                width: 95%;
            }
            textarea{
                border: 1px solid #B1B1B1;
                border-radius: 8px;
                padding: 10px;
                resize: vertical;
            }
        }
        button{
            padding: 8px 15px;
            border-radius: 8px;
            border: none;
            background: #f2790f;
            cursor: pointer;
        }
        select{
            border: 1px solid #B1B1B1;
            border-radius: 8px;
            padding: 10px;
        }
    }
}
</style>