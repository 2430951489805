<template>
  <div class="specs-edit-cont" v-if="currentUser">
      <h2>specjalizacje</h2>
      <div class="specs-edit-content">
          <table class="specs-edit-table">
              <tr v-for="spec in specs" :key="spec.id"><td>{{ spec.name }}</td><td @click="() => setFieldEdit(spec.name, spec.id)" class="cspoint"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td><td class="cspoint" @click="() => deleteSpec(spec.id)"><img src="@/assets/icons/trash.svg" alt="x"></td></tr>
          </table>
          <div class="specs-edit-edit-add-conts" v-if="showEditAdd">
              <button @click="() => showEditAdd = false" class="field-close-btn"><img src="@/assets/icons/x.svg" alt="x"></button>
              <label for="editAddField" class="edit-add-label">{{ editAddComm }}</label>
              <input type="text" name="editAddField" id="editAddField" placeholder="nazwa" v-model="newSpecName">
              <button @click="submitEditAdd" class="cspoint">{{ editAddComm.split(' ')[0] }}</button>
          </div>
      </div>
      <button class="specs-edit-add-btn" @click="setFieldAdd">Dodaj</button>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default{
  name:"SepcsEdit",
  data(){
      return{
          specs: [],
          showEditAdd: false,
          editAddComm: '',
          specId: '',
          newSpecName: ''
      }
  },
  props:['isVisible'],
  mounted(){
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
      this.getSpecs()
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  methods:{
      getSpecs(){
          axios.get(`/Categories`)
              .then(res => {
                  this.specs = res.data
              })
      },
      setFieldAdd(){
          this.showEditAdd = true
          this.editAddComm = 'Dodaj nową specjalizację'
      },
      setFieldEdit(name, id){
          this.showEditAdd = true
          this.editAddComm = `Edytuj specjalizację: ${name}`
          this.specId = String(id)
      },
      submitEditAdd(){
          if(this.editAddComm !== '' && this.newSpecName !== ''){
              switch(this.editAddComm.split(' ')[0]){
                  case 'Dodaj':
                      this.addSpec()
                      break;
                  case 'Edytuj':
                      this.editSpec()
                      break;
              }
          }
      },
      addSpec(){
          const ob = {
              name: this.newSpecName
          }
          axios.post(`/Category`, ob, authHeader())
              .then(() => {
                  this.specs.push(ob)
              })
              
      },
      editSpec(){
          if(this.specId !== ''){
              const ob = {
                  id: Number(this.specId),
                  name: this.newSpecName
              }
              axios.post(`/Category`, ob, authHeader())
                  .then(() => {
                      this.specs.forEach(x => {
                          if(x.id == ob.id){
                              x.name = ob.name
                          }
                      })
                  })
                  
          }
      },
      deleteSpec(id){
          axios.delete(`/Category/${id}`, authHeader())
              .then(() => {
                  this.specs.forEach(x => {
                      if(x.id == id){
                          this.specs.push(this.specs.splice(this.specs.indexOf(x), 1)[0])
                          this.specs.pop()
                      }
                  })
              })
              
      }
  }  
}
</script>

<style scoped>
.specs-edit-cont{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.specs-edit-table > tr > td{
  padding: 5px 10px;
}
.specs-edit-add-btn{
  padding: 5px 30px;
  margin-top: 10px;
  cursor: pointer;
}
.specs-edit-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.specs-edit-edit-add-conts{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}
.specs-edit-edit-add-conts > *{
  margin: 3px 0;
}
.field-close-btn{
  background: none;
  border: none;
  cursor: pointer;
}
.edit-add-label{
  max-width: 350px;
}
.cspoint{
  cursor: pointer;
}
</style>