<template>
  <div class="calendar-user-modal" v-if="$route.query.userid">
    <button class="calendar-user-modal__close-btn" @click="() => {
        $router.push(`${$route.path}`).then(() => userData = null)
    }"><img src="@/assets/icons/x.svg" alt=""></button>
    {{ getUserData() }}
    <div v-if="userData" class="calendar-user-modal__inner">
        <span>imię i nazwisko: <b>{{ userData.firstname }} {{ userData.lastname }}</b></span>
        <span>adres e-mail: <b>{{ userData.email }}</b></span>
        <span>zwierzę: <b>{{ userData.pets.filter(pet => pet.id == this.$route.query.petid).length > 0 ? userData.pets.filter(pet => pet.id == this.$route.query.petid)[0].pet_name : null }}</b></span>
        <!-- <button v-if="canGetAdvance" class="cta" @click="() => sendPaymentDataA()">{{ $route.query.pd == 'true' ? 'Anuluj link do zaliczki' : 'Wyślij link do zaliczki' }}</button> -->
        <div class="calendar-user-modal__inner__contact" v-if="!addLinkActive">
            <span>kontakt</span>
            <textarea name="message" id="message" placeholder="twoja wiadomość" v-model="messageContent"></textarea>
            <button class="cta" @click="() => sendMessage()">wyślij</button>
        </div>
        <div class="calendar-user-modal__inner__advance" v-if="addLinkActive">
            <span>zaliczka</span>
            <label for="precent">wartość procentowa</label>
            <input type="number" id="precent" min="1" max="100" name="precent" placeholder="%" aria-label="procent" @input="() => setAdvanceAmount('precent')" v-model="advance.precent">
            <label for="value">wartość</label>
            <input type="number" id="value" name="value" :max="serviceData.price" placeholder="zł" aria-label="wartość w PLN" @input="() => setAdvanceAmount('value')" v-model="advance.value">
            <div>
                <span>zaliczka: {{ advance.amount }} zł</span>
                <button class="cta cta--green" @click="() => sendPaymentDataB()">wyślij</button>
                <button class="cta cta--red" @click="() => addLinkActive = false">anuluj</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default{
  name:'CalendarUserModal',
  data(){
    return{
        userData: null,
        messageContent: '',
        addLinkActive: false,
        serviceData: {},
        advance:{
            amount: 0,
            precent: 0,
            value: 0,
            maxValue: 0
        },
        canGetAdvance: null
    }
  },
  props:{
  },
  mounted(){
    
  },
  computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
  methods:{
    getUserData(){
        if(!this.userData){
            axios.get(`/User/${this.$route.query.userid}`).then(resp => {
                    this.userData = resp.data
                })
        }
        if(!this.serviceData.price && !this.serviceData.name){
            axios.get(`/Service/${this.$route.params.serviceId}`).then(({data: {price, name}}) => {
                this.serviceData.price = price
                this.serviceData.name = name
            }).catch(() => {
                
            })
        }
        if(this.canGetAdvance === null){
            axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
                this.canGetAdvance = Boolean(res.data.reportsKey && res.data.crc && res.data.merchantId && res.data.terms_and_conditions_url && res.data.privacy_policy_url)
            }).catch(() => {
                
            })
        }
    },
    sendMessage(){
        if(this.messageContent.length > 0){
            const ob = {
                title: this.currentUser.data.name,
                content: this.messageContent,
                from_id: this.currentUser.data.user_id,
                to_id: this.userData.id
            }
            axios.post('/Message', ob, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.$store.commit('setIsSuccess', 'Wysłano wiadomość')
                    }
                })
        }else{
            this.$store.commit('setIsAlert', 'wpisz treść wiadomości')
        }
    },
    sendPaymentDataA(){
        if(this.$route.query.pd == 'true'){
            axios.delete(`/DeletePaymentData/${this.$route.query.item_id}`, authHeader()).then(() => {
                this.$store.commit('setIsSuccess', 'Anulowano link do płatności zaliczki')
                this.$router.push(`${this.$route.path}`)
                this.addLinkActive = false
                this.$emit('reloadCalendar')
            }).catch(() => {
                
            })
        }else{
            this.addLinkActive = true
        }
    },
    sendPaymentDataB(){
        if(this.advance.amount > 0 && this.advance.amount <= this.serviceData.price){
            axios.post('/GetPaymentUrlPrzelewy24', {
                payment_name: `Zaliczka usługi: ${this.serviceData.name} do użytkownika: ${this.userData.email}`,
                price: this.advance.amount*100,
                buyer_email: this.userData.email
            }, authHeader()).then(res => {
                axios.post('/AddPaymentData', {
                    id: this.$route.query.item_id,
                    payment_url: res.data.payment_url,
                    payment_price: this.advance.amount
                }, authHeader()).then(()=> {
                    this.$store.commit('setIsSuccess', 'Wysłano link do płatności zaliczki')
                    this.$router.push(`${this.$route.path}`)
                    this.addLinkActive = false
                    this.$emit('reloadCalendar')
                })
            }).catch(() => {
                
            })
            
        }else{
            this.$store.commit('setIsAlert', 'zaliczka nie może być większa niż cena usługi, ani mniejsza niż 1') 
        }
    },
    setAdvanceAmount(type){
        if(type == 'precent'){
            this.advance.amount = this.serviceData.price*(Number(this.advance.precent)/100)
            this.advance.value = this.serviceData.price*(Number(this.advance.precent)/100)
        }else if(type == 'value'){
            this.advance.amount = this.advance.value
            this.advance.precent = (Number(this.advance.value)/this.serviceData.price)*100
        }
    }
  }
}
</script>

<style scoped lang='scss'>
.calendar-user-modal{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #B1B1B1;
    z-index: 100;
    overflow: hidden;
    padding: 20px;
    &__inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        button.cta{
                background-color: #F2790F;
                padding: 5px 10px;
                border-radius: 8px;
                border: none;
                cursor: pointer;
                &--green{
                    background-color: rgb(94, 160, 26);
                }
                &--red{
                    background-color: #BB2D3B;
                }
        }
        &__contact{
            margin-top: 10px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border-top: 1px solid #B1B1B1;
            textarea{
                resize: vertical;
                width: 300px;
                padding: 8px;
                border: 1px solid #B1B1B1;
                border-radius: 8px;
            }
        }
        &__advance{
            margin-top: 10px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border-top: 1px solid #B1B1B1;
            input{
                padding: 8px;
                border: 1px solid #B1B1B1;
                border-radius: 8px;
            }
            label{
                font-size: 1.7rem;
            }
            div{
                display: flex;
                gap: 10px;
            }
        }
    }
    &__close-btn{
        position: absolute;
        top: 10px;
        right: 15px;
        border: none;
        border-radius: 50%;
        background-color: #fff;
        width: 30px;
        height: 30px;
        cursor: pointer;
        img{
            width: 30px;
            height: 30px;
        }
    }
}
</style>