<template>
  <div v-if="currentUser">
    <h2>Tytuły usług</h2>
    <button class="header-add-btn" v-if="!addingActive" @click="addingActive = true">dodaj tytuły</button>
    <button class="header-add-btn" v-if="addingActive" @click="addingActive = false">powrót</button>
    <div class="titles-add-cont" v-if="addingActive">
      <select name="category" id="category" v-model="selectedAddCategory">
        <option value="">--wybierz kategorię--</option>
        <option :value="category.id" :key="category.id" v-for="category in categories">{{ category.name }}</option>
      </select>
      <label for="titlesAdd">podaj tytuły po średniku (;):</label>
      <textarea name="titlesAdd" id="titlesAdd" cols="30" rows="10" v-model="titlesToAdd" placeholder="strzyżenie psa;strzyżenie królika;leczenie zęba u psa"></textarea>
      <button class="header-add-btn" @click="addTitles">dodaj</button>
    </div>
    <div class="titles-manage-cont" v-if="!addingActive">
      <select name="category" id="category" v-model="selectedCategory" @change="(e) => getTitles(selectedCategory)">
        <option value="">--wybierz kategorię tytułów--</option>
        <option :value="category.id" :key="category.id" v-for="category in categories">{{ category.name }}</option>
      </select>
      <div class="titles-block-cont" v-if="titles">
        <input type="checkbox" id="titlesBlock" v-model="titlesBlock" @change="titlesAddBlock" />
        <label for="titlesBlock">Blokada automatycznego dodawania tytułów</label>
      </div>
      <div v-if="titles">
        <input v-if="titles.length > 0" type="text" id="title" placeholder="wyszukaj tytuł" @input="(e) => searchTitles(e.target.value)">
        <span style="margin-top: 10px;">{{ titlesCount }} tytułów w bazie dla tej kategorii</span>
        <table v-if="titles.length > 0">
          <tr v-for="title in titles" :key="title.id"><td>{{ title.content }}</td><td>
            <button @click="deleteTitle(title.id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
            <!-- <button><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button> -->
          </td></tr>
        </table>
        <h3 v-else>Brak tytułów</h3>
      </div>
      <h3 v-else>Brak tytułów, wybierz kategorię</h3>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
  name: 'ServicesTitle',
  data() {
    return {
        categories: null,
        titles: null,
        titlesBlock: false,
        selectedCategory: '',
        selectedAddCategory: '',
        titlesToAdd:'',
        addingActive: false,
        titlesCount: 0
    };
  },
  props: ['isVisible'],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    } else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
    this.getCategories();
  },
  methods: {
    getCategories() {
      axios.get(`/Categories`).then((res) => {
        this.categories = res.data;
      });
    },
    getTitles(id){
      if(id){
        axios.get(`/GetServiceTitles/${id}`).then(res => {
          this.titles = res.data.serviceTitles
          this.titlesBlock = res.data.block == null ? false : res.data.block == 1 ? true : false
          this.titlesCount = this.titles.length
        })
      }
    },
    searchTitles(value){
      if(value){
        this.titles = this.titles.filter(item => item.content.toUpperCase().includes(value.toUpperCase()))
      }else{
        this.getTitles(this.selectedCategory)
      }
    },
    addTitles(){
      if(this.selectedAddCategory && this.titlesToAdd){
        // this.titlesToAdd = this.titlesToAdd.replace(/\s/g,'')
        if(this.titlesToAdd.charAt(this.titlesToAdd.length-1) == ';'){
            this.titlesToAdd = this.titlesToAdd.slice(null, -1)
        }
        if(this.titlesToAdd.charAt(0) == ';'){
          this.titlesToAdd = this.titlesToAdd.slice(1)
        }
        // const splittedTitles = this.titlesToAdd.split(';')
        // console.log(splittedTitles);

        axios.post('/AddManyServiceTitles', {
          category_id: this.selectedAddCategory,
          titles: this.titlesToAdd
        }, authHeader()).then(res => {
          if(String(res.status).charAt(0) == '2'){
            this.$store.commit('setIsSuccess', 'Pomyślnie dodano tytuły')
            this.titlesToAdd = ''
            this.selectedCategory = ''
            this.addingActive = false
          }
        })
      }else{
        this.$store.commit('setIsAlert', 'uzupełnij kategorię oraz pole tytułów')
      }
    },
    deleteTitle(id){
      axios.delete(`/DeleteServiceTitle/${id}`, authHeader()).then(res => {
        if(String(res.status).charAt(0) == '2'){
          this.$store.commit('setIsSuccess', 'Pomyślnie usunięto tytuł')
          this.getTitles(this.selectedCategory)
        }
      })
    },
    titlesAddBlock(){
      axios.post(`/AutoAddServiceTitleBlock`, {
        block: this.titlesBlock,
        category_id: this.selectedCategory
      }, authHeader()).then(res => {
        if(res.status == 200){
          this.$store.commit('setIsSuccess', 'Pomyślnie zablokowano automatyczne dodawanie tytułów')
        }
      })
    }
  },
};
</script>

<style scoped>
div.titles-block-cont{
  flex-direction: row !important;
  gap: 8px;
}
.header-add-btn{
  background-color: #F2790F;
  padding: 8px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.titles-manage-cont > div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titles-manage-cont{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  input, select{
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #b1b1b1;
  }
  table{
      border-collapse: collapse;
      margin-top: 20px;
  }
  table, tr, td, th{
      border: 1px solid #000;
  }
  td, th{
      padding: 5px 10px;
  }
  td{
      button{
          background: none;
          border: none;
          border-radius: 8px;
          padding: 5px 10px;
          cursor: pointer;
      }
      button.green{
          background-color: #5EA01A;
      }
      button.red{
          background-color: #DC3545;
      }
      button.blue{
          background-color: #007BFF;
      }
  }
}
.titles-add-cont{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  input, textarea, select{
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #b1b1b1;
  }
}
</style>
