<template>
<div v-if="currentUser">
    <div class="profile-container" v-if="currentUser.data.role === 1">
    <h2>Wybierz zakładkę</h2>
    </div>
</div>
<div v-else>
    <h2>Błąd wczytywania. Spróbuj ponownie</h2>
</div>
</template>

<script>
import { useMeta } from 'vue-meta';
export default{
name:'AdminProfile',
setup () {
    useMeta({title: 'profil użytkownika'})
},
data(){
    return{

    }
},
props:{

},
computed: {
    currentUser () {
    return this.$store.state.auth.user
    }
},
methods:{
},  
mounted(){
    if(!this.currentUser){
    this.$router.push('/login')
    }else if(this.currentUser.data.role !== 1){
    this.$router.push('/login')
    }else if(this.currentUser){
    this.$router.push('/admin-profile/categories')
    }
}
}
</script>

<style scoped lang='scss'>

</style>