<template>
    <div class="newsletter" v-if="currentUser">
        <h2>Wyślij newsletter</h2>
        <form @submit.prevent="sendToConfirm()">
            <div class="row" v-if="!showPreview">
                <div class="input-container">
                    <label for="subject">Temat:</label>
                    <input type="text" id="subject" v-model="newsletter.subject" required>
                </div>
                
                <div class="input-container" v-if="!showPreview">
                    <label for="title">Tytuł:</label>
                    <input type="text" id="title" v-model="newsletter.title" required>
                </div>

                <div class="input-container" v-if="!showPreview">
                    <label for="recipient">Grupy odbiorców:</label>
                    <select v-model="newsletter.role_id" id="recipient">
                        <option value="0">Brak</option>
                        <option value="2">Specjaliści</option>
                        <option value="3">Właściciele zwierząt</option>
                        <option value="5">Placówki</option>
                    </select>
                </div>
            </div>
            
            <div v-if="!showPreview" :style="{ 'margin-top': newsletter.role_id != 5 ? '20px' : '0'}">
                <div style="margin-bottom: 20px; margin-top: 10px;" v-if="newsletter.role_id == 5">
                    <input style="margin-right: 5px;" type="checkbox" id="addDeleteLink" name="addDeleteLink" v-model="addDeleteLink">
                    <label for="addDeleteLink">Dodaj link usuwający konto</label><br>
                    <input style="margin-right: 5px;" type="checkbox" id="addActiveLink" name="addActiveLink" v-model="addActiveLink">
                    <label for="addActiveLink">Dodaj link aktywujący konto</label>
                </div>
                <ckeditor :editor="editor" v-model="newsletter.content" :config="editorConfig"></ckeditor>
            </div>
            <div class="other-emails" v-if="!showPreview">
                <label for="emails">Pozostali odbiorcy (adresy e-mail podaj po przecinku):</label>
                <textarea name="emails" id="emails" cols="30" rows="10" v-model="newsletter.emails"></textarea>
            </div>
            <button type="submit">{{ showPreview ? 'Anuluj' : 'Wyślij' }}</button>
        </form>
        <div class="newsletter-preview" v-if="showPreview">
            <h3>Podgląd wiadomości</h3>
            <table class="desktop">
                <tr><td>Temat</td><td>{{ newsletter.subject ? newsletter.subject : 'nie podano' }}</td></tr>
                <tr><td>Do</td><td>{{ newsletter.role_id == '2' ? 'specjalistów' : newsletter.role_id == '3' ? 'właścicieli zwierząt' : newsletter.role_id == '5' ? 'placówek' : 'nie podano' }}</td></tr>
                <tr><td>Pozostali odbiorcy</td><td>{{newsletter.emails ? newsletter.emails.split(',').length : '0'}}</td></tr>
            </table>
            <table class="mobile">
                <tr><th>Temat:</th></tr>
                <tr><td>{{ newsletter.subject ? newsletter.subject : 'nie podano' }}</td></tr>
                <tr><th>Do:</th></tr>
                <tr><td>{{ newsletter.role_id == '2' ? 'specjalistów' : newsletter.role_id == '3' ? 'właścicieli zwierząt' : newsletter.role_id == '5' ? 'placówek' : 'nie podano' }}</td></tr>
                <tr><th>Pozostali odbiorcy:</th></tr>
                <tr><td>{{newsletter.emails ? newsletter.emails.split(',').length : '0'}}</td></tr>
            </table>
            <div class="newsletter-preview-mail">
                <h2>{{ newsletter.title }}</h2>
                <div v-html="newsletter.content"></div>
            </div>
            <button @click="sendNewsletter()">Potwierdź</button>
            <img class="loading" src="@/assets/icons/loading.gif" v-if="loading" alt="Ładowanie...">
        </div>
    </div>
  </template>
  
  <script>
import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic'
import { Font } from '@ckeditor/ckeditor5-font'
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { List } from '@ckeditor/ckeditor5-list';
import { Image, ImageInsert } from '@ckeditor/ckeditor5-image';
import axios from 'axios';
import authHeader from '@/services/auth-header';
  export default{
    name:"SendNewsletter",
    data(){
        return{
            newsletter:{
                subject: '',
                title: '',
                content: '',
                role_id: '0',
                emails: ''
            },
            showPreview: false,
            loading: false,
            editor: ClassicEditor,
            addDeleteLink: false,
            editorConfig: {
                plugins: [ Font, Essentials, Bold, Italic, Link, Paragraph, Heading, BlockQuote, List, ImageInsert, Image ],
                fontColor:{
                    colors: [
                        {
                            color: '#ff0000',
                            label: 'red'
                        },
                        {
                            color: '#00ff00',
                            label: 'green'
                        },
                        {
                            color: '#0000ff',
                            label: 'blue'
                        }
                    ]
                },
                toolbar: [
                    'undo', 'redo', 'heading', 'bold', 'italic', 'fontColor', 'blockQuote', 'bulletedList', 'numberedList', 'link', 'insertImage',
                ]
            },
            addActiveLink: false
        }
    },
    mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    methods:{
        sendToConfirm(){
            if(this.newsletter.content && (this.newsletter.emails || this.newsletter.role_id !== '0')){
                this.showPreview = !this.showPreview
                this.newsletter.emails = this.newsletter.emails.replace(/\s/g,'')
                if(this.newsletter.emails.charAt(this.newsletter.emails.length-1) == ','){
                    this.newsletter.emails = this.newsletter.emails.slice(null, -1)
                }
            }else{
                this.$store.commit('setIsAlert', 'uzupełnij treść i odbiorców (pozostałych lub grupy)')
            }
        },
        sendNewsletter(){
            if(this.newsletter.title && this.newsletter.subject){
                if(this.newsletter.content && (this.newsletter.emails || this.newsletter.role_id !== '0')){
                    this.loading = true
                    axios.post('/PrepareNewsletter', {
                        role_id: this.newsletter.role_id,
                        emails: this.newsletter.emails
                    }, authHeader()).then(res => {
                        if(res.data.count == 0){
                            this.$store.commit('setIsAlert', 'Brak użytkowników w bazie')
                            this.resetNewsletterForm()
                        }else{
                            this.newsletter.content = `${this.newsletter.content}<br><br><a style="font-size: 10px;" href="https://m2vet.pl/confirm-newsletter?newsletterId=[!newsletterId!]&status=true">zrezygnuj z newslettera</a>`
                            if(this.addDeleteLink){
                                this.newsletter.content = `${this.newsletter.content}<br><a style="font-size: 10px;" href="https://m2vet.pl/confirm-newsletter?newsletterId=[!newsletterId!]&status=false">usuń konto</a>`
                            }
                            if(this.addActiveLink){
                                this.newsletter.content = `${this.newsletter.content}<br><a style="font-size: 10px;" href="https://m2vet.pl/confirm-newsletter?newsletterId=[!newsletterId!]&active=true">aktywuj konto</a>`
                            }
                            axios.post('/SendNewsletter', this.newsletter, authHeader()).then(() => {
                                this.$store.commit('setIsSuccess', 'Pomyślnie wysłano newsletter')
                                this.resetNewsletterForm()
                            }).catch(() => {
                                this.resetNewsletterForm()
                            })
                        }
                    }).catch(() => {
                        
                        this.resetNewsletterForm()
                    })
                }else{
                    this.$store.commit('setIsAlert', 'uzupełnij treść i odbiorców (pozostałych lub grupy)')
                }
            }else{
                this.$store.commit('setIsAlert', 'uzupełnij temat i tytuł')
            }
        },
        resetNewsletterForm(){
            this.loading = false
            this.showPreview = false
            this.newsletter.subject = ''
            this.newsletter.title = ''
            this.newsletter.content = ''
            this.newsletter.role_id = '0'
            this.newsletter.emails = ''
        }
    }  
  }
  </script>
  
  <style scoped lang="scss">
    .newsletter{
        button{
            background-color: #F27A07;
            border: none;
            padding: 10px 30px;
            border-radius: 8px;
            cursor: pointer;
            margin-top: 20px;
        }
        form{
            .row{
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
            }
            .input-container{
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
                input, select{
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #b1b1b1;
                }
            }
            .other-emails{
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 30px;
                textarea{
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #b1b1b1;
                    resize: vertical;
                }
            }
        }
        .newsletter-preview{
            table{
                border-collapse: collapse;
                max-width: 1000px;
            }
            table,tr,td{
                border: 1px solid #000;
            }
            td{
                padding: 10px;
            }
            table.mobile{
                display: none;
                width: 100%;
            }
        }
        .newsletter-preview-mail{
            margin-top: 20px;
            border-top: 1px solid #000;
        }
        .loading{
            width: 40px;
            display: block;
            margin-top: 10px;

        }
    }
    @media(max-width: 576px){
        .newsletter{
            .newsletter-preview{
                table.desktop{
                    display: none;
                }
                table.mobile{
                    display: table;
                }
            }
        }
    }
  </style>