<template>
    <div class="invitation-confirm-wrapper">        
        <h2>Czy chcesz zaakceptować zaproszenie od placówki?</h2>
        <div class="invitation-choice">
          <button @click="confirmInvitation(true)">Tak</button>
          <button @click="confirmInvitation(false)">Nie</button>          
        </div>
        <img src="@/assets/icons/loading.gif" alt="" v-if="loading">
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'FirmInvConfirm',
    setup () {
      useMeta({ title: 'Potwierdzanie zaproszenia placówki' });
    },
    data(){
      return{
        loading: false
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if(this.$route.query.id){
        if (!this.currentUser) {
          this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 2) {
          this.$router.push('/login');
        }
      }else{
        this.$store.commit('setIsAlert', 'Niepoprawny link.')
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
        confirmInvitation(option){
            this.loading = true
            if(this.$route.query.id){
                axios.post('/AddBusinessToFirmConfirmation', {
                    id: this.$route.query.id,
                    status: option
                  }, authHeader()).then((res) => {
                    this.loading = false
                    if(`${res.status}`.charAt(0) === '2' && option){
                      this.$store.commit('setIsSuccess', 'Od teraz twoje konto jest przypisane do placówki.')
                    }
                    this.$router.push('/dla-biznesu/profile/user-data');
                }).catch(() => {

                })
            }
        }
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .invitation-confirm-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 70px;
        }
        .invitation-choice{
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          justify-content: space-between;
          button{
            padding: 5px 10px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
          }
          button:first-child{
            background-color: #5EA01A;
          }
          button:last-child{
            background-color: #DC3545;
          }
        }
    }
  </style>