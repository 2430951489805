<template>
    <h2>Stwórz zalecenie</h2>
    <div v-if="currentUser" class="create-recomm">
      <div v-if="myServices && employees" class="calendar-container">
        <ServiceModal/>
        <span>1. wybierz usługę, której będzie dotyczyło zalecenie</span>
        <table class="calendar-container__services-table">
          <tr><th>nazwa</th><th>pracownik</th><th>akcje</th></tr>
          <tr v-for="service in myServices" :key="service.service.id">
            <td>{{ service.service.name }}</td>
            <td>{{ service.service.employee_id ? `${employees.filter(item => item.id == service.service.employee_id)[0].firstname} ${employees.filter(item => item.id == service.service.employee_id)[0].lastname}` : 'brak pracownika' }}</td>
            <td>
              <button @click="() => $router.push({path: `/dla-biznesu/profile/recommendations/add`, query: {serviceID: service.service.id}})"><img src="@/assets/icons/eye-fill.svg" alt="podgląd usługi"></button>
              <button @click="() => choosedServiceId = service.service.id"><img src="@/assets/icons/square-fill.svg" alt="wybierz" v-if="choosedServiceId == service.service.id"><img v-if="choosedServiceId !== service.service.id" src="@/assets/icons/square.svg" alt="wybierz"></button>
            </td></tr>
        </table>
      </div>
      <div class="date-choose" v-if="choosedServiceId">  
        <span>2. wybierz datę wizyty</span>
        <input type="date" name="visitDate" id="visitDate" v-model="visitDate" @input="getVisits()">
      </div>
      <div class="vist-choose" v-if="choosedServiceId && visitDate && visits">
        <span>3. wybierz wizytę</span>
        <div v-if="visits.length > 0" class="vist-choose__times">
          <span class="vist-choose__times__time" :style="{textDecoration: choosedVisit == visit ? 'underline' : null}" @click="() => {
            choosedVisit = visit
            getUserData()
          }" v-for="visit in visits" :key="visit.id">{{ visit.time }}</span>
        </div>
        <div v-else>
          <span>brak wizyt</span>
        </div>
      </div>
      <div class="submit-recomm" v-if="choosedServiceId && visitDate && choosedVisit && userData">
        <span>4. wyślij zgłoszenie</span>
        <span>dla:</span>
        <b>{{ userData.firstname }} {{ userData.lastname }}</b>
        <b>{{ userData.pets.filter(pet => choosedVisit.pet_id == pet.id)[0].pet_name }}</b>
        <form @submit.prevent="sendRecomm()">
          <label for="recommContent">Treść zalecenia*</label>
          <textarea required v-model="newRecomm.content" name="recommContent" id="recommContent"></textarea>

          <label for="recommPhoto1">Grafika 1</label>
          <input type="file" @change="(e) => newRecomm.photo1 = e.target.files[0]" name="recommPhoto1" id="recommPhoto1" accept="image/png, image/jpeg">

          <label for="recommPhoto2">Grafika 2</label>
          <input type="file" @change="(e) => newRecomm.photo2 = e.target.files[0]" name="recommPhoto2" id="recommPhoto2" accept="image/png, image/jpeg">

          <button type="submit">Wyślij</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
  export default{
    name:'BizProfileCreateRecommendation',
    setup () {
      useMeta({title: 'Stwórz zalecenie'})
    },
    data(){
      return{
        myServices: null,
        employees: null,
        choosedServiceId: null,
        visitDate: null,
        visits: null,
        choosedVisit: null,
        userData: null,
        newRecomm:{
          content: '',
          photo1: null,
          photo2: null
        }
      }
    },
    props:{
  
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    components:{
      ServiceModal
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      if(this.$store.state.isSubscribed){
        this.getMyServices()
        this.getEmployees()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
      getUserData(){
        if(this.choosedVisit){
          axios.get(`/User/${this.choosedVisit.user_id}`).then(res => {
            this.userData = res.data
          })
        }
      },
      getVisits(){
        axios.get(`/CalendarItemsForService/${this.choosedServiceId}`).then(res => {
          this.visits = res.data.filter(item => item.date == this.visitDate && item.user_id)
        })
      },
      getMyServices(){
        axios.get(`/UserService/${this.currentUser.data.user_id}`).then(res => {
          this.myServices = res.data
        }).catch(() => {
                      
                  })
      },
      getEmployees(){
      axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 100}, authHeader())
          .then(res => {
              this.employees = res.data.items
          }).catch(() => {
            
          })
      },
      sendRecomm(){
        if(this.choosedVisit && this.userData){
          const ob = {
            pet_id: this.choosedVisit.pet_id,
            content: `${this.userData.firstname} ${this.userData.lastname},${this.userData.pets.filter(pet => this.choosedVisit.pet_id == pet.id)[0].pet_name}|${this.newRecomm.content}`,
          }
          if(this.newRecomm.photo1){
            ob.photo = this.newRecomm.photo1
          }
          if(this.newRecomm.photo2){
            ob.second_photo = this.newRecomm.photo2
          }
          axios.post(`/Recommendation`, ob, { 
            headers:{
              Authorization: `${authHeader().headers.Authorization}`,
              ['Content-Type']: 'multipart/form-data'
            }
          }).then(() => {
            this.$store.commit('setIsSuccess', 'Pomyślnie wysłano zalecenie')
            this.$router.push('/dla-biznesu/profile/recommendations')
          }).catch(() => {
            
          })
        }
      }
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .calendar-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  &__services-table{
    border: 1px solid #b1b1b1;
    border-collapse: collapse;
    tr, td, th{
      border: 1px solid #b1b1b1;
    }
    tr{
      td, th{
        padding: 5px 8px;
        max-width: 400px;
        button{
          background: none;
          border: none;
          cursor: pointer;
          img{
            width:20px;
          }
        }
      }
    }
  }
}
.date-choose{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
}
.vist-choose{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
  &__times{
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    gap: 15px;
    &__time{
      cursor: pointer;
    }
  }
}

.submit-recomm{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
  form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 390px;
    textarea, input{
      border: 1px solid #9b9b9b;
      padding: 8px;
      border-radius: 8px;
    }
    textarea{
      resize: vertical;
    }
    button{
      background-color: #F27A07;
      border: none;
      padding: 8px 16px;
      border-radius: 8px;
      margin-top: 20px;
    }
  }
}
  </style>