<template>
    <div v-if="currentUser && $route.params.serviceId" class="service-calendar-container">
        <ServiceModal/>
        <CalendarUserModal @reloadCalendar="getServiceCalendar()"/>
        <ModalVue :fields="[
            {
                name: 'dateInput',
                tag: 'input',
                req: true,
                type: 'date'
            },
            {
                name: 'timeInput',
                tag: 'input',
                req: true,
                type: 'time'
            }
        ]" title="wybierz element do usunięcia" button-text="usuń" :is-visible="isDeleteModalVisible" @closeModal="() => {isDeleteModalVisible=false}" @sendData="(data) => removeCalendarItem(data)"/>
        <ModalVue :fields="[
            {
                name: 'dateInput',
                tag: 'input',
                req: true,
                type: 'date'
            },
            {
                name: 'timeInput',
                tag: 'input',
                req: true,
                type: 'time'
            }
        ]" title="wybierz wizytę do anulowania" button-text="anuluj" :is-visible="isCancelModalVisible" @closeModal="() => {isCancelModalVisible=false}" @sendData="(data) => cancelVisit(data)"/>
        <h2>Kalendarz usługi</h2>
        <div class="service-calendar-container__top-buttons">
            <ButtonVue text="podgląd usługi" @click="() => $router.push({path: $route.path, query: {serviceID: $route.params.serviceId}})" bg-color="#f2790f" color="#000"/>
            <ButtonVue text="dodaj element" bg-color="#b1b1b1" color="#000"  @click="() => isAddingVisible = true"/>
            <ButtonVue text="dodaj elementy" bg-color="#b1b1b1" color="#000"  @click="() => isSerialAddingVisible = true"/>
            <ButtonVue text="usuń element" bg-color="#BB2D3B" color="#000"  @click="() => isDeleteModalVisible = true"/>
            <ButtonVue text="anuluj wizytę" bg-color="#0B5ED7" color="#000"  @click="() => isCancelModalVisible = true"/>
        </div>
        <div class="service-calendar-container__add-element-container" v-if="isAddingVisible" ref="addCalendarItemForm">
            <button class="close-btn" @click="() => isAddingVisible = false"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
            <input type="date" v-model="newCalendarItemDate">
            <button @click="() => timeFieldCount++">dodaj pole godziny</button>
            <button @click="() => {
                if(timeFieldCount>1){
                    timeFieldCount--
                }
            }">odejmij pole godziny</button>
            <input type="time" v-for="timeField in timeFieldCount" :key="timeField">
            <ButtonVue text="zatwierdź" bg-color="#f2790f" color="#000" @click="addCalendarItem"/>
        </div>
        <div class="service-calendar-container__serial-add-element-container" v-if="isSerialAddingVisible">
            <button class="close-btn" @click="() => isSerialAddingVisible = false"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
            <div>
                <table>
                    <tr>
                        <th>pon.
                            <div>
                                <button @click="serialHours[0].push('')">+</button>
                                <button @click="serialHours[0].pop()">-</button>
                            </div>
                        </th>
                        <th>wt.
                            <div>
                                <button @click="serialHours[1].push('')">+</button>
                                <button @click="serialHours[1].pop()">-</button>
                            </div>
                        </th>
                        <th>śr.
                            <div>
                                <button @click="serialHours[2].push('')">+</button>
                                <button @click="serialHours[2].pop()">-</button>
                            </div>
                        </th>
                        <th>czw.
                            <div>
                                <button @click="serialHours[3].push('')">+</button>
                                <button @click="serialHours[3].pop()">-</button>
                            </div>
                        </th>
                        <th>pt.
                            <div>
                                <button @click="serialHours[4].push('')">+</button>
                                <button @click="serialHours[4].pop()">-</button>
                            </div>
                        </th>
                        <th>sob.
                            <div>
                                <button @click="serialHours[5].push('')">+</button>
                                <button @click="serialHours[5].pop()">-</button>
                            </div>
                        </th>
                        <th>niedz.
                            <div>
                                <button @click="serialHours[6].push('')">+</button>
                                <button @click="serialHours[6].pop()">-</button>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td v-for="x in 7" :key="x">
                            <div>
                                <input type="time" id="serialHourInput" :data-hourx="x" v-for="serialHour in serialHours[x-1].length" :key="serialHour" />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="repeatability" v-if="isSerialAddingVisible">
            <span>Powtarzaj od tygodnia (włącznie):</span>
            <select name="AddSerialDateFrom" id="AddSerialDateFrom" v-model="serialAddRepeatabilityFrom">
                <option value="">-- wybierz tydzień --</option>
                <option :value="week.value" v-for="week, index in weeksList" :key="index">{{ week.text }}</option>
            </select>
            <span>do tygodnia (włącznie):</span>
            <select name="AddSerialDateTo" id="AddSerialDateTo" v-model="serialAddRepeatabilityTo">
                <option value="">-- wybierz tydzień --</option>
                <option :value="week.value" v-for="week, index in weeksList" :key="index">{{ week.text }}</option>
            </select>
            <span v-if="serialAddLoading">Dodawanie elementów kalendarza ({{ dataToSendLeft.length }} / {{ serialToAddElems }})</span>
            <span v-if="serialAddError" class="sending-error">{{ serialAddError }}</span>
            <img class="sending-loading" src="@/assets/icons/loading.gif" v-if="serialAddLoading">
            <button @click="addSerialCarendarItems" v-if="!serialAddLoading">dodaj</button>
            <button @click="sendingSerialElementsStop = true" v-if="serialAddLoading">anuluj</button>
        </div>
        <div class="service-calendar-container__view">
            <select name="datesViewSelect" id="datesViewSelect" v-model="datesViewSelect" @change="setView">
                <option value="day">dzisiaj</option>
                <option value="sevenDays">7 dni do przodu</option>
                <option value="month">miesiąc</option>
                <option value="date">konkretna data</option>
            </select>
            <input type="date" name="viewDate" id="viewDate" v-model="viewDate" v-if="datesViewSelect == 'date'" @input="setView">
            <input type="month" name="viewMonth" id="viewMonth" v-model="viewMonth" v-if="datesViewSelect == 'month'" @input="setView">
        </div>
        <div class="service-calendar-container__table-cont">
            <table class="service-calendar-container__table-cont__table" ref="calendarTable" v-if="dates.length > 0 && datesViewSelect !== 'month'">
                <tr><td>godzina</td><td v-for="date in dates.slice(dates.indexOf(new Date().toJSON().slice(0, 10)), dates.indexOf(new Date().toJSON().slice(0, 10))+7)" :key="date">{{ date }}</td></tr>
                <tr v-for="hour in hours" :key="hour"><td>{{ hour }}</td><td v-for="date in dates.slice(dates.indexOf(new Date().toJSON().slice(0, 10)), dates.indexOf(new Date().toJSON().slice(0, 10))+7)" :key="date" :data-timeInfo="`${date}T${hour}`" @click="(e) => {
                    if(e.target.dataset.user_id && e.target.dataset.pet_id && e.target.dataset.item_id && e.target.dataset.pd){
                        $router.push({path: `/dla-biznesu/profile/calendar/${$route.params.serviceId}`, query: {userid: e.target.dataset.user_id, petid: e.target.dataset.pet_id, item_id: e.target.dataset.item_id, pd: e.target.dataset.pd}})
                    }
                }"></td></tr>
            </table>
            <table class="service-calendar-container__table-cont__table" style="width: auto; min-width: 990px;" v-if="daysInMonth.length > 0 && datesViewSelect == 'month'" ref="monthTable" id="monthTable">
                <tr>
                    <td :style="{border: todaysDay == day ? '2px solid black' : '1px solid #b1b1b1'}" style="width: 130px;" v-for="day,index in daysInMonth.slice(0,7)" :key="index" :data-day="day"><div>{{ day }}<br></div></td>
                </tr>
                <tr>
                    <td :style="{border: todaysDay == day ? '2px solid black' : '1px solid #b1b1b1'}" style="width: 130px;" v-for="day,index in daysInMonth.slice(7,14)" :key="index" :data-day="day"><div>{{ day }}<br></div></td>
                </tr>
                <tr>
                    <td :style="{border: todaysDay == day ? '2px solid black' : '1px solid #b1b1b1'}" style="width: 130px;" v-for="day,index in daysInMonth.slice(14,21)" :key="index" :data-day="day"><div>{{ day }}<br></div></td>
                </tr>
                <tr>
                    <td :style="{border: todaysDay == day ? '2px solid black' : '1px solid #b1b1b1'}" style="width: 130px;" v-for="day,index in daysInMonth.slice(21,28)" :key="index" :data-day="day"><div>{{ day }}<br></div></td>
                </tr>
                <tr>
                    <td :style="{border: todaysDay == day ? '2px solid black' : '1px solid #b1b1b1'}" style="width: 130px;" v-for="day,index in daysInMonth.slice(28,35)" :key="index" :data-day="day"><div>{{ day }}<br></div></td>
                </tr>
            </table>
        </div>
        <div class="service-calendar-container__map-description">
            <span style="color: #bdbdbd;">brak elementu - nikt nie może zapisać się na ten termin</span>
            <span style="color: #5ea01a;">element aktywny - termin jest wolny i można się na niego umówić</span>
            <span style="color: #f2790f;">element zazrezerwowany - termin jest zarezerwowany</span>
        </div>
    </div>
</template>

<script>
import ButtonVue from '@/components/Button.vue';
import { useMeta } from 'vue-meta';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import CalendarUserModal from '@/components/SpecialComponents/CalendarUserModal.vue';
import ModalVue from '@/components/Modal.vue';
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default{
    name: 'ServiceCalendar',
    setup() {
        useMeta({ title: 'Kalendarz usługi' });
    },
    data() {
        return {
            dates: [],
            hours: [],
            items: [],
            isAddingVisible: false,
            isSerialAddingVisible: false,
            timeFieldCount:1,
            isDeleteModalVisible: false,
            newCalendarItemDate: '',
            datesViewSelect: 'day',
            viewDate: '',
            isCancelModalVisible: false,
            serialHours:[
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ],
            weeksList:[],
            serialAddRepeatabilityFrom: '',
            serialAddRepeatabilityTo: '',
            dataToSendLeft: [],
            serialAddLoading: false,
            serialToAddElems: 0,
            serialAddError: null,
            sendingSerialElementsStop: false,
            viewMonth: '',
            daysInMonth: [],
            todaysDay: ''
        };
    },
    props: {},
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 2) {
            this.$router.push('/login');
        }
        if(this.$store.state.isSubscribed){
            this.getServiceCalendar()
        }else{
            this.$router.push('/dla-biznesu/profile/user-data')
        }
        this.createWeeksList()
        this.todaysDay = new Date().toISOString().split('T')[0]
    },
    methods: {
        addSerialCarendarItems(){
            document.querySelectorAll('#serialHourInput').forEach(elem => {
                this.serialHours[elem.dataset.hourx-1].push(elem.value)
                this.serialHours[elem.dataset.hourx-1].shift()
            })
            let errors = 7
            this.serialHours.forEach(item => {
                if(item.length > 0){
                    errors = errors - 1
                }
            })
            if(errors < 7 && this.serialAddRepeatabilityFrom && this.serialAddRepeatabilityTo){
                let selectedSerialHoursOB = {}
                let howManyRepeatabilityDays = (new Date(this.serialAddRepeatabilityTo).getTime() - new Date(this.serialAddRepeatabilityFrom).getTime())/(1000*60*60*24)
                const serialAddRepeatabilityFrom1 = new Date(this.serialAddRepeatabilityFrom)
                serialAddRepeatabilityFrom1.setDate(serialAddRepeatabilityFrom1.getDate()-1)
                let dateFrom = new Date(serialAddRepeatabilityFrom1.toISOString())
                for(let x = 0; x<Math.ceil(howManyRepeatabilityDays/7)+1; x++){
                    this.serialHours.forEach((item) => {
                        dateFrom.setDate(dateFrom.getDate()+1)
                        selectedSerialHoursOB[dateFrom.toISOString().split('T')[0]] = item
                    })
                }
                let filteredSelectedSerialHoursOB = {}
                for(const ob in selectedSerialHoursOB){
                    if(Object.keys(selectedSerialHoursOB[ob]).length > 0){
                        const filteredSelectedSerialHoursArr = []
                        Object.values(selectedSerialHoursOB[ob]).forEach(elem => {
                            if(!filteredSelectedSerialHoursArr.includes(elem)){
                                filteredSelectedSerialHoursArr.push(elem)   
                            }
                        })
                        filteredSelectedSerialHoursOB[ob] = filteredSelectedSerialHoursArr
                    }  
                }
                let dataToSend = []
                for(const data in filteredSelectedSerialHoursOB){
                    dataToSend.push({
                        date_from: data,
                        date_to: data,
                        hours: filteredSelectedSerialHoursOB[data],
                        service_id: this.$route.params.serviceId
                    })
                }
                const datesToCheck = []
                this.items.forEach(calItem => {
                    datesToCheck.push(calItem.date)
                })
                const compliantItems = dataToSend.filter(data => datesToCheck.includes(data.date_from))
                if(compliantItems.length > 0){
                    compliantItems.forEach(comItem => {
                        delete dataToSend[dataToSend.indexOf(comItem)]
                    })
                }
                this.dataToSendLeft = dataToSend
                this.serialToAddElems = dataToSend.length
                this.sendSerialElements()
            }else{
                this.$store.commit('setIsAlert', 'uzupełnij kalendarz chociaż jedną godziną i ustaw powtarzalność')
            }
        },
        sendSerialElements(){
            if(this.dataToSendLeft.length > 0 && !this.sendingSerialElementsStop){
                this.serialAddLoading = true
                axios.post('/CalendarItem', this.dataToSendLeft[0], authHeader()).then(res => {
                    if(String(res.status).charAt(0) == '2'){
                        this.dataToSendLeft.shift()
                        setTimeout(() => {
                        this.sendSerialElements()
                        }, 1000)
                    }
                }).catch(() => {
                    this.dataToSendLeft.shift()
                    this.serialAddError = `Nie dodano niektórych elementów!`
                    this.sendSerialElements()
                })
            }else{
                this.serialAddLoading = false
                this.serialHours = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                this.isSerialAddingVisible = false
                this.serialAddRepeatabilityTo = ''
                this.serialAddRepeatabilityFrom = ''
                this.$store.commit('setIsSuccess', 'Pomyślnie dodano elementy')
                this.getServiceCalendar()
            }
        },
        createWeeksList(){
            const daysInTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            let currentDate = new Date()
            currentDate.setDate(new Date().getDate()+(7 - (daysInTheWeek.indexOf(new Date().toLocaleDateString('en-US', { weekday: 'long' })))))
            for(let x = 0; x < 50; x++){
                let multiMondayFrom = new Date(currentDate)
                multiMondayFrom.setDate(multiMondayFrom.getDate()+(7*x))
                let multiMondayTo = new Date(multiMondayFrom)
                multiMondayTo.setDate(multiMondayTo.getDate()+7)
                this.weeksList.push({
                    value: multiMondayFrom.toISOString().split('T')[0],
                    text: `${multiMondayFrom.toISOString().split('T')[0]} - ${multiMondayTo.toISOString().split('T')[0]}`
                })
            }
        },
        fillCells(){
            setTimeout(() => {
                this.$refs.calendarTable.querySelectorAll('td').forEach(el => {
                    if(el.dataset.timeinfo){
                        if(this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1]).length > 0){
                            if(this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].user_id){
                                el.style = 'background-color: #f2790f; cursor: pointer;'
                                el.dataset.user_id = this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].user_id
                                el.dataset.pet_id = this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].pet_id
                                el.dataset.item_id = this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].id
                                el.dataset.pd = Boolean(this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].payment_url &&
                                    this.items.filter(item => item.date == el.dataset.timeinfo.split('T')[0] && item.time == el.dataset.timeinfo.split('T')[1])[0].payment_price)
                                el.textContent = 'szczegóły'
                            }else{
                                el.style = 'background-color: #5ea01a;'
                                el.dataset.user_id = null
                                el.dataset.pet_id = null
                                el.dataset.item_id = null
                                el.textContent = ''
                            }
                        }else{
                            el.style = 'background-color: #bdbdbd;'
                        }
                    }
                })
            }, 100)
        },
        fillMonthCells(){
            setTimeout(() => {
                this.$refs.monthTable.querySelectorAll('td').forEach(el => {
                    if(el.dataset.day){
                        const dayItems = this.items.filter(item => item.date == el.dataset.day)
                        if(dayItems.length > 0){
                            dayItems.forEach(item => {
                                if(item.user_id){
                                    const hour = document.createElement('a')
                                    const br = document.createElement('br')
                                    hour.style = 'color: #f2790f;'
                                    hour.addEventListener('click', () => {
                                        this.$router.push({path: `/dla-biznesu/profile/calendar/${this.$route.params.serviceId}`, query: {userid: item.user_id, petid: item.pet_id, item_id: item.item_id, pd: Boolean(item.payment_url && item.payment_price)}})
                                    })
                                    hour.setAttribute('href', '#monthTable')
                                    hour.textContent = item.time
                                    el.appendChild(hour)
                                    el.appendChild(br)
                                }else{
                                    const hour = document.createElement('span')
                                    const br = document.createElement('br')
                                    hour.style = 'color: #5ea01a;'
                                    hour.textContent = item.time
                                    el.appendChild(hour)
                                    el.appendChild(br)
                                }   
                            })
                        }
                        else{
                            el.querySelectorAll('a,span,br').forEach(elem => {
                                elem.remove()
                            })
                        }
                    }
                })
            }, 100)
        },
        addCalendarItem (){
            const timeFileds = this.$refs.addCalendarItemForm.querySelectorAll('input[type="time"]')
            if(this.newCalendarItemDate){
                let timeValues = []
                timeFileds.forEach(e => {
                    if(e.value){
                        timeValues.push(e.value)
                    }
                })
                if(timeValues.length < timeFileds.length){
                    this.$store.commit('setIsAlert', 'wypełnij wszystkie pola')
                }else{
                    const checkSelectedDates = this.items.filter(item => item.date == this.newCalendarItemDate)
                    checkSelectedDates.forEach(item => {
                        timeValues.forEach(t => {
                            if(t == item.time){
                                this.$store.commit('setIsAlert', `${t} jest już aktywna. Wybierz inną`)
                                timeValues = timeValues.filter(time => time !== t)
                            }
                        })
                    })
                    if(timeValues == 0){
                        this.$store.commit('setIsAlert', `żadna godzina nie pasuje. Wybierz inną`)
                    }else{
                        const reqBody = {
                            date_from: this.newCalendarItemDate,
                            date_to: this.newCalendarItemDate,
                            hours: timeValues,
                            service_id: this.$route.params.serviceId
                        }
                        axios.post('/CalendarItem', reqBody, authHeader()).then(res => {
                            if(String(res.status).charAt(0) == '2'){
                                this.$store.commit('setIsSuccess', 'Pomyślnie dodano element')
                                this.getServiceCalendar()
                            }
                        }).catch(() => {
                            
                        })
                    }
                }
            }else{
                this.$store.commit('setIsAlert', 'wypełnij wszystkie pola')
            }
        },
        getServiceCalendar(){
            axios.get(`/CalendarItemsForService/${this.$route.params.serviceId}`).then(res => {
                this.items = res.data
                this.dates.push(new Date().toJSON().slice(0, 10))
                this.items.forEach(e => {
                    if(!this.hours.includes(e.time)){
                        this.hours.push(e.time)
                    }
                })
                this.hours.sort((date1, date2) => date1.split(':')[0] - date2.split(':')[0])
                this.fillCells()
            }).catch(() => {
                
            })
        },
        setView(){
            this.dates = []
            switch(this.datesViewSelect){
                case 'sevenDays':
                    for(let x = 0; x < 7; x++){
                        this.dates.push(this.getNextDays(new Date (), x).toISOString().slice(0, 10))
                    }
                    this.fillCells()
                    break
                case 'day':
                    this.viewDate = ''
                    this.dates.push(new Date().toJSON().slice(0, 10))
                    this.fillCells()
            }
            if(this.viewDate){
                this.dates.push(this.viewDate)
                this.fillCells()
            }
            if(this.viewMonth){
                this.daysInMonth = []
                const lastDayInMonth = new Date(Number(this.viewMonth.split('-')[0]), Number(this.viewMonth.split('-')[1]), 1)
                const firstDayInMonth = new Date(`${this.viewMonth}-01`)
                const timeBetween = lastDayInMonth.getTime() - firstDayInMonth.getTime()
                const daysBetween = timeBetween / (1000*60*60*24)
                for(let x = 0; x<Math.round(daysBetween); x++){
                    this.daysInMonth.push(`${this.viewMonth}-${x+1}`)
                }
                this.fillMonthCells()
            }
        },
        getNextDays(currentDate = new Date(), daysToAdd = 1){
            const nextDate = new Date(currentDate)
            nextDate.setDate(currentDate.getDate() + daysToAdd)
            return nextDate
        },
        removeCalendarItem(data){
            if(this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput).length > 0){
                const itemToRemoveId = this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput)[0].id
                axios.delete(`/CalendarItem/${itemToRemoveId}`, authHeader()).then(res => {
                    if(String(res.status).charAt(0) == '2'){
                        this.$store.commit('setIsSuccess', 'Pomyślnie usunięto element')
                        this.getServiceCalendar()
                    }
                })
            }else{
                this.$store.commit('setIsAlert', `Nie ma takiego elementu`)
            }
        },
        cancelVisit(data){
            if(this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput).length > 0){
                if(this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput)[0].user_id){
                    const itemToCancelId = this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput)[0].id
                    const itemToCancelUserId = this.items.filter(item => item.date == data.dateInput && item.time == data.timeInput)[0].user_id
                    axios.post('/CalendarItemRemoveUser', {
                        calendar_id: itemToCancelId,
                        user_id: itemToCancelUserId,
                        employee_id: 0
                    }, authHeader()).then(res => {
                        if(String(res.status).charAt(0) == '2'){
                        this.$store.commit('setIsSuccess', 'anulowano wizytę')
                        this.getServiceCalendar()
                        }
                    }).catch(() => {
                        
                    })
                }else{
                    this.$store.commit('setIsAlert', `Nikt nie jest umówiony na ten termin`)
                }
            }else{
                this.$store.commit('setIsAlert', `Nie ma takiego elementu`)
            }
        }
    },
    components: { ButtonVue, ServiceModal, CalendarUserModal, ModalVue }
}
</script>

<style scoped lang='scss'>
.sending-loading{
    width: 40px;
}
.service-calendar-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__table-cont{
        margin-top: 30px;
        overflow: scroll;
        max-width: 1100px;
        max-height: 500px;
        width: 95%;
        &__table{
            width: 1700px;
            border: 1px solid #b1b1b1;
            border-collapse: collapse;
            tr, td{
                border: 1px solid #b1b1b1;
            }
            tr{
                td{
                    padding: 5px;
                }
            }
        }
    }
    &__map-description{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__top-buttons{
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__add-element-container{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        align-items: center;
        border: 1px solid #b1b1b1;
        border-radius: 8px;
        margin: 20px 0;
        width: 300px;
        *{
            width: 90%;
        }
        input{
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #000;
        }
        button{
            background: none;
            border: none;
            cursor: pointer;
        }
        
    }
    &__serial-add-element-container{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        max-width: 1100px;
        max-height: 500px;
        width: 95%;
        table{
            // border: 1px solid #000;
            min-width: 800px;
            // border-collapse: collapse;
            // tr,td,th{
            //     border: 1px solid #000;
            // }
            tr{
                th{
                    text-align: center;
                    button{
                        background: #b1b1b1;
                        border: none;
                        border-radius: 8px;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        margin: 0 6px;
                    }
                }
                td > div{
                    height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        min-width: 150px;
                        input{
                            padding: 8px;
                            border-radius: 8px;
                            border: 1px solid #000;
                            margin: 6px 0;
                        }
                }
            }
        }
        .close-btn{
            background: none;
            border: none;
            cursor: pointer;
            margin-bottom: 20px;
        }   
    }
    .repeatability{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        gap: 20px;
        select{
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #9e9e9e;
        }
        button{
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #9e9e9e;
        }
    }
    &__view{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        *{
            border: 1px solid #b1b1b1;
            padding: 5px;
            border-radius: 8px;
        }
    }
}
.close-btn{
    img{
        width: 25px;
        height: 25px;
    }
}
td.empty{
    background-color: #bdbdbd;
}
td.set{
    background-color: #5ea01a;
}
td.reserved{
    background-color: #f2790f;
}
</style>