<template>
    <button class="entry-wrapper__back-btn" @click="$router.push('/blog')"><img src="@/assets/icons/arrow-left-short.svg">powrót do wszystkich wpisów</button>
    <div class="entry-wrapper" v-if="entryData">
        <div class="entry-wrapper__row-align">
            <h1>{{ entryData.title }}</h1>
            <span>{{ entryData.created_at.split('T')[0] }}</span>
        </div>
        <div class="entry-wrapper__separate-line"></div>
        <p>{{ entryData.short_description }}</p>
        <p class="entry-wrapper__desc" v-html="entryData.description"></p>
        <img class="entry-wrapper__img" :src="entryData.photo_url" :alt="entryData.title">
        <div class="entry-wrapper__row-align" style="margin-top: 20px;">
            <span class="entry-wrapper__author">&#8212;&nbsp;{{ entryData.author }}</span>
            <span>{{ entryData.created_at.split('T')[0] }}</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { useMeta } from 'vue-meta'
export default{
  name:'EntryPage',
  setup () {
    useMeta({title: 'wpis'})
  },
  data(){
    return{
        entryData: null
    }
  },
  props:{

  },
  mounted(){
    this.getEntryData()
  },
  methods:{
    getEntryData(){
        if(!this.entryData){
            axios.post('/BlogPostSearch', {
                page: 1,
                limit: 1000
            }).then(res => {
                if(res.data.count > 1000){
                    axios.post('/BlogPostSearch', {
                        page: 1,
                        limit: res.data.count
                    }).then(resp => {
                        this.entryData = resp.data
                        this.entryData = this.entryData.items.filter(item => item.id == this.$route.params.entryID)[0]
                    }).catch(() => {
                
            })
                }else{
                    this.entryData = res.data
                    this.entryData = this.entryData.items.filter(item => item.id == this.$route.params.entryID)[0]
                }
            }).catch(() => {
                
            })
        }
    }
  }
}
</script>

<style scoped lang='scss'>
.entry-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    p{
        margin: 0;
    }
    &__row-align{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        h1{
            width: 90%;
        }
    }
    &__desc{
        font-size: 1.7rem;
    }
    &__img{
        width: 90%;
        max-width: 700px;
        height: 350px;
        object-fit: cover;
    }
    &__author{
        font-style: italic;
        font-weight: 900;
    }
    &__back-btn{
        background-color: #F2790F;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        img{
            width: 20px;
            height: 20px;
        }
    }
    &__separate-line{
        height: 1px;
        width: 100%;
        background-color: #000;
    }
}
</style>