<template>
    <h2>Dodaj usługę</h2>
    <div class="add-service-wrapper">        
        <form class="add-service-wrapper__form" @submit="(e) => addNewService(e)">
            <input type="text" placeholder="nazwa" name="serviceName" id="serviceName" v-model="service.title" @input="filterTitles" required maxlength="50" minlength="3">
            <!-- <select name="exampleTitles" id="exampleTitles" class="example-titles" @change="(e) => service.title = e.target.value">
              <option value="">--przykładowe nazwy--</option>
              <option :value="title.content" v-for="title in titles" :key="title.id">{{ title.content }}</option>
            </select> -->
            <div class="example-titles" v-if="service.title">
              <span v-for="title in titles" :key="title.id" @click="service.title = title.content">{{ title.content }}</span>
            </div>
            <textarea placeholder="krótki opis" v-model="service.short_description" name="short_description" required maxlength="350" minlength="20"></textarea>
            <textarea placeholder="treść" v-model="service.description" name="content" required maxlength="3000" minlength="80"></textarea>
            <input type="text" placeholder="adres" name="address" id="address" v-model="service.address" required maxlength="50" minlength="3">
            <select name="state" id="state" v-model="service.state">
                <option :value="state.value" :key="state.value" v-for="state in states">{{ state.name }}</option>
            </select>
            <input type="tel" placeholder="numer telefonu" name="phone" id="phone" v-model="service.phone" required pattern="[0-9]{9}">
            <input type="number" placeholder="cena" name="price" id="price" v-model="service.price" required>
            <label for="price">wartość zaliczki w % (Jeśli nie oczekujesz zaliczki, wpisz 0)</label>
            <input type="number" name="price" id="price" placeholder="zaliczka" v-model="service.advanceAmount" max="100" required>
            <label for="photo">zdjęcie wyróżniające</label>
            <input type="file" name="photo" id="photo" @change="(e) => onFileUpload(e)" required>
            <label for="state">pracownik</label>
            <select name="state" id="state" v-model="service.employee">
                <option :value="employee.id" :key="employee.id" v-for="employee in employees">{{ employee.firstname }} {{ employee.lastname }}</option>
            </select>
            <button type="submit">Zatwierdź</button>
        </form>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'BizProfileAddServiceVue',
    setup () {
      useMeta({ title: 'Dodaj usługę' });
    },
    data(){
      return{
        states:[],
        service:{
            title: '',
            short_description: '',
            description: '',
            address: '',
            state: '',
            phone: '',
            price: '',
            photo: null,
            employee: '',
            advanceAmount: 0
        },
        employees: [],
        titles: null,
        titlesConst: null
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }

      if(this.$store.state.isSubscribed){
        this.getStates()
        this.getEmployees()
        this.getTitles()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
        getTitles(){
          axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            axios.get(`/GetServiceTitles/${res.data.category_id}`).then(res => {
              this.titles = res.data.serviceTitles
              this.titlesConst = res.data.serviceTitles
            })
          })
        },
        filterTitles(){
          this.titles = this.titlesConst
          this.titles = this.titles.filter(title => title.content.toLowerCase().includes(this.service.title.toLowerCase()))       
        },
        getStates(){
            axios.get('/State').then(res => {
                res.data.forEach((item) => {
                    const ob = {
                        name: item.name,
                        value: `${item.id}`
                    }
                    this.states.push(ob)
                })
                this.service.state = this.states[0].value
            })
        },
        onFileUpload(event){
            this.service.photo = event.target.files[0]
        },
        addNewService(e){
            e.preventDefault()
            if(this.service.photo){
                const newServiceData = {
                    name: this.service.title,
                    short_description: this.service.short_description,
                    description: this.service.description,
                    address: this.service.address,
                    state_id: this.service.state,
                    phone: this.service.phone,
                    price: this.service.price,
                    photo: this.service.photo,
                    employee_id: this.service.employee ? this.service.employee : null,
                    advanceAmount: this.service.advanceAmount
                }
                axios.post('/Service', newServiceData, { 
                    headers:{
                      Authorization: `${authHeader().headers.Authorization}`,
                      ['Content-Type']: 'multipart/form-data'
                    }
                  }).then(res => {
                    if(String(res.status).charAt(0) == '2'){
                      this.$store.commit('setIsSuccess', 'Pomyślnie dodano usługę')
                      this.$router.push('/dla-biznesu/profile/services-provided/')
                    }
                  })
            }else{
              this.$store.commit('setIsAlert', 'wgraj zdjęcie')
            }
        },
        getEmployees(){
        axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 100}, authHeader())
            .then(res => {
                this.employees = res.data.items
                if(this.employees.length > 0){
                  this.service.employee = this.employees[0].id
                }
            }).catch(() => {
              
            })
        },
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .add-service-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 500px;
            gap: 20px;
            *:not(label){
                padding: 8px;
                width: 100%;
                border: 1px solid #B1B1B1;
                border-radius: 8px;
            }
            label{
              text-align: center;
              font-size: 1.6rem;
            }
            button[type='submit']{
                background-color: #F2790F;
                border: none;
                cursor: pointer;
            }
        }
    }
    .example-titles{
      padding: 0 !important;
      overflow: hidden;
      max-height: 320px;
      overflow-y: scroll;
      span{
        display: block;
        border: none;
        padding: 8px;
        font-size: 1.8rem;
        cursor: pointer;
        margin: 0;
        border-radius: 0;
      }
      span:hover{
        background-color: #B1B1B1;
      }
    }
  </style>