<template>
<div v-if="currentUser.data.role === 5">
    <h2>Dodaj Specjalistę</h2>
    <div class="spec-add">
        <input type="email" v-model="email" placeholder="adres e-mail" aria-label="podaj adres e-mail">
        <button @click="email ? sendConfirm = true : $store.commit('setIsAlert', 'Podaj adres e-mail specjalisty')">Wyślij zaproszenie</button>
        <div class="confirm-add" v-if="sendConfirm">
            <span>Jesteś pewny/a</span>
            <div>
                <button @click="sendConfirm = false">Nie</button>
                <button @click="sendInvitation()">Tak</button>
            </div>
        </div>
    </div>
</div>
<div v-else>
    <h2>Błąd wczytywania. Spróbuj ponownie</h2>
</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default{
    name: 'SpecialistAdd',
    data() {
        return {
            email: '',
            sendConfirm: false
        };
    },
    setup() {
        useMeta({ title: 'specjaliści' });
    },
    props: {},
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 5) {
            this.$router.push('/login');
        }
    },
    methods: {
        sendInvitation(){
            axios.post('/AddBusinessToFirm', {
                email: this.email
            }, authHeader()).then((res) => {
                if(res && res.status == 200){
                    this.$store.commit('setIsSuccess', 'Wysłano zaproszenie do specjalisty')
                    this.sendConfirm = false
                    this.$router.push('/firm-profile/specialists')
                }
            })
        }
    },
    components: {},
}
</script>

<style scoped lang='scss'>
.spec-add{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    input{
        padding: 10px 20px;
        border: 1px solid #D0D0D0;
        border-radius: 8px;
    }
    button{
        background-color: #F2790F;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        padding: 10px 20px;
        margin-bottom: 30px;
        font-size: 1.8rem;
    }
    .confirm-add{
        margin-top: 10px;
        div{  
            margin-top: 10px;
            button:first-child{
                background-color: #DC3545;
                margin-right: 10px;
            }
            button:last-child{
                background-color: #5EA01A;
                margin-left: 10px;
            }
        }
    }
}
</style>