<template>
  <button class="button-vue">{{this.text}}</button>
</template>

<script>
export default{
  name:'ButtonVue',
  data(){
    return{

    }
  },
  props:{
    bgColor: String,
    text: String,
    color: String
  }
}
</script>

<style scoped lang='scss'>
.button-vue{
    padding: 8px 15px;
    border: none;
    border-radius: 8px;
    background-color: v-bind('bgColor');
    color: v-bind('color');
    cursor: pointer;
}
</style>