<template>
    <h2>Edytuj usługę</h2>
    <div class="edit-service-wrapper" v-if="$route.query.serviceToEdit">        
        <form class="edit-service-wrapper__form" @submit="(e) => editService(e)">
            <input type="text" placeholder="nazwa" name="serviceName" id="serviceName" v-model="service.title" required maxlength="50" minlength="3">
            <textarea placeholder="krótki opis" v-model="service.short_description" name="short_description" required maxlength="350" minlength="20"></textarea>
            <textarea placeholder="treść" v-model="service.description" name="content" required maxlength="3000" minlength="80"></textarea>
            <input type="text" placeholder="adres" name="address" id="address" v-model="service.address" required maxlength="50" minlength="3">
            <select name="state" id="state" v-model="service.state">
                <option :value="state.value" :key="state.value" v-for="state in states">{{ state.name }}</option>
            </select>
            <input type="tel" placeholder="numer telefonu" name="phone" id="phone" v-model="service.phone" required pattern="[0-9]{9}">
            <input type="number" placeholder="cena" name="price" id="price" v-model="service.price" required>
            <input type="file" name="photo" id="photo" @change="(e) => onFileUpload(e)">
            <select name="state" id="state" v-model="service.employee">
                <option :value="employee.id" :key="employee.id" v-for="employee in employees">{{ employee.firstname }} {{ employee.lastname }}</option>
            </select>
            <button type="submit">Zatwierdź</button>
        </form>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'BizProfileEditServiceVue',
    setup () {
      useMeta({ title: 'Edytuj usługę' });
    },
    data(){
      return{
        states:[],
        service:{
            id: null,
            title: '',
            short_description: '',
            description: '',
            address: '',
            state: '',
            phone: '',
            price: '',
            photo: null,
            employee: ''
        },
        employees: []
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      if(this.$store.state.isSubscribed){
        this.getStates()
        this.getEmployees()
        this.getServiceData()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
        getServiceData(){
            if(this.$route.query.serviceToEdit){
                axios.get(`/Service/${this.$route.query.serviceToEdit}`).then(res => {
                    if(res.data){
                        this.service.id = res.data.id
                        this.service.title = res.data.name
                        this.service.short_description = res.data.short_description
                        this.service.description = res.data.description
                        this.service.address = res.data.address
                        this.service.state = res.data.state_id
                        this.service.phone = res.data.phone
                        this.service.price = res.data.price
                        this.service.employee = res.data.employee_id
                    }else{
                        this.$store.commit('setIsAlert', 'nie ma takiej usługi')
                    }
                })
            }
        },
        getStates(){
            axios.get('/State').then(res => {
                res.data.forEach((item) => {
                    const ob = {
                        name: item.name,
                        value: `${item.id}`
                    }
                    this.states.push(ob)
                })
            })
        },
        onFileUpload(event){
            this.service.photo = event.target.files[0]
        },
        editService(e){
            e.preventDefault()
                const newServiceData = {
                    id: this.service.id,
                    name: this.service.title,
                    short_description: this.service.short_description,
                    description: this.service.description,
                    address: this.service.address,
                    state_id: this.service.state,
                    phone: this.service.phone,
                    price: this.service.price,
                    employee_id: this.service.employee ? this.service.employee : null
                }
                if(this.service.photo){
                  newServiceData.photo = this.service.photo
                }
                axios.post('/Service', newServiceData, { 
                    headers:{
                      Authorization: `${authHeader().headers.Authorization}`,
                      ['Content-Type']: 'multipart/form-data'
                    }
                  }).then(res => {
                    if(String(res.status).charAt(0) == '2'){
                      this.$store.commit('setIsSuccess', 'Pomyślnie dodano usługę')
                      this.$router.push('/dla-biznesu/profile/services-provided/')
                    }
                  })
        },
        getEmployees(){
        axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 100}, authHeader())
            .then(res => {
                this.employees = res.data.items
            }).catch(() => {
              
            })
        },
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .edit-service-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 500px;
            gap: 20px;
            *{
                padding: 8px;
                width: 100%;
                border: 1px solid #B1B1B1;
                border-radius: 8px;
            }
            button[type='submit']{
                background-color: #F2790F;
                border: none;
                cursor: pointer;
            }
        }
    }
  </style>