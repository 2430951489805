<template>
  <div class="browser-bar">
    <div class="browser-bar__row browser-bar__row--first">
        <input type="text" placeholder="szukana usługa" id="serviceNameInput" v-model="name">
        <select name="serviceCategoryInput" id="serviceCategoryInput" v-model="state">
            <option value="">-- wybierz województwo --</option>
            <option :value="state.value" v-for="state in states" :key="state.value">{{ state.name }}</option>
        </select>
        <button @click="() => {
              if(name.length > 2 || name.length == 0){
                setSearchParams()
              }else{
                $store.commit('setIsAlert', 'wpisz minimum 3 litery')
              }
            }">wyszukaj</button>
    </div>
    <div class="browser-bar__row browser-bar__row--second">
        <span v-for="category in categories" :key="category.id" :style="{'text-decoration': selectedCategory == category.id ? 'underline' : 'none'}" @click="() => selectedCategory = category.id">{{ category.name }}</span>
    </div>
    <div class="browser-bar__row browser-bar__row--third">
        <!-- <ButtonVue :text="additionalOptions ? 'mniej opcji' : 'więcej opcji'" bg-color="#F2790F" color="#000" style="font-size: 1.7rem;" @click="() => additionalOptions = !additionalOptions"/> -->
        <ButtonVue text="resetuj" bg-color="#5EA01A" color="#000" style="font-size: 1.7rem;" @click="() => {
            name = ''
            selectedCategory = ''
            state = ''
            $router.push('/browser')
            hidePage = false
            itemsPage = 1
            itemsDisplayed = '9'
            getServices()
        }"/>
    </div>
  </div>
  <!-- <div class="additional-options" v-if="additionalOptions">
    <span>cena od:</span>
    <input type="number">
    <span> - do:</span>
    <input type="number">
    <select name="sortOption" id="sortOption" v-model="sortOption">
        <option value="1">trafność</option>
        <option value="2">cena: rosnąco</option>
        <option value="3">cena: malejąco</option>
        <option value="4">data: od najstarszego</option>
        <option value="5">data: od najświeższego</option>
    </select>
  </div> -->
  <div class="browser-pages" v-if="!hidePage">
    <select name="itemsDisplayed" id="itemsDisplayed" v-model="itemsDisplayed" @change="() => {
        itemsPage = 1
        filterServices()
        }">
        <!-- <option value="1">1</option> -->
        <option value="9">9</option>
        <option value="15">15</option>
        <option value="21">21</option>
        <option value="27">27</option>
        <option value="33">33</option>
        <option value="39">39</option>
    </select>
    <div class="browser-pages__pages-select" v-if="!hidePage">
        <button><img src="@/assets/message-arrow.png" @click="() => {
        if(itemsPage > 1){
            itemsPage -= 1
            filterServices()
        }
        }" alt=""></button>
        <span>{{ itemsPage }}/{{ Math.ceil(itemsCount/Number(itemsDisplayed)) }}</span>
        <button @click="() => {
        if(itemsPage < Math.ceil(itemsCount/Number(itemsDisplayed))){
            itemsPage += 1
            filterServices()
        }
        }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
    </div>
  </div>
  <div v-if="items">
    <ServiceModal/>
    <div class="items-grid" v-if="items.length > 0">
        <ServiceCardVue v-for="item in items" @openServiceModal="() => $router.push({path: '/browser', query: {serviceID: item.id}})" :price="item.price" :key="item.id" :img="item.photo_url" :name="item.name" :state="states.filter(state => state.value == item.state_id)[0].name" :ishighlighted="item.active" :category="categories.filter(category => category.id == item.user.category_id)[0].name" :is-advance="item.advanceAmount ? true : false"/>
    </div>
    <div v-else>
        <h3>Nie znaleziono usług</h3>
    </div>
  </div>
  <div class="items-grid" v-else>
    <h3>Nie znaleziono usług</h3>
  </div>
</template>

<script>
import ButtonVue from '@/components/Button.vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue'
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue'
export default{
  name:'BrowserVue',
  setup () {
    useMeta({title: 'Wyszkiwarka'})
  },
  data(){
    return{
        name: '',
        states: [],
        state: '',
        categories: [],
        selectedCategory : '',
        sortOption: '1',
        itemsDisplayed: '9',
        itemsPage: 1,
        itemsCount: '',
        additionalOptions: false,
        items: null,
        hidePage: false
    }
  },
  props:{

  },
  mounted(){
    this.setFilters()
    this.getStates()
    this.getCategories()
    if(this.$route.query.state || this.$route.query.category || this.$route.query.name){
        this.filterServices()
    }else{
        this.getServices()
    }
  },
  components:{ ButtonVue, ServiceCardVue, ServiceModal },
  methods:{
    setFilters(){
        this.$route.query.name ? this.name = this.$route.query.name : this.name = ''
        this.$route.query.state ? this.state = this.$route.query.state : this.state = ''
        this.$route.query.category ? this.selectedCategory = this.$route.query.category : this.selectedCategory = ''
    },
    getStates(){
        axios.get('/State').then(res => {
            res.data.forEach((item) => {
                const ob = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.states.push(ob)
            })
        })
    },
    getCategories(){
      axios.get('/Categories').then(res => {
          res.data.forEach((item) => {
              const ob  = {
                  name: item.name,
                  id: `${item.id}`
              }
              this.categories.push(ob)
          })
        })
    },
    getServices(){
        const params = {
            page: this.itemsPage,
            limit: this.itemsDisplayed
        }
        axios.post('/Services', params).then(res => {
            this.itemsCount = res.data.count
            this.items = res.data.items
        })
    },
    setSearchParams(){
        this.$router.push({path: '/browser', query: {name: this.name, state: this.state, category: this.selectedCategory}}).then(() => {
            this.filterServices()
        })
    },
    filterServices(){
        this.items = []
        const ob = {
            page: this.itemsPage,
            limit: this.itemsDisplayed
        }
        if(this.$route.query.category){
            ob.category_id = this.$route.query.category
        }
        if(this.$route.query.state){
            ob.state_id = this.$route.query.state
        }
        if(this.$route.query.name){
            ob.name = this.$route.query.name
        }
        axios.post('/Services', ob).then(res => {
            this.itemsCount = res.data.count
            this.items = res.data.items
        })
    }
  }
}
</script>

<style scoped lang='scss'>
.browser-bar{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
    &__row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        &--first{
            *{
                padding: 8px;
                border: 1px solid #b1b1b1;
                border-radius: 8px;
                background-color: #fff;
            }
            button{
                cursor: pointer;
            }
        }
        &--second{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            span{
                cursor: pointer;
            }
        }
        &--third{
            justify-content: flex-start;
        }
    }
}
.additional-options{
    display: flex;
    gap: 20px;
    margin: 20px 0;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #F2790F;
    padding-bottom: 20px;
    input, select{
        padding: 8px;
        border: 1px solid #b1b1b1;
        border-radius: 8px;
        background-color: #fff;
    }
}

.browser-pages{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    select{
        padding: 8px;
        border: 1px solid #b1b1b1;
        border-radius: 8px;
        background-color: #fff;
    }
    &__pages-select{
        button{
            background-color: #F2790F;
            border: none;
            border-radius: 50%;
            img{
                width: 15px;
                height: 15px;
            }
            img.rotate{
                transform: rotate(180deg);
            }
        }
    }
}

.items-grid{
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
}
</style>