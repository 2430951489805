<template>
    <div class="blog-card">
        <img :src="photo" alt="">
        <div class="blog-card__inner">
            <b>{{ title }}</b>
            <span>{{ author }}</span>
            <i style="font-size: 1.6rem;">{{ target }}</i>
            <ButtonVue color="#F2790F" bg-color="#fff" text="Więcej..." style="font-size: 1.5rem;" @click="() => $router.push({path: `/blog/entry/${id}`, query:{name: title.toLowerCase().split(' ').join('-')}})"/>
        </div>
        <span class="date-tag">{{ created_at.split('T')[0] }}</span>
    </div>
</template>

<script>
import ButtonVue from '../Button.vue';
export default{
  name:'BlogPostCardVue',
  data(){
    return{

    }
  },
  components: {
    ButtonVue
  },
  props:['url', 'title', 'author', 'photo', 'id', 'target', 'created_at']
}
</script>

<style scoped lang='scss'>
.blog-card{
    width: 290px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 15px;
    background-color: #f3f3f3;
    box-shadow: -1px 1px 7px 0px #c9c9c9;
    padding-bottom: 15px;
    justify-content: space-between;
    height: 410px;
    img{
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    &__inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      padding: 0 10px;
      span{
          font-size: 1.7rem;
      }
      b{
        text-align: center;
      }
    }
    .date-tag{
      align-self: flex-end;
      margin-right: 10px;
      font-size: 1.6rem;
    }
}
</style>