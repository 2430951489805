<template>
    <ModalVue :fields="[
                  {
                    name: 'firstname',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'imię',
                    req: true,
                    value: firstName,
                    label: 'imię'
                  },
                  {
                    name: 'lastname',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'nazwisko',
                    req: true,
                    value: lastName,
                    label: 'nazwisko'
                  },
                  {
                    name: 'phone',
                    type: 'tel',
                    tag: 'input',
                    placeholder: 'numer telefonu',
                    req: true,
                    htmlPattern: '[0-9]{9}',
                    value: phone,
                    label: 'numer telefonu'
                  },
                  // options
                  {
                    name: 'state',
                    tag: 'select',
                    options: states,
                    value: state,
                    label: 'województwo'
                  },
                  {
                    name: 'address',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'adres',
                    req: false,
                    value: address,
                    label: 'adres'
                  },
                  {
                    name: 'category',
                    tag: 'select',
                    options: categories,
                    value: category,
                    label: 'kategoria'
                  },
                ]" title="edytuj dane" button-text="zatwierdź" :isVisible="isModalVisible" @closeModal="() => {isModalVisible=false}" @sendData="(data) => editUser(data)"/>
    <ModalVue :fields="[
      {
        name:'photo',
        type:'file',
        tag: 'input',
        req: true
      }
    ]" title="prześlij zdjęcie" button-text="wyślij" :is-visible="isPhotoModalVisible" @closeModal="() => {isPhotoModalVisible=false}" @sendData="(data) => sendPhoto(data)"/>
    <div v-if="currentUser" class="priv-profile-user-data">
      <div class="user-data-wrapper" v-if="currentUser.data.role === 4">
        <h2>Twoje dane</h2>
        <div class="user-data-container">
          <div class="user-data-container__inner user-data-container__inner--small">
            <button @click="() => isModalVisible=true"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
            <button class="image-btn" @click="() => {
              if(!userPhotoUrl){
                isPhotoModalVisible=true
              }else if(userPhotoUrl){
                removePhoto()
              }
            }">
              <img class="image-btn__edit" src="@/assets/icons/pencil-square.svg" alt="edytuj" v-if="!userPhotoUrl">
              <img class="image-btn__delete" src="@/assets/icons/trash.svg" alt="edytuj" v-if="userPhotoUrl">
              <img class="image-btn__img" :src="userPhotoUrl ? userPhotoUrl : getImageUrl()" alt="">
            </button>
            <b>{{ firstName }} {{ lastName }}</b>
            <span>{{ email }}</span>
            <span style="font-size: 1.7rem;" :class="!address ? 'no-value' : ''">{{ address ? address : 'brak adresu' }}</span>
            <span style="font-size: 1.7rem;" :class="!phone ? 'no-value' : ''">{{ phone ? phone : 'brak numeru telefonu' }}</span>
            <span style="font-size: 1.7rem;" :class="!category ? 'no-value' : ''">{{ category ? category.name : 'brak kategorii' }}</span>
            <span style="font-size: 1.7rem;" :class="!state ? 'no-value' : ''">{{ state ? state.name : 'brak województwa' }}</span>
            <button class="open-business-card-btn" @click="() => $router.push(`/browse/user/${currentUser.data.user_id}`)">Twoja Wizytówka</button>
          </div>
          <div class="user-data-container__inner user-data-container__inner--big">
            <form @submit="(e) => sendDescriptions(e)">
              <label for="shortDescription">Krótki opis działalności</label>
              <textarea name="shortDescription" id="shortDescription" v-model="short_description" required maxlength="350" minlength="20"></textarea>
  
              <label for="description">Opis działalności</label>
              <textarea name="description" id="description" v-model="description" required maxlength="3000" minlength="80"></textarea>

              <button type="submit">Zatwierdź</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>Błąd wczytywania. Spróbuj ponownie</h2>
    </div>
  </template>
  
  <script>
  import ModalVue from '@/components/Modal.vue'
  import authHeader from '@/services/auth-header';
  import axios from 'axios';
  import { useMeta } from 'vue-meta'
  
  export default{
      name: 'EmployeeProfileUserData',
      data() {
          return {
              userPhotoUrl: '',
              userPhotoId: '',
              firstName: '',
              lastName: '',
              email: '',
              isModalVisible: false,
              isPhotoModalVisible: false,
              address: '',
              description: '',
              short_description: '',
              phone: '',
              category: null,
              state: null,
              states:[],
              categories: []
          };
      },
      setup() {
          useMeta({ title: 'twoje dane' });
      },
      props: {},
      computed: {
          currentUser() {
              return this.$store.state.auth.user;
          }
      },
      mounted() {
          if (!this.currentUser) {
              this.$router.push('/login');
          }
          else if (this.currentUser.data.role !== 4) {
              this.$router.push('/login');
          }
          this.getUserData()
      },
      methods: {
          getImageUrl() {
              return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
          },
          getUserData(){
            axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
              this.firstName = res.data.firstname
              this.lastName = res.data.lastname
              this.email = res.data.email
              this.address = res.data.address
              this.description = res.data.description
              this.short_description = res.data.short_description
              this.phone = res.data.phone
              this.category = res.data.category
              this.state = res.data.state
            })
  
            axios.get(`/UserImage/${this.currentUser.data.user_id}`).then(res => {
              if(res.data.length > 0){
                this.userPhotoUrl = res.data[0].photo_url
                this.userPhotoId = res.data[0].id
              }
            })

            axios.get('/State').then(res => {
              res.data.forEach((item) => {
                  const ob = {
                      name: item.name,
                      value: `${item.id}`
                  }
                  this.states.push(ob)
              })
            })

            axios.get('/Categories').then(res => {
              res.data.forEach((item) => {
                const ob  = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.categories.push(ob)
              })
            })
          },
          editUser(data){
            axios.post(`/UserProfile`, {
              id: this.currentUser.data.user_id,
              firstname: data.firstname,
              lastname: data.lastname,
              email: this.email,
              name: data.businessName,
              phone: data.phone,
              category_id: data.category,
              state_id: data.state,
              address: data.address,
              description: this.description,
              short_description: this.short_description,
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane')
                this.firstName = data.firstname
                this.lastName = data.lastname
                this.phone = data.phone
                this.category = this.categories.find(cat => cat.value == data.category)
                this.state = this.states.find(state => state.value == data.state)
                this.address = data.address
              }
            })
          },
          sendPhoto(data){
            axios.post('/UserImage', {
              user_id: this.currentUser.data.user_id,
              photo: data.photo
            },{ 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }
            ).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie dodano zdjęcie')
                this.userPhotoUrl = URL.createObjectURL(data.photo)
              }
            })
          },
          removePhoto(){
            axios.delete(`/UserImage/${this.userPhotoId}`, authHeader).then((res) => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zdjęcie')
                this.userPhotoUrl = ''
              }
            }).catch(() => {
              
              setTimeout(() => {
                location.reload()
              }, 500)
            })
          },
          sendDescriptions(e){
            e.preventDefault()
            axios.post(`/UserProfile`, {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.businessName,
              phone: this.phone,
              category_id: this.category,
              state_id: this.state,
              address: this.address,
              description: this.description,
              short_description: this.short_description,
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano opisy')
              }
            })
          }
      },
      components: { ModalVue },
  }
  </script>
  
  <style scoped lang='scss'>
  .user-data-container{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    flex-wrap: wrap;
    &__inner{
      background-color: #f2790f33;
      border-radius: 20px;
      &--small{
        width: calc(33% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        gap: 20px;
        padding-bottom: 35px;
        min-width: 256px;
        max-width: 300px;
        text-align: center;
        button{
          align-self: flex-end;
          border: none;
          background: none;
          cursor: pointer;
          img{
            width: 15px;
            height: 15px;
            border-radius: 0;
          }
        }
        // img{
        //   width: 120px;
        //   height: 120px;
        //   object-fit: cover;
        //   border-radius: 50%;
        // }
        .image-btn{
          width: 120px;
          height: 120px;
          align-self: center;
          cursor: pointer;
          position: relative;
          &__img{
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 50%;
          }
          &__edit{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
          &__delete{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
        }
        .image-btn:hover{
          .image-btn__edit{
            opacity: 1;
          }
          .image-btn__delete{
            opacity: 1;
          }
        }
      }
      &--big{
        width: calc(66% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 15px;
        min-width: 610px;
        max-width: 780px;
        form{
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          textarea{
            width: 100%;
            max-width: 500px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #B1B1B1;
            resize: vertical;
            font-size: 1.7rem;
            height: 100px;
          }
          label{
            margin-top: 20px;
          }
          button{
            width: 150px;
            padding: 5px 0;
            border: none;
            background-color: #f2790f;
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
    }
    .no-value{
      color: #B1B1B1;
    }
    .open-business-card-btn{
      background-color: #ffffff;
      border: 1px solid #f2790f;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
  }
  
  
  @media (max-width: 660px){
    .user-data-container{
      &__inner{
        &--big{
          width: 100%;
          min-width: 0;
        }
        &--small{
          width: 100%;
          min-width: 0;
        }
      }
    }
  }
  </style>