<template>
  <h2>Moje ogłoszenia</h2>
  <ButtonVue color="#000" text="Dodaj ogłoszenie" bgColor="#f2790f" @click="() => $router.push('/dla-biznesu/profile/announcements/add')"/>
  <div v-if="isEditModalVisible" class="announcements-edit">
    <h3>Edycja ogłoszenia</h3>
    <form class="add-announcement-wrapper__form" @submit.prevent="(e) => editAnnouncement(e)">
          <input type="text" placeholder="tytuł" v-model="editModalDetails.titleValue" name="title" required maxlength="50" minlength="3">
          <textarea placeholder="krótki opis" v-model="editModalDetails.shortDescriptionValue" name="short_description" required maxlength="350" minlength="20"></textarea>
          <textarea placeholder="treść" v-model="editModalDetails.contentValue" name="content" required maxlength="3000" minlength="80"></textarea>
          <input type="email" placeholder="adres e-mail do kontaktu" autocomplete="email" v-model="editModalDetails.contact" maxlength="50" required>
          <label>odbiorcy i kategoria</label>
          <select name="target" id="target" v-model="editModalDetails.target" required>
            <option>do wszystkich</option>
            <option>do specjalistów</option>
            <option>do właścicieli zwierząt</option>
          </select>
          <select name="category" id="category" v-model="editModalDetails.category" required>
            <option>sprzedaż</option>
            <option>współpraca</option>
            <option>wymiana</option>
            <option>inne</option>
          </select>
          <label for="announcementImage">zdjęcie wyróżniające</label>
          <input type="file" id="announcementImage" @change="(e) => onFileUpload(e)" accept="image/png, image/jpeg">
          <div>
            <button type="submit" style="margin-right: 10px;">Zatwierdź</button>
            <button type="button" @click="() => isEditModalVisible = false">Anuluj</button>
          </div>
      </form>
  </div>
  <div v-if="currentUser && announcements.length > 0" class="announcements-container">
      <AnnouncementCardVue :show-active="true" v-for="announcement in announcements" :key="announcement.id" :contact="announcement.contact" :active="announcement.active" :target="announcement.target" :category="announcement.category" :img="announcement.photo_url" :longContent="announcement.description" :id="announcement.id" :title="announcement.title" :content="announcement.short_description" :editable="true" :removable="true" :photo="announcement.photo" @deleteAnnouncement="(id) => deleteAnnouncement(id)" 
      @showEditModal="({titleValue, shortDescriptionValue, contentValue, id, target, contact, category}) => {
        isEditModalVisible=true
        editModalDetails.titleValue = titleValue,
        editModalDetails.shortDescriptionValue = shortDescriptionValue
        editModalDetails.contentValue = contentValue,
        editModalDetails.id = id
        editModalDetails.target = target
        editModalDetails.contact = contact
        editModalDetails.category = category
      }"/>
        <ButtonVue v-if="announcements.length > 10" color="#000" text="Załaduj więcej..." bgColor="#f2790f" @click="() => {
          announcementsLimit += 5
          getMyAnnouncements(announcementsLimit)
        }"/>
  </div>
  <div v-else>
    <h4>Brak ogłoszeń</h4>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import axios from 'axios';
import AnnouncementCardVue from '@/components/SpecialComponents/AnnouncementCard.vue';
import authHeader from '@/services/auth-header';
import ButtonVue from '@/components/Button.vue';

export default{
  name:'BizProfileAnnouncementsVue',
  setup () {
    useMeta({ title: 'Moje ogłoszenia' });
  },
  data(){
    return{
        announcements: [],
        announcementsLimit: 10,
        isEditModalVisible: false,
        editModalDetails:{
          titleValue: '',
          shortDescriptionValue: '',
          contentValue: '',
          id: '',
          contact:'',
          target: '',
          category: '',
          photo: null
        }
    }
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 2) {
      this.$router.push('/login');
    }
    this.getMyAnnouncements(this.announcementsLimit)
  },
  methods:{
      getMyAnnouncements(limit){
          const ob = {
              user_id: this.currentUser.data.user_id,
              page: 1,
              limit: limit
          }
          axios.post('/AnnouncementSearch', ob).then(res => {
              res.data.items.forEach(item => {
                  const freshAnnouncement = {
                      title: item.title,
                      description: item.description,
                      id: item.id,
                      photo_url: item.photo_url,
                      short_description: item.short_description,
                      target: item.target,
                      category: item.category,
                      contact: item.contact,
                      active: item.active
                  }
                  this.announcements.push(freshAnnouncement)
              })
          }).catch(() => {
                  
              })
      },
      onFileUpload (e) {
          this.editModalDetails.photo = e.target.files[0]
      },
      deleteAnnouncement(id){
          axios.delete(`/Announcement/${id}`, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                  this.$store.commit('setIsSuccess', 'Pomyślnie usunięto ogłoszenie')
                  this.announcements = this.announcements.filter(item => item.id !== id)
              }
          }).catch(() => {
                  
              })
      },
      editAnnouncement(){
            const ob = {
              id: this.editModalDetails.id,
              title: this.editModalDetails.titleValue,
              short_description: this.editModalDetails.shortDescriptionValue,
              description: this.editModalDetails.contentValue,
              active: 0,
              contact: this.editModalDetails.contact,
              target: this.editModalDetails.target,
              category: this.editModalDetails.category
            }
            if(this.editModalDetails.photo){
              ob.photo = this.editModalDetails.photo
            }
            axios.post('/Announcement', ob,{ 
              headers:{
                Authorization: `${authHeader().headers.Authorization}`,
                ['Content-Type']: 'multipart/form-data'
              }
            }).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zmieniono ogłoszenie')
                const editAnnouncement = this.announcements.filter(item => item.id == this.editModalDetails.id)[0]
                // editAnnouncement.photo_url = URL.createObjectURL(this.editModalDetails.photo)
                editAnnouncement.title = this.editModalDetails.titleValue
                editAnnouncement.description = this.editModalDetails.contentValue
                editAnnouncement.short_description = this.editModalDetails.shortDescriptionValue
                editAnnouncement.contact = this.editModalDetails.contact
                editAnnouncement.target = this.editModalDetails.target
                editAnnouncement.category = this.editModalDetails.category
                this.isEditModalVisible = false
              }
            }).catch(() => {
                  
              })
      }
  },
  components:{
      AnnouncementCardVue,
      ButtonVue
  }
}
</script>

<style scoped lang='scss'>
.announcements-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-top: 50px;
}
.announcements-edit{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  form{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 500px;
          gap: 10px;
          input{
              width: 100%;
              border: 1px solid #B1B1B1;
              padding: 8px;
              border-radius: 8px;
          }
          select{
            width: 100%;
            border: 1px solid #B1B1B1;
            padding: 8px;
            border-radius: 8px;
          }
          textarea{
              resize: vertical;
              width: 100%;
              border: 1px solid #B1B1B1;
              padding: 8px;
              border-radius: 8px;
          }
          label{
              margin-top: 20px;
          }
          button{
              margin-top: 20px;
              background-color: #f2790f;
              padding: 8px 15px;
              border-radius: 8px;
              border: none;
              cursor: pointer;
          }
      }
}
</style>