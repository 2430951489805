<template>
    <div class="subscribers" v-if="currentUser">
        <RecommendersModal :isVisible="isRecommendersModalVisible" @closeRecommendersModal="isRecommendersModalVisible = false" :recommenders="currentUsersRecommends"/>
        <h2>Polecający</h2>
        
        <div class="sub-plan-choose">
            <span>wyszukaj po:</span>
            <select name="filter" id="filter" v-model="searcher.choosedFilter">
                <option :value="item.value" v-for="item, index in searcher.filters" :key="index">{{ item.name }}</option>
            </select>
            <input type="text" v-model="searcher.searchPhrase" v-if="searcher.choosedFilter" :placeholder="searcher.filters.filter(item => item.value == searcher.choosedFilter)[0].name">
            <button @click="filterUsers">Szukaj</button>
            <img v-if="searcher.loading" class="loading" src="@/assets/icons/loading.gif" alt="">
            <button @click="() => {
                getRecommenders()
                searcher.searchPhrase = ''
            }">Resetuj filtry</button>
        </div> 
        <div class="users-displaying-options">
            <div>
                <span>pokaż na stronie:</span>                
                <select name="usersLimit" id="usersLimit" class="users-limit-select" v-model="usersList.limit" @change="() => {
                    getRecommenders()
                }">
                    <option value="1">1</option>
                    <option value="10" selected>10</option>
                    <option value="16">16</option>
                    <option value="22">22</option>
                    <option value="28">28</option>
                    <option value="34">34</option>
                </select>
            </div>
            <div>
                <span>strona: {{ usersList.page }}/{{ Math.ceil(usersList.usersCount/usersList.limit) }}</span>
                <div class="users-page-btns">
                    <button @click="() => {
                        if(usersList.page > 1){
                            usersList.page -= 1
                            getRecommenders()
                        }
                    }"><img src="@/assets/icons/arrow-left-short.svg" alt=""></button>
                    <button @click="() => {
                        if(usersList.page < Math.ceil(usersList.usersCount/usersList.limit)){
                            usersList.page += 1
                            getRecommenders()
                        }
                    }"><img src="@/assets/icons/arrow-right-short.svg" alt=""></button>
                </div>
            </div>
        </div>
        <div class="subscribers__table" v-if="usersList.usersList">
            <table v-if="usersList.usersList.length > 0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>adres e-mail</th>
                        <th>ilość poleceń</th>
                        <th>polecenia</th>
                    </tr>  
                </thead>
                <tbody>
                    <tr v-for="user in usersList.usersList" :key="user.id">
                        <td>{{ user.id }}</td>
                        <td><RouterLink :to="`/browse/user/${user.id}`">{{ user.email }}</RouterLink></td>
                        <td>{{user.recommends.length}}</td>
                        <td>
                            <button @click="() => {
                                isRecommendersModalVisible = true;
                                currentUsersRecommends = user.recommends
                            }">pokaż</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2 v-else>Nie znaleziono polecających</h2>
        </div>
    </div>
  </template>
  
  <script>
  import authHeader from '@/services/auth-header';
import axios from 'axios';
import RecommendersModal from '@/components/SpecialComponents/RecommendersModal.vue'
  export default{
    name:"RecommendersList",
    data(){
        return{
            isRecommendersModalVisible: false,
            currentUsersRecommends: null,
            usersList: {
                usersList: null,
                page: 1,
                limit: 10,
                usersCount: null
            },
            searcher: {
                searchPhrase: '',
                choosedFilter: '',
                loading: false,
                filters: [
                    {
                        name: 'adres e-mail',
                        value: 'email'
                    },
                    {
                        name: 'id',
                        value: 'id'
                    },
                    {
                        name: 'imię i nazwisko',
                        value: 'name'
                    }
                ]
            },
        }
    },
    mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
      this.getRecommenders()
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    methods:{
        getRecommenders(){
            axios.post('/UsersWithRecommends', {
                page: this.usersList.page,
                limit: this.usersList.limit
            }, authHeader()).then((res) => {
                this.usersList.usersCount = res.data.count
                this.usersList.usersList = res.data.items
            })
        },
        filterUsers() {
            this.searcher.loading = true
            const ob = {
                page: 1,
                limit: this.usersList.usersCount,
            };
            axios.post(`/UsersWithRecommends`, ob, authHeader())
                .then(res => {
                if (this.searcher.searchPhrase !== '') {
                    switch (this.searcher.choosedFilter) {
                        case 'email':
                            this.usersList.usersList = res.data.items.filter(user => user.email.toLowerCase().includes(this.searcher.searchPhrase.toLowerCase()));
                            break;
                        case 'id':
                            this.usersList.usersList = res.data.items.filter(user => user.id == this.searcher.searchPhrase);
                            break;
                        case 'name':
                            this.usersList.usersList = res.data.items.filter(user => `${user.firstname} ${user.lastname}`.toLowerCase().includes(this.searcher.searchPhrase.toLowerCase()));
                            break;
                    }
                    this.searcher.loading = false
                }
            }).catch(() => {
                
            });
        }
    },
    components:{ RecommendersModal }
  }
  </script>
  
  <style scoped lang="scss">
  .sub-plan-choose{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    select{
        padding: 10px 20px;
        border-radius: 8px;
        border: 1px solid #adadad;
    }
    button{
        background-color: #F2790F;
        padding: 8px 30px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
    input{
        padding: 8px;
        width: 260px;
        border-radius: 10px;
        border: 1px solid rgb(133, 133, 133);
        box-sizing: border-box;
    }
  }
    .subscribers{
        &__table{
            overflow: scroll;
            table, tr, td, th{
                border: 1px solid #000;
            }
            table{
                border-collapse: collapse;
            }
            td, th{
                padding: 5px 10px;
            }
            td{
                button{
                    margin: 7px 8px;
                    background-color: #F27A07;
                    border: none;
                    border-radius: 8px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
                button.green{
                    background-color: #5EA01A;
                }
                button.red{
                    background-color: #DC3545;
                }
                button.blue{
                    background-color: #007BFF;
                }
            }
        }
        &__table::-webkit-scrollbar{
            width: 3px;
        }
        &__table::-webkit-scrollbar-track {
        background: #fff; 
        }
        &__table::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 4px;
        }
    }
    .red-text{
        color: #DC3545
    }
    .green-text{
        color: #5EA01A;
    }
    .users-displaying-options{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
    .users-displaying-options > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
    .users-limit-select{
    padding: 5px;
    width: 50px;
    border-radius: 8px;
}
.users-page-btns > button{
    background-color: #F2790F;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 10px;
    cursor: pointer;
}
.loading{
    width: 40px;
}
  </style>