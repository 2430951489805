<template>
  <div class="opinion-container">
    <div class="opinion-container__top-icons">
        <img src="@/assets/icons/quote.svg" alt="">
        <div>
            <img src="@/assets/icons/star-fill.svg" :alt="rate" v-for="x in Number(rate)" :key="x">
        </div>
    </div>
    <div class="opinion-container__content">
        <b>{{ title }}</b>
        <span>{{ content }}</span>
    </div>
  </div>
</template>

<script>
export default{
  name:'OpinionCardVue',
  data(){
    return{

    }
  },
  props:['rate', 'title', 'content'],
}
</script>

<style scoped lang='scss'>
.opinion-container{
    box-shadow: -1px 1px 7px 0px #c9c9c9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 15px;
    padding: 10px;
    max-width: 500px;
    gap: 10px;
    &__top-icons{
        display: flex;
        justify-content: space-between;
        width: 100%;
        img{
            width: 30px;
        }
        div{
            img{
                width: 20px;
            }
        }
    }
    &__content{
        padding-left: 6px;
        font-size: 1.6rem;
        b{
            font-size: 2rem;
            display: block;
            margin-bottom: 8px;
        }
    }
}
</style>