<template>
  <div class="partner-container">
    <a v-for="partner, index in partners" :href="partner.url" :key="index">
      <img :src="partner.src" :alt="partner.alt">
    </a>
  </div>
</template>

<script>
import axios from 'axios';

export default{
  name:'PartnersVue',
  data(){
    return{
      partners: []
    }
  },
  props:{

  },
  mounted(){
    this.getPartners()
  },
  methods:{
    getPartners(){
        const ob = {
            page: 1,
            limit: 10
        }
        axios.post(`/PartnerSearch`, ob)
            .then(res => {
              res.data.items.forEach((item) => {
                const ob = {
                  src: item.logo_url,
                  alt: item.name,
                  url: item.url
                }
                this.partners.push(ob)
              })
            })
    }
  }
}
</script>

<style scoped lang='scss'>
.partner-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 70px;
  img{
    width: 250px;
    object-fit: contain;
  }
}
</style>