<template>
    <div v-if="currentUser">
      <div class="profile-container" v-if="currentUser.data.role === 4">
        <h2>Wybierz zakładkę</h2>
      </div>
    </div>
    <div v-else>
      <h2>Błąd wczytywania. Spróbuj ponownie</h2>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  export default{
    name:'EmployeeProfile',
    setup () {
      useMeta({title: 'profil pracownika'})
    },
    data(){
      return{
  
      }
    },
    props:{
  
    },
    computed: {
      currentUser () {
        return this.$store.state.auth.user
      }
    },
    methods:{
    },  
    mounted(){
      if(!this.currentUser){
        this.$router.push('/login')
      }else if(this.currentUser.data.role !== 4){
        this.$router.push('/login')
      }else if(this.currentUser){
        this.$router.push('/dla-biznesu/employee-profile/user-data')
      }
    }
  }
  </script>
  
  <style scoped lang='scss'>
  
  </style>