<template>
  <BizHeroVue/>
  <section class="offer">
    <h2>Co oferujemy?</h2>
    <OfferVue/>
  </section>
  <section class="opinions">
    <h2>Co o nas mówią</h2>
    <div class="opinions__opinions-grid" v-if="reviews">
      <OpinionCardVue v-for="review in reviews" :key="review.id" :rate="review.content.split('|')[1]" :title="review.content.split('|')[0]" :content="review.content.split('|')[2]"/>
    </div>
  </section>
  <!-- <section class="price-list">
    <h2>Cennik</h2>
    <span>Sprawdź ceny i dobierz odpowiedni pakiet dla siebie.</span>
    <PriceListVue/>
    <ButtonVue color="#FFF" text="Załóż konto" @click="() => $router.push('/register')" bg-color="#F2790F"/>
  </section> -->
  <section class="contact-form">
    <h2>Skontaktuj się z nami</h2>
    <span>W razie jakichkolwiek pytań, jesteśmy do twojej dyspozycji!</span>
    <ContactFormVue/>
  </section>
</template>

<script>
import { useMeta } from 'vue-meta';
import BizHeroVue from '@/components/HomeComponents/biz/BizHero.vue';
import OfferVue from '@/components/HomeComponents/biz/Offer.vue';
import OpinionCardVue from '@/components/SpecialComponents/OpinionCard.vue';
// import PriceListVue from '@/components/HomeComponents/biz/PriceList.vue';
import ContactFormVue from '@/components/HomeComponents/biz/ContactForm.vue';
import axios from 'axios';
// import ButtonVue from '@/components/Button.vue';

export default {
  name: 'BusinessHomePage',
  setup () {
    useMeta({
      title: 'Dla biznesu',
      meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
    })
  },
  data(){
    return{
      reviews: null
    }
  },
  components: {
    BizHeroVue,
    OfferVue,
    OpinionCardVue,
    // PriceListVue,
    ContactFormVue,
    // ButtonVue
  },
  mounted(){
    this.getReviews()
  },
  methods:{
    getReviews(){
        axios.get('/CmsAll').then(res => {
            this.reviews = res.data.filter(item => item.title.includes('Opinia'))
        }).catch(() => {
            
        })
    },
  }
}
</script>

<style lang="scss">
  .offer{
    margin-top: 50px;
    h2{
      text-align: center;
    }
  }
  .opinions{
  margin-top: 90px;
  h2{
    text-align: center;
  }
  &__opinions-grid{
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
  }
}
.price-list{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 30px;
  h2, span{
    text-align: center;
    margin: 0;
  }
  button{
    align-self: center;
  }
}

.contact-form{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  h2{
    margin: 0;
  }
}
</style>
