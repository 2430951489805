<template>
  <div class="offer-container">
    <div class="offer-container__inner">
        <div class="offer-container__inner__floor offer-container__inner__floor--left">
            <b>System kalendarza</b>
            <span>Dzięki tej funkcjonalności będziesz mógł/mogła prowadzić swój własny kalendarz, przez który twoi klienci będą mogli umawiać się na wizyty.</span>
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--left">
            <img style="width: 230px;" src="@/assets/money.png" alt="prowizja">
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--left">
            <b>Powiadomienia</b>
            <span>Informacje mailowe o zarezerwowanych i odwołanych wizytach.</span>
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--left">
            <img src="@/assets/logo-example.png" alt="twoje logo">
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--left">
            <b>Przejrzysty interface</b>
            <span>Panel zarządzania kontem zaprojektowaliśmy intuicyjnie, tak żeby można było szybko wykonać niezbędne działania.</span>
        </div>
    </div>
    <div class="offer-container__center-inner">
        <div class="offer-container__center-inner__line"></div>
    </div>
    <div class="offer-container__inner">
        <div class="offer-container__inner__floor offer-container__inner__floor--right">
            <img src="@/assets/calendar.png" alt="kalendarz">
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--right">
            <b>Automatyczna płatność za zarezerwowaną wizytę</b>
            <span>w wysokości 50% lub 100% ceny wizyty (wedle potrzeby)</span>
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--right">
            <img style="width: 230px;" src="@/assets/notifications.png" alt="powiadomienia">
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--right">
            <b>Własna wizytówka</b>
            <span>Jako usługodawca możesz stworzyć swoją własną wizytówkę z opisem, galerią, danymi kontaktowymi i czatem.</span>
        </div>
        <div class="offer-container__inner__floor offer-container__inner__floor--right">
            <img src="@/assets/layout.png" alt="interfejs">
        </div>
    </div>
  </div>
  <div class="offer-container-mobile">
    <div class="offer-container-mobile__inner">
        <b>System kalendarza</b>
        <img src="@/assets/calendar.png" alt="kalendarz">
        <span>Dzięki tej funkcjonalności będziesz mógł/mogła prowadzić swój własny kalendarz, przez który twoi klienci będą mogli umawiać się na wizyty.</span>
    </div>
    <div class="offer-container-mobile__center-inner">
        <div class="offer-container__center-inner__line"></div>
    </div>
    <div class="offer-container-mobile__inner">
        <b>Przejrzysty interface</b>
        <img src="@/assets/layout.png" alt="interface">
        <span>Panel zarządzania kontem zaprojektowaliśmy intuicyjnie, tak żeby można było szybko wykonać niezbędne działania.</span>
    </div>
    <div class="offer-container-mobile__center-inner">
        <div class="offer-container__center-inner__line"></div>
    </div>
    <div class="offer-container-mobile__inner">
        <b>Własna wizytówka</b>
        <img src="@/assets/logo-example.png" alt="twoje logo">
        <span>Jako usługodawca możesz stworzyć swoją własną wizytówkę z opisem, galerią, danymi kontaktowymi i czatem.</span>
    </div>
    <div class="offer-container-mobile__center-inner">
        <div class="offer-container__center-inner__line"></div>
    </div>
    <div class="offer-container-mobile__inner">
        <b>Automatyczna płatność za zarezerwowaną wizytę</b>
        <img style="width: 230px;" src="@/assets/money.png" alt="prowizja">
        <span>w wysokości 50% lub 100% ceny wizyty (wedle potrzeby)</span>
    </div>
    <div class="offer-container-mobile__center-inner">
        <div class="offer-container__center-inner__line"></div>
    </div>
    <div class="offer-container-mobile__inner">
        <b>Powiadomienia</b>
        <img style="width: 230px;" src="@/assets/notifications.png" alt="powiadomienia">
        <span>Informacje mailowe o zarezerwowanych i odwołanych wizytach.</span>
    </div>
  </div>
</template>

<script>
export default{
  name:'OfferVue',
  data(){
    return{

    }
  }
}
</script>

<style scoped lang='scss'>
.offer-container{
    display: flex;
    width: 100%;
    &__inner{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__floor{
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &--left{
                align-self: flex-end;
                text-align: right;
            }
            &--right{
                align-self: flex-start;
            }
        }
    }
    &__center-inner{
        margin: 30px;
        &__line{
            width: 1px;
            height: 100%;
            border-right: 2px dashed #000;
        }
    }
}

.offer-container-mobile{
    display: none;
}

@media (max-width: 720px){
    .offer-container{
        display: none;
    }
    .offer-container-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        &__inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            b{
                font-size: 2.5rem;
            }
            span{
                max-width: 400px;
                text-align: center;
            }
            img{
                width: 90%;
            }
        }
        &__center-inner{
            margin: 30px 0;
            width: 90%;
            height: 1px;
            border-bottom: 2px dashed #000;
        }
    }
}
</style>