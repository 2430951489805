<template>
  <select name="dropdown" :style="{border: border ? `1px solid #b1b1b1` : `none`}" :id="this.id" @change="setChecked" v-model="currentOption" class="dropdown">
    <option value="placeholder">-- {{ placeholder }} --</option>
    <option :value="item.value" :key="index" v-for="item, index in this.items">{{ item.name }}</option>
  </select>
</template>

<script>
export default{
  name:'DropdownVue',
  data(){
    return{
      currentOption: 'placeholder'
    }
  },
  props:['items', 'placeholder', 'id', 'border'],
  methods: {
    setChecked(){
        this.$emit('setChecked', this.currentOption)
    }
  }
}
</script>

<style scoped lang='scss'>
.dropdown{
    border-radius: 8px;
    padding: 6px 15px;
    border: none;
}
</style>