<template>
    <div class="payment-confirm-wrapper">        
        <h2>Potwierdzanie płatności</h2>
        <img src="@/assets/icons/loading.gif" alt="">
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:'PaymentConfirm',
    setup () {
      useMeta({ title: 'Potwierdzanie płatności' });
    },
    data(){
      return{

      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      this.confirmPayment()
    },
    methods:{
        confirmPayment(){
            if(this.$route.query.paymentStatus){
                axios.post('/PaymentConfirmation', {
                    status: this.$route.query.paymentStatus === 'CONFIRMED' ? true : false
                }, authHeader()).then(() => {
                    this.$store.commit('setIsSuccess', 'Pomyślnie wykupiono plan. Zaloguj się')
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/');
                }).catch(() => {
                    this.$router.push('/dla-biznesu/profile/subscriptions')
                })
            }else{
                this.$router.push('/dla-biznesu/profile/user-data')
                
            }
        }
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .payment-confirm-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 70px;
        }
    }
  </style>