<template>
    <div class="password-change" v-if="currentUser">
        <h2>zmień hasło</h2>
        <form @submit.prevent="changePass">
            <input type="text" name="oldpassword" id="oldpassword" placeholder="stare hasło" v-model="passData.oldpassword" required>
            <input type="text" name="newpassword" id="newpassword" placeholder="nowe hasło" v-model="passData.newpassword" required minlength="8">
            <input type="text" name="repeatpassword" id="repeatpassword" placeholder="powtórz hasło" v-model="passData.repeatpassword" required minlength="8">
            <button>zmień</button>
        </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import authHeader from '@/services/auth-header';
  export default{
    name:"ChangePassword",
    data(){
        return{
            passData:{
                oldpassword: '',
                newpassword: '',
                repeatpassword: ''
            }
        }
    },
    mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    methods:{
        changePass(){
            axios.post('/ChangePassword', this.passData, authHeader()).then(res => {
              if(String(res.status).charAt(0) === '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano hasło')
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }
            }).catch(() => {
              this.$store.commit('setIsAlert', 'złe bieżące hasło')
            })
          }
    }  
  }
  </script>
  
  <style scoped lang="scss">
    .password-change{
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            input{
                padding: 8px;
                border-radius: 8px;
                border: 1px solid #b1b1b1;
            }
            button{
                background-color: #F2790F;
                border: none;
                padding: 8px;
                border-radius: 8px;
            }
        }
    }
  </style>