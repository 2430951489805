<template>
    <div class="rec-trainns-cont" id="recTrainsSect">
        <img src="@/assets/baner.png" alt="" class="rec-trainns-bg">
        <div class="rec-trainns-content">
            <h3>Polecane szkolenia</h3>
            <div class="rec-trainns-spec">
                <img src="@/assets/hairstyling.png" alt="">
                <span>Groomer</span>
            </div>
            <div class="rec-trainns-spec">
                <img src="@/assets/pawprint.png" alt="">
                <span>Behawiorysta</span>
            </div>
            <p class="rec-trainns-paragraph">
                Skorzystaj z oferty szkoleń i warsztatów on-line. Wartościowe treści dla usługodawców i właścicieli zwierząt.
                <a href="#" class="link-show">Sprawdź ofertę</a>
            </p>
            <div class="cta-btn-hide-container">
                <a href="http://warsztaty.m2vet.pl" target="_blank"><ButtonVue style="padding-left: 0;" bgColor="#fff" color="#f07810" text="Sprawdź ofertę"/></a>
            </div>
        </div>
    </div>
    <div class="rec-trainns-cont-mobile">
        <h3>Polecane szkolenia</h3>
        <div class="rec-trainns-spec">
            <img src="@/assets/hairstyling.png" alt="">
            <span>Groomer</span>
        </div>
        <div class="rec-trainns-spec">
            <img src="@/assets/pawprint.png" alt="">
            <span>Behawiorysta</span>
        </div>
        <p class="rec-trainns-paragraph-mobile">Skorzystaj z oferty szkoleń i warsztatów on-line. Wartościowe treści dla usługodawców i właścicieli zwierząt.</p>
        <a href="http://warsztaty.m2vet.pl" target="_blank"><ButtonVue bgColor="#fff" color="#f07810" text="Sprawdź ofertę"/></a>
    </div>
</template>

<script>
import ButtonVue from '@/components/Button.vue';

export default{
    name:"TrainingsVue",
    data(){
        return{

        }
    },
    props:{

    },
    components:{
        ButtonVue
    },
    mounted(){

    },
    methods:{

    }  
}
</script>

<style scoped>
    .rec-trainns-cont{
        margin: 0 auto;
        width: 900px;
        position: relative;
    }
    .rec-trainns-spec{
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    .rec-trainns-spec > img{
        width: 20px;
    }
    .rec-trainns-paragraph{
        max-width: 400px;
    }
    .rec-trainns-bg{
        width: 100%;
    }
    .rec-trainns-content{
        position: absolute;
        top: 40px;
        left: 40px;
        font-size: 1.7rem;
    }
    .rec-trainns-cont-mobile{
        display: none;
    }
    .link-show{
        display: none;
    }
    @media(max-width: 950px){
        .rec-trainns-cont{
            width: 700px;
        }
        .rec-trainns-content{
            font-size: 1.5rem;
        }
    }
    @media(max-width: 720px){
        .rec-trainns-cont{
            width: 500px;
        }
        .rec-trainns-content{
            width: 300px;
            top: 5px;
        }
        .rec-trainns-spec{
            margin: 5px 0;
        }
        .cta-btn-hide-container{
            display: none;
        }   
        .link-show{
            display: inline;
        }
    }
    @media(max-width: 620px){
        .rec-trainns-cont{
            display: none;
        }
        .rec-trainns-cont > img{
            display: none;
        }
        .rec-trainns-cont-mobile{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-image: url('@/assets/baner2.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 90%;
            margin: 0 auto;
            font-size: 1.5rem;
            padding: 10px;
            margin-top: 30px;
        }
        .rec-trainns-paragraph-mobile{
            max-width: 350px;
            text-align: center;
        }
    }
    @media(max-width: 460px){
        .rec-trainns-cont-mobile{
            background-image: none;
            background-color: #ff770075;
            width:80%;
            border-radius: 30px;
            margin-top: 30px;
        }
    }
</style>