<template>
    <ModalVue :fields="[
                  {
                    name: 'name',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'nazwa',
                    req: true,
                    value: user.name
                  },
                  {
                    name: 'phone',
                    type: 'tel',
                    tag: 'input',
                    placeholder: 'numer telefonu',
                    req: true,
                    htmlPattern: '[0-9]{9}',
                    value: user.phone,
                    label: 'numer telefonu'
                  },
                  // options
                  {
                    name: 'state_id',
                    tag: 'select',
                    options: states,
                    value: user.state_id,
                    label: 'województwo'
                  },
                  {
                    name: 'address',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'adres',
                    req: false,
                    value: user.address,
                    label: 'adres'
                  },
                  {
                    name: 'category_id',
                    tag: 'select',
                    options: categories,
                    value: user.category_id,
                    label: 'kategoria'
                  },
                ]" title="edytuj dane" button-text="zatwierdź" :isVisible="isModalVisible" @closeModal="() => {isModalVisible=false}" @sendData="(data) => editUser(data)"/>
      <ModalVue :fields="[
      {
        name: 'oldpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'obecne hasło'
      },
      {
        name: 'newpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'nowe hasło',
        minlength: 8
      },
      {
        name: 'repeatnewpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'powtórz nowe hasło',
        minlength: 8
      }
    ]" title="zmnień hasło" :is-visible="isPassModalVisible" button-text="zmień" @closeModal="() => {isPassModalVisible=false}" @sendData="(data) => changePass(data)"/>
    <ModalVue :fields="[
      {
        name:'photo',
        type:'file',
        tag: 'input',
        req: true
      }
    ]" title="prześlij zdjęcie" button-text="wyślij" :is-visible="isPhotoModalVisible" @closeModal="() => {isPhotoModalVisible=false}" @sendData="(data) => sendPhoto(data)"/>
    <div v-if="currentUser" class="priv-profile-user-data">
      <div class="user-data-wrapper" v-if="currentUser.data.role === 5">
        <h2>Twoje dane</h2>
        <div class="user-data-container">
          <div class="user-data-container__inner user-data-container__inner--small">
            <button @click="() => isModalVisible=true"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
            <button class="image-btn" @click="() => {
              if(!userPhotoUrl){
                isPhotoModalVisible=true
              }else if(userPhotoUrl){
                removePhoto()
              }
            }">
              <img class="image-btn__edit" src="@/assets/icons/pencil-square.svg" alt="edytuj" v-if="!userPhotoUrl">
              <img class="image-btn__delete" src="@/assets/icons/trash.svg" alt="edytuj" v-if="userPhotoUrl">
              <img class="image-btn__img" :src="userPhotoUrl ? userPhotoUrl : getImageUrl()" alt="">
            </button>
            <b>{{ user.name }}</b>
            <span>{{ user.email }}</span>
            <span style="font-size: 1.7rem;" :class="!user.address ? 'no-value' : ''">{{ user.address ? user.address : 'brak adresu' }}</span>
            <span style="font-size: 1.7rem;" :class="!user.phone ? 'no-value' : ''">{{ user.phone ? user.phone : 'brak numeru telefonu' }}</span>
            <span style="font-size: 1.7rem;" :class="!user.category_id ? 'no-value' : ''">{{ user.category_id ? `${categories.find(e => user.category_id == e.id) ? categories.find(e => user.category_id == e.id).name : 'brak kategorii'}` : 'brak kategorii' }}</span>
            <span style="font-size: 1.7rem;" :class="!user.state_id ? 'no-value' : ''">{{ user.state_id ? `${states.find(e => user.state_id == e.id) ? states.find(e => user.state_id == e.id).name : 'brak województwa'}` : 'brak województwa' }}</span>
            <button class="open-business-card-btn" @click="() => $router.push(`/browse/user/${currentUser.data.user_id}`)">Twoja Wizytówka</button>
            <button class="change-pass-btn" @click="() => isPassModalVisible = true">Zmień hasło</button>
            <button class="delete-account-btn" @click="() => confirmDelete = true">Usuń konto</button>
            <div class="delete-account-confirm" v-if="confirmDelete">
              <span>czy na pewno?</span>
              <span><span @click="deleteAccount">Tak</span> / <span @click="() => confirmDelete = false">Nie</span></span>
            </div>
          </div>
          <div class="user-data-container__inner user-data-container__inner--big">
            <form class="account-change-form" @submit.prevent="changeAccountType" @reset.prevent="accountChangeConfirm = false">
              <b>Jesteś specjalistą i świadczysz usługi?</b>
              <span>Zmień typ konta</span>
              <p>Jeśli jesteś specjalistą i świadczysz usługi, zmień typ konta, żeby mieć taką możliwość. Aktualne konto skierowane jest do klinik/placówek, które zatrudniają specjalistów. Konto specjalisty jest płatne według <RouterLink to="/dla-biznesu">cennika</RouterLink></p>
              <button type="button" v-if="!accountChangeConfirm" @click="accountChangeConfirm = true">Jestem specjalistą</button>
              <span v-if="accountChangeConfirm">Jesteś Pewny/a</span>
              <div v-if="accountChangeConfirm">
                <button type="submit">tak</button>
                <button type="reset">nie</button>
              </div>
            </form>
            <form @submit.prevent="editUser()">
              <label for="shortDescription">Krótki opis działalności</label>
              <textarea name="shortDescription" id="shortDescription" v-model="user.short_description" required maxlength="350" minlength="20"></textarea>
  
              <label for="description">Opis działalności</label>
              <textarea name="description" id="description" v-model="user.description" required maxlength="3000" minlength="80"></textarea>

              <button type="submit">Zatwierdź</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>Błąd wczytywania. Spróbuj ponownie</h2>
    </div>
  </template>
  
  <script>
  import ModalVue from '@/components/Modal.vue'
  import authHeader from '@/services/auth-header';
  import axios from 'axios';
  import { useMeta } from 'vue-meta'
  
  export default{
      name: 'FirmUserData',
      data() {
          return {
              userPhotoUrl: '',
              userPhotoId: '',
              isModalVisible: false,
              isPhotoModalVisible: false,
              states:[],
              categories: [],
              accountChangeConfirm: false,
              isPassModalVisible: false,
              confirmDelete: false,
              user:{
                address: '',
                description: '',
                short_description: '',
                phone: '',
                name: '',
                category_id: null,
                state_id: null,
                email: '',
                nip: '',
                krs: '',
                regon: '',
                fv: []
              }
          };
      },
      setup() {
          useMeta({ title: 'twoje dane' });
      },
      props: {},
      computed: {
          currentUser() {
              return this.$store.state.auth.user;
          }
      },
      mounted() {
          if (!this.currentUser) {
              this.$router.push('/login');
          }
          else if (this.currentUser.data.role !== 5) {
              this.$router.push('/login');
          }
          this.getUserData()
      },
      methods: {
          getImageUrl() {
              return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
          },
          getUserData(){
            axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
              this.user = res.data
            })
  
            axios.get(`/UserImage/${this.currentUser.data.user_id}`).then(res => {
              if(res.data.length > 0){
                this.userPhotoUrl = res.data[0].photo_url
                this.userPhotoId = res.data[0].id
              }
            })

            axios.get('/State').then(res => {
              res.data.forEach(e => {
                e.value = e.id
              })
              this.states = res.data
            })

            axios.get('/Categories').then(res => {
              res.data.forEach(e => {
                e.value = e.id
              })
              this.categories = res.data
            })
          },
          editUser(data){
            this.user.fv = []
            Object.assign(this.user, data)
            this.user.id = this.currentUser.data.user_id
            axios.post(`/UserProfile`, this.user, authHeader()).then(() => {
              this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane')
            })
          },
          sendPhoto(data){
            axios.post('/UserImage', {
              user_id: this.currentUser.data.user_id,
              photo: data.photo
            },{ 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }
            ).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie dodano zdjęcie')
                this.userPhotoUrl = URL.createObjectURL(data.photo)
              }
            })
          },
          removePhoto(){
            axios.delete(`/UserImage/${this.userPhotoId}`, authHeader).then((res) => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zdjęcie')
                this.userPhotoUrl = ''
              }
            }).catch(() => {
              
              setTimeout(() => {
                location.reload()
              }, 500)
            })
          },
          changeAccountType(){
            axios.get(`/UserChangeRoleId/2`, authHeader()).then(() => {
              this.$store.dispatch('auth/logout');
              this.$router.push('/login')
            })
          },
          changePass(data){
            axios.post('/ChangePassword', {
              oldpassword: data.oldpassword,
              newpassword: data.newpassword,
              repeatpassword: data.repeatnewpassword
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) === '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano hasło')
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }
            })
        },
        deleteAccount(){
            axios.delete(`/User/${this.currentUser.data.user_id}`, authHeader()).then(() => {
              this.$router.push('/')
              localStorage.removeItem('userm2VET');
              window.location.reload()
            })
          }
      },
      components: { ModalVue },
  }
  </script>
  
  <style scoped lang='scss'>
  .user-data-container{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    flex-wrap: wrap;
    &__inner{
      background-color: #f2790f33;
      border-radius: 20px;
      &--small{
        width: calc(33% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        gap: 20px;
        padding-bottom: 35px;
        min-width: 256px;
        max-width: 300px;
        text-align: center;
        button{
          align-self: flex-end;
          border: none;
          background: none;
          cursor: pointer;
          img{
            width: 15px;
            height: 15px;
            border-radius: 0;
          }
        }
        // img{
        //   width: 120px;
        //   height: 120px;
        //   object-fit: cover;
        //   border-radius: 50%;
        // }
        .image-btn{
          width: 120px;
          height: 120px;
          align-self: center;
          cursor: pointer;
          position: relative;
          &__img{
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 50%;
          }
          &__edit{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
          &__delete{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
        }
        .image-btn:hover{
          .image-btn__edit{
            opacity: 1;
          }
          .image-btn__delete{
            opacity: 1;
          }
        }
      }
      &--big{
        width: calc(66% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 15px;
        min-width: 610px;
        max-width: 780px;
        form{
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          textarea{
            width: 100%;
            max-width: 500px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #B1B1B1;
            resize: vertical;
            font-size: 1.7rem;
            height: 100px;
          }
          label{
            margin-top: 20px;
          }
          button{
            width: 160px;
            padding: 5px 10px;
            border: none;
            background-color: #f2790f;
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer;
            font-size: 1.7rem;
          }
        }
        form.account-change-form{
          p{
            margin: 0;
            font-size: 1.6rem;
          }
          button{
            margin-top: 5px;
          }
          div{
            button{
              margin-right: 10px;
            }
          }
        }
      }
    }
    .no-value{
      color: #B1B1B1;
    }
    .open-business-card-btn{
      background-color: #ffffff;
      border: 1px solid #f2790f;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
  }
  
  button.change-pass-btn{
    background-color: #f2790f;
    border: none;
    align-self: center;
    padding: 5px 10px;
    font-size: 1.7rem;
    border-radius: 8px;
  }
  
  button.delete-account-btn{
      background-color: #C82333;
      border: none;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
    .delete-account-confirm{
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      gap: 10px;
      span{
        span{
          cursor: pointer;
          text-decoration: underline;
          font-weight: 900;
        }
      }
    }

  @media (max-width: 660px){
    .user-data-container{
      &__inner{
        &--big{
          width: 100%;
          min-width: 0;
        }
        &--small{
          width: 100%;
          min-width: 0;
        }
      }
    }
  }
  </style>