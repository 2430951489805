<template>
    <div class="service-card-container">
      <div v-if="editable" class="service-card-container__action-btns">
        <button @click="() => $emit('deleteService', id, userServiceId)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
        <button @click="() => $emit('editService', id)"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
      </div>
      <div v-if="admin" class="service-card-container__action-btns">
        <button @click="() => {
          $emit('verify')
          verifyLoading = true
        }" v-if="!ishighlighted"><img src="@/assets/icons/check.svg" alt="zweryfikuj" v-if="!verifyLoading"><img src="@/assets/icons/loading.gif" v-else></button>
        <button @click="() => $emit('deleteService', id, userServiceId)"><img src="@/assets/icons/trash.svg" alt="edytuj"></button>
      </div>
      <div v-if="fav" class="service-card-container__action-btns">
        <button @click="() => $emit('deleteFavService', id)"><img src="@/assets/icons/trash.svg" alt="edytuj"></button>
      </div>
      <img :src="img" alt="">
      <div class="service-card-container__inner__highlighted-tag" v-if="ishighlighted">
        <span>zweryfikowane</span>
      </div>
      <div class="service-card-container__inner">
          <div class="price-container">
            <div></div>
            <span class="service-card-container__inner__price">{{price}} zł</span>
            <img v-if="isAdvance" class="advance-icon" src="@/assets/icons/cash.svg" alt="usługa z zaliczką" >
            <div v-if="!isAdvance"></div>
          </div>
          <b>{{ name }}</b>
          <span>{{category}}{{category ? ' | ' : null}}{{ state }}</span>
          <ButtonVue @click="() => $emit('openServiceModal')" color="#000" bgColor="#F27A07" text="Szczegóły" style="font-size: 1.6rem;"/>
      </div>
    </div>
  </template>
  
  <script>
  import ButtonVue from '../Button.vue'
  export default{
    name:'ServiceCardVue',
    data(){
      return{
        verifyLoading: false
      }
    },
    props:['img', 'name', 'state', 'ishighlighted', 'editable', 'id', 'userServiceId', 'admin', 'price', 'category', 'fav', 'isAdvance'],
    components:{
      ButtonVue
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .service-card-container{
    width: 290px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 15px;
    background-color: #f3f3f3;
    box-shadow: -1px 1px 7px 0px #c9c9c9;
    padding-bottom: 15px;
    position: relative;
    img{
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
    &__inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      padding: 0 10px;
      span{
          font-size: 1.7rem;
      }
      b{
        text-align: center;
      }
      &__highlighted-tag{
        background-color: #ffd700;
        width: 100%;
        text-align: center;
        font-size: 1.7rem;
      }
      &__price{
        background-color: #5EA01A;
        padding: 5px 10px;
        border-radius: 8px;
      }
      .price-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .advance-icon{
        width: 25px;
        object-fit: contain;
        height: 20px;
      }
    }
    &__action-btns{
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 5px;
      right: 5px;
      button{
        background: none;
        border: none;
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  </style>