<template>
    <div class="email-preview-modal-container" v-if="isVisible">
        <button @click="() => $emit('closeRecommendersModal')"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
        <table>
            <tr><th>id</th><th>email</th><th>data</th></tr>
            <tr v-for="recommender in recommenders" :key="recommender.register_id"><td>{{ recommender.register_id }}</td><td>{{ recommender.register_email }}</td><td>{{ recommender.updated_at.split('T')[0] }}</td></tr>
        </table>
    </div>
  </template>
  
  <script>
  export default{
    name:'SubscriptionDetailsModal',
    data(){
      return{
  
      }
    },
    props: ['isVisible', 'recommenders'],
    mounted(){
  
    },
    methods:{
  
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .email-preview-modal-container{
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
      border: 1px solid #000;
      overflow: scroll;
      max-height: 400px;
      width: 80%;
      max-width: 500px;
      table{
        width: 450px;
        border: 1px solid #000;
        border-collapse: collapse;
        tr, td, th{
            border: 1px solid #000;
        }
        td{
            padding: 5px;
        }
      }
      button{
          background: none;
          border: none;
          cursor: pointer;
          img{
              width: 25px;
          }
      }
      @media(max-width: 430px){
          justify-content: flex-start;
      }
  }
  </style>