<template>
  <div v-if="currentUser" class="calendar-container">
    <h2>Kalendarz</h2>
    <span>wybierz usługę do skonfigurowania kalendarza:</span>
    <div v-if="myServices && employees">
      <ServiceModal/>
      <table class="calendar-container__services-table">
        <tr><th>nazwa</th><th>pracownik</th><th>akcje</th></tr>
        <tr v-for="service in myServices" :key="service.service.id">
          <td>{{ service.service.name }}</td>
          <td>{{ service.service.employee_id ? `${employees.filter(item => item.id == service.service.employee_id)[0].firstname} ${employees.filter(item => item.id == service.service.employee_id)[0].lastname}` : 'brak pracownika' }}</td>
          <td>
            <button @click="() => $router.push({path: `/dla-biznesu/profile/calendar`, query: {serviceID: service.service.id}})"><img src="@/assets/icons/eye-fill.svg" alt="podgląd usługi"></button>
            <button @click="() => $router.push({path: `/dla-biznesu/profile/calendar/${service.service.id}`})"><img src="@/assets/icons/calendar-date.svg" alt="wybierz"></button>
          </td></tr>
      </table>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import axios from 'axios';
import authHeader from '@/services/auth-header';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
export default{
  name:'BizProfileCalendarVue',
  setup () {
    useMeta({title: 'Kalendarz'})
  },
  data(){
    return{
      myServices: null,
      employees: null
    }
  },
  computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      if(this.$store.state.isSubscribed){
        this.getMyServices()
        this.getEmployees()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
  props:{

  },
  components:{ServiceModal},
  methods:{
    getMyServices(){
      axios.get(`/UserService/${this.currentUser.data.user_id}`).then(res => {
        this.myServices = res.data
      })
    },
    getEmployees(){
    axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 100}, authHeader())
        .then(res => {
            this.employees = res.data.items
        }).catch(() => {
          
        })
    },
  }
}
</script>

<style scoped lang='scss'>
.calendar-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  &__services-table{
    border: 1px solid #b1b1b1;
    border-collapse: collapse;
    tr, td, th{
      border: 1px solid #b1b1b1;
    }
    tr{
      td, th{
        padding: 5px 8px;
        max-width: 400px;
        button{
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>