<template>
    <div class="contact-info-edit-cont" v-if="currentUser">
        <h2>Edycja danych kontaktowych</h2>
        <div class="contact-info-edit-content">
            <table class="contact-info-edit-table">
                <tr><td class="table-bold">adres 1:</td><td v-text="address1"></td><td @click="() => editData('address1', 'adres 1')"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td></tr>
                <tr><td class="table-bold">adres 2:</td><td v-text="address2"></td><td @click="() => editData('address2', 'adres 2')"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td></tr>
                <tr><td class="table-bold">numer telefonu:</td><td v-text="phoneNum"></td><td @click="() => editData('phoneNum', 'telefon')"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td></tr>
                <tr><td class="table-bold">adres email:</td><td v-text="emailAddress"></td><td @click="() => editData('emailAddress', 'adres email')"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td></tr>
                <tr><td class="table-bold">nip:</td><td v-text="nip"></td><td @click="() => editData('nip', 'nip')"><img src="@/assets/icons/pencil-square.svg" alt="Edytuj"></td></tr>
            </table>
            <div class="contact-info-edit-edit" v-if="isEditing">
                <button @click="() => isEditing = false" class="edit-close-btn"><img src="@/assets/icons/x.svg" alt="x"></button>
                <label for="editInput">edytuj {{whichKey}}</label>
                <input type="text" name="editInput" id="editInput" v-model="editInput">
                <button class="edit-submit-btn" @click="postCtInfo">Zatwierdź</button>
            </div>
        </div>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
    name:"ContactInfoEdit",
    data(){
        return{
            address1: 'brak',
            address2: 'brak',
            phoneNum: 'brak',
            emailAddress: 'brak',
            nip: 'brak',
            isEditing: false,
            whichKey: '',
            key: '',
            editInput: ''
        }
    },
    props:['isVisible'],
    mounted(){
        this.getCtInfo()
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
    },
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    methods:{
        getCtInfo(){
            axios.get(`${process.env.VUE_APP_API_BASE_URL}ContactGet`)
                .then(res => {
                    this.address1 = res.data.address_1
                    this.address2 = res.data.address_2
                    this.phoneNum = res.data.phone
                    this.emailAddress = res.data.email
                    this.nip = res.data.nip
                })
                
        },
        editData(key, whk){
            this.isEditing = true
            this.whichKey = whk
            this.key = key
        },
        postCtInfo(){
            if(this.key !== '' && this.editInput !== ''){
                this[this.key] = this.editInput
                const ob = {
                    address_1: this.address1,
                    address_2: this.address2,
                    phone: this.phoneNum,
                    email: this.emailAddress,
                    nip: this.nip
                }
                axios.post(`${process.env.VUE_APP_API_BASE_URL}Contact`, ob, authHeader())
                    .then(() => {
                        this.isEditing = false
                    })
                    
            }
        }
    }  
}
</script>

<style scoped>
.contact-info-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-info-edit-table > tr > td{
    padding: 5px 15px;
}
.contact-info-edit-table > tr > td:nth-last-child(1){
    cursor: pointer;
}
.contact-info-edit-table > tr{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid black;
}
.table-bold{
    font-weight: 700;
}
.contact-info-edit-edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    margin-top: 15px;
    border-radius: 8px;
}
.contact-info-edit-edit > label{
    font-size: 1.7rem;
}
.contact-info-edit-edit > input{
    margin-top: 5px;
    padding: 5px 10px;
}
.edit-close-btn{
    background: none;
    border: none;
    cursor: pointer;
}
.edit-submit-btn{
    padding: 5px 10px;
    margin-top: 5px;
    cursor: pointer;
}
.contact-info-edit-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
</style>