<template>
    <div v-if="currentUser" class="reports-admin">
        <h2>Zgłoszenia</h2>
        <div class="service-search">
            <!-- <input type="text" placeholder="szukaj" v-model="searchInput">
            <button @click="() => searchService()">Szukaj</button>
            <button @click="() => resetSearch()" class="btn-green">resetuj</button> -->
            <div class="service-search__pages">
              <span>strona:</span>
              <button @click="() => {
                if(page > 1){
                  page -= 1
                  getReports()
                }
              }"><img src="@/assets/message-arrow.png" alt=""></button>
              <span>{{ page }}/{{ Math.ceil(count/50) }}</span>
              <button @click="() => {
                if(page < Math.ceil(count/50)){
                  page += 1
                  getReports()
                }
              }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
            </div>
        </div>
        <div class="reports-admin__grid" v-if="reports">
          <ReportCard @deleteReport="(id) => deleteReport(id)" :id="report.id" :type="report.announcement_id ? 'ogłoszenie' : 'usługa'" :comment="report.comment" :date="report.created_at" v-for="report in reports" :key="report.id"/>
        </div>
        <div v-else>
          <h3>Brak zgłoszeń</h3>
        </div>
    </div>
</template>

<script>
import ReportCard from '@/components/SpecialComponents/ReportCard.vue';

import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
    name: "AdminReports",
    data() {
        return {
          page: 1,
          reports: null,
          searchInput: '',
          count: 0
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
        this.getReports()
    },
    methods: {
      getReports(){
        axios.post(`/ReportSearch`, {
          page: this.page,
          limit: 50
        }, authHeader()).then(res => {
          this.reports = res.data.items
          this.count = res.data.count
        })
      },
      // searchService(){
      //   this.reports = this.reports.filter(item => item.comment.includes(this.searchInput))
      // },
      // resetSearch(){
      //   this.searchInput = ''
      //   this.getReports()
      // },
      deleteReport(id){
        axios.delete(`/Report/${id}`, authHeader()).then(() => {
          this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zgłoszenie')
          this.getReports()
        }).catch(() => {
          
        })
      }
    },
    components: { ReportCard }
}
</script>

<style scoped lang="scss">
.reports-admin{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  &__grid{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
}

.service-search{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.service-search > input{
    padding: 7px;
    border-radius: 10px;
    border: 2px solid #8b8b8b;
    width: 250px;
}
.service-search > button{
    border: none;
    background-color: #f2790f;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
}
.service-search > .btn-green{
    background-color: #5ea01a !important;
}

.service-search{
  &__pages{
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 10px;
    button{
      background-color: #cbcbcb;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      img{
        width: 15px;
      }
      img.rotate{
        transform: rotate(180deg);
      }
    }
  }
}
</style>