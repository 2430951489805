<template>
  <h2>Świadczone usługi</h2>
  <ButtonVue color="#000" text="Dodaj usługę" bgColor="#f2790f" @click="() => $router.push('/dla-biznesu/profile/services-provided/add')"/>
  <div class="search-container">
    <input type="text" class="search-input" id="serviceSearch" v-model="serviceSearch" placeholder="szukaj usług">
    <button @click="searchService" class="search-btn"><img src="@/assets/icons/search.svg" alt="szukaj"></button>
    <button @click="() => {
      getMyServices()
      serviceSearch = ''
    }" class="reset-btn">resetuj</button>
  </div>
  <div v-if="currentUser && myServices.length > 0" class="services-grid">
    <ServiceModal/>
    <div v-if="states.length > 0">
      <ServiceCardVue @editService="(id) => {$router.push({path: `/dla-biznesu/profile/services-provided/edit`, query: {serviceToEdit: id}})}" @deleteService="(id, userServiceId) => deleteService(id, userServiceId)" @openServiceModal="() => $router.push({path: `/dla-biznesu/profile/services-provided`, query: {serviceID: service.service.id}})" :price="service.service.price" v-for="service in myServices" :is-advance="service.service.advanceAmount ? true : false" :id="service.service.id" :key="service.service.id" :img="service.service.photo_url" :name="service.service.name" :state="states.filter(state => state.value == service.service.state_id)[0].name" :ishighlighted="service.service.active" :editable="true" :userServiceId="service.id"/>
    </div>
    <div v-else>
      <h4 class="no-services-info">Ładowanie...</h4>
    </div>
  </div>
  <div v-else>
    <h4 class="no-services-info">Brak usług</h4>
  </div>
</template>

<script>
import axios from 'axios';
import { useMeta } from 'vue-meta';
import ButtonVue from '@/components/Button.vue';
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import authHeader from '@/services/auth-header';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
export default{
  name:'BizProfileServicesProvided',
  setup () {
    useMeta({title: 'Świadczone usługi'})
  },
  data(){
    return{
      myServices:[],
      states: [],
      serviceSearch: ''
    }
  },
  props:{

  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  components:{
    ButtonVue,
    ServiceCardVue,
    ServiceModal
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 2) {
      this.$router.push('/login');
    }
    this.getMyServices()
    if(this.$store.state.isSubscribed){
      this.getMyServices()
    }else{
      this.$router.push('/dla-biznesu/profile/user-data')
    }
  },
  methods:{
    getMyServices(){
      axios.get(`/UserService/${this.currentUser.data.user_id}`).then(res => {
        this.myServices = res.data
      })
      axios.get('/State').then(res => {
        res.data.forEach((item) => {
          const ob = {
            name: item.name,
            value: `${item.id}`
          }
          this.states.push(ob)
        })
      })
    },
    deleteService(id,userServiceId){
      axios.delete(`/Service/${id}`, authHeader()).then(res => {
        if(String(res.status).charAt(0) == '2'){
          axios.delete(`/UserService/${userServiceId}`, authHeader()).then(resp => {
            if(String(resp.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie usunięto usługę')
              this.getMyServices()
            }
          })
        }
      })
    },
    searchService(){
      if(this.serviceSearch.length > 2){
        this.myServices = this.myServices.filter(item => item.service.name.toLowerCase().includes(this.serviceSearch.toLowerCase()))
      }else{
        this.$store.commit('setIsAlert', 'wpisz co najmniej 3 znaki')
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.no-services-info{
  text-align: center;
}
.services-grid{
  margin-top: 50px;
  div{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
  }
}
.search-input{
  border: 1px solid #b1b1b1;
  padding: 7px;
  border-radius: 8px;
  margin-left: 20px;
}
.search-btn{
  cursor: pointer;
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  img{
    width: 20px;
    margin-top: 5px;
  }
}
.search-container{
  display: inline-flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.reset-btn{
  cursor: pointer;
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  padding: 4px;
}
</style>