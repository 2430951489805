import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue';
import BusinessHomePage from '@/pages/BusinessHomePage.vue';
import NotFound from '@/pages/NotFound.vue'
import LoginPage from '@/pages/LoginPage.vue'
import BusinessProfile from '@/pages/BusinessProfile.vue'
import ProfileVue from '@/pages/Profile.vue'
import RegistrationPage from '@/pages/RegistrationPage.vue'
import PrivProfileUserData from '@/pages/priv-profile/UserData.vue'
import PrivProfileMessagesVue from '@/pages/priv-profile/Messages.vue'
import PrivProfileFavServices from '@/pages/priv-profile/FavServices.vue'
import PrivProfileAnnouncementsVue from '@/pages/priv-profile/Announcements.vue'
import AddAnnouncementVue from '@/pages/priv-profile/AddAnnouncement.vue'
import BizProfileUserData from '@/pages/biz-profile/UserData.vue'
import BizProfileServicesProvided from '@/pages/biz-profile/ServProvided.vue'
import BizProfileAddServiceVue from '@/pages/biz-profile/AddService.vue'
import BizProfileMessagesVue from '@/pages/biz-profile/Messages.vue'
import BizProfileEmployees from '@/pages/biz-profile/Employees.vue'
import BizProfileAddEmployeeVue from '@/pages/biz-profile/AddEmployee.vue'
import BizProfileCalendarVue from '@/pages/biz-profile/Calendar.vue'
import EmployeeProfile from '@/pages/EmployeeProfile.vue'
import EmployeeProfileUserData from '@/pages/employee-profile/UserData.vue'
import BusinessCardVue from '@/pages/BusinessCard.vue'
import BlogVue from '@/pages/Blog.vue'
import AnnouncementsPage from '@/pages/Announcements.vue'
import BrowserVue from '@/pages/Browser.vue'
import AdminProfile from '@/pages/AdminProfile.vue'
import SepcsEdit from '@/pages/admin-profile/Categories.vue'
import ContactInfoEdit from '@/pages/admin-profile/ContactInfo.vue'
import BlogEdit from '@/pages/admin-profile/Blog.vue'
import FooterEdit from '@/pages/admin-profile/Footer.vue'
import PartnersEdit from '@/pages/admin-profile/Partners.vue'
import MailBox from '@/pages/admin-profile/MailBox.vue'
import RegulationsEdit from '@/pages/admin-profile/Regulations.vue'
import ServiceVerifying from '@/pages/admin-profile/ServiceVerify.vue'
import UsersList from '@/pages/admin-profile/Users.vue'
import UsersAdd from '@/pages/admin-profile/UsersAdd.vue'
import ServiceCalendar from '@/pages/biz-profile/ServiceCalendar.vue'
import PriceList from '@/pages/admin-profile/PriceList.vue'
import StatuteVue from '@/pages/regulations/Statute.vue'
import PrivacyVue from '@/pages/regulations/Privacy.vue'
import CookiesVue from '@/pages/regulations/Cookies.vue'
import MakeAppointment from '@/pages/priv-profile/MakeAppointment.vue'
import ChangePassword from '@/pages/admin-profile/ChangePassword.vue'
import VisitsVue from '@/pages/priv-profile/Visits.vue'
import PageReviews from '@/pages/admin-profile/PageReviews.vue'
import AnnouncementsVerify from '@/pages/admin-profile/AnnouncementsVerify.vue'
import BizProfileEditServiceVue from '@/pages/biz-profile/EditService.vue'
import BizProfileRecommendations from '@/pages/biz-profile/Recommendations.vue'
import ReportPage from '@/pages/Report.vue'
import AdminReports from '@/pages/admin-profile/Reports.vue'
import AdminReportPreview from '@/pages/admin-profile/ReportPreview.vue'
import BizProfileCreateRecommendation from '@/pages/biz-profile/AddRecommendation.vue'
import PrivRecommendations from '@/pages/priv-profile/Recommendations.vue'
import UsefulTips from '@/pages/biz-profile/UsefulTips.vue'
import ResetPass from '@/pages/ResetPass.vue'
import ResetPass2 from '@/pages/ResetPass2.vue'
import SpecBrowserVue from '@/pages/SpecialistsBrowser.vue'
import EntryPage from '@/pages/EntryPage.vue'
import BizProfileAnnouncementsVue from '@/pages/biz-profile/BizAnnouncements.vue'
import BizAddAnnouncementVue from '@/pages/biz-profile/BizAddAnnouncement.vue'
import AddSubPlan from '@/pages/admin-profile/AddSubPlan.vue'
import SubscribersList from '@/pages/admin-profile/Subscribers.vue'
import ReportErrorPriv from '@/pages/priv-profile/ReportError.vue'
import ReportErrorBiz from '@/pages/biz-profile/ReportError.vue'
import ReportErrorEmployee from '@/pages/employee-profile/ReportError.vue'
import RegistrationPage2 from '@/pages/RegistrationPage2.vue'
import UserSubscriptions from '@/pages/biz-profile/UserSubscriptions.vue'
import SendNewsletter from '@/pages/admin-profile/Newsletter.vue'
import ConfirmNewsletter from '@/pages/ConfirmNewsletter.vue'
import PaymentConfirm from '@/pages/biz-profile/PaymentConfirm.vue'
import FirmUserData from '@/pages/firm-profile/UserData.vue'
import ReportErrorFirm from '@/pages/firm-profile/ReportError.vue'
import SpecialistsManagement from '@/pages/firm-profile/Specialists.vue'
import SpecialistAdd from '@/pages/firm-profile/SpecialistAdd.vue'
import PriceListPreview from '@/pages/admin-profile/PriceListPreview.vue'
import RecommendersList from '@/pages/admin-profile/Recommenders.vue'
import FirmInvConfirm from '@/pages/biz-profile/FirmInvConfirm.vue';
import ServicesTitle from "@/pages/admin-profile/ServicesTitle.vue"

const routes = [
    { path: '/', component: HomePage },
    { path: '/dla-biznesu', component: BusinessHomePage },
    { path: '/:catchAll(.*)', redirect: '/404' },
    { path: '/404', component: NotFound },
    { path: '/login', component: LoginPage },
    { path: '/login/reset', component: ResetPass },
    { path: '/login/reset2', component: ResetPass2 },
    { path: '/dla-biznesu/profile', component: BusinessProfile },
    { path: '/profile', component: ProfileVue },
    { path: '/register', component: RegistrationPage },
    { path: '/register2', component: RegistrationPage2 },
    { path: '/profile/user-data', component: PrivProfileUserData },
    { path: '/profile/messages', component: PrivProfileMessagesVue },
    { path: '/profile/fav-services', component: PrivProfileFavServices},
    { path: '/profile/announcements', component: PrivProfileAnnouncementsVue},
    { path: '/profile/announcements/add', component: AddAnnouncementVue},
    { path: '/profile/report-error', component: ReportErrorPriv},
    { path: '/dla-biznesu/profile/user-data', component: BizProfileUserData},
    { path: '/dla-biznesu/profile/services-provided', component: BizProfileServicesProvided},
    { path: '/dla-biznesu/profile/subscriptions', component: UserSubscriptions},
    { path: '/dla-biznesu/profile/subscriptions/confirm', component: PaymentConfirm},
    { path: '/dla-biznesu/profile/services-provided/add', component: BizProfileAddServiceVue},
    { path: '/dla-biznesu/profile/services-provided/edit', component: BizProfileEditServiceVue},
    { path: '/dla-biznesu/profile/messages', component: BizProfileMessagesVue},
    { path: '/dla-biznesu/profile/recommendations', component: BizProfileRecommendations},
    { path: '/dla-biznesu/profile/employees', component: BizProfileEmployees},
    { path: '/dla-biznesu/profile/employees/add', component: BizProfileAddEmployeeVue},
    { path: '/dla-biznesu/profile/calendar', component: BizProfileCalendarVue},
    { path: '/dla-biznesu/employee-profile', component: EmployeeProfile},
    { path: '/dla-biznesu/employee-profile/user-data', component: EmployeeProfileUserData},
    { path: '/dla-biznesu/employee-profile/report-error', component: ReportErrorEmployee},
    { path: '/browse/user/:userId', component: BusinessCardVue},
    { path: '/blog', component: BlogVue},
    { path: '/blog/entry/:entryID', component: EntryPage},
    { path: '/announcements', component: AnnouncementsPage},
    { path: '/browser', component: BrowserVue },
    { path: '/spec-browser', component: SpecBrowserVue },
    { path: '/admin-profile', component: AdminProfile },
    { path: '/admin-profile/categories', component: SepcsEdit },
    { path: '/admin-profile/contact-info', component: ContactInfoEdit },
    { path: '/admin-profile/blog', component: BlogEdit },
    { path: '/admin-profile/footer', component: FooterEdit },
    { path: '/admin-profile/partners', component: PartnersEdit },
    { path: '/admin-profile/mailbox', component: MailBox },
    { path: '/admin-profile/regulations', component: RegulationsEdit },
    { path: '/admin-profile/service-verify', component: ServiceVerifying },
    { path: '/admin-profile/users', component: UsersList },
    { path: '/admin-profile/change-password', component: ChangePassword },
    { path: '/admin-profile/users/add', component: UsersAdd },
    { path: '/admin-profile/page-reviews', component: PageReviews },
    { path: '/admin-profile/announcements-verify', component: AnnouncementsVerify },
    { path: '/admin-profile/reports', component: AdminReports },
    { path: '/admin-profile/subscribers', component: SubscribersList },
    { path: '/admin-profile/recommenders', component: RecommendersList },
    { path: '/admin-profile/newsletter', component: SendNewsletter },
    { path: '/admin-profile/price-list-preview', component: PriceListPreview },
    { path: '/admin-profile/titles', component: ServicesTitle },
    { path: '/dla-biznesu/profile/calendar/:serviceId', component: ServiceCalendar },
    { path: '/admin-profile/price-list', component: PriceList },
    { path: '/admin-profile/price-list/add', component: AddSubPlan },
    { path: '/statute', component: StatuteVue },
    { path: '/cookies', component: CookiesVue },
    { path: '/privacy', component: PrivacyVue },
    { path: '/profile/make-appointment', component: MakeAppointment },
    { path: '/profile/visits', component: VisitsVue },
    { path: '/profile/recommendations', component: PrivRecommendations },
    { path: '/report', component: ReportPage},
    { path: '/admin-profile/reports/:reportId', component: AdminReportPreview},
    { path: '/dla-biznesu/profile/recommendations/add', component: BizProfileCreateRecommendation},
    { path: '/dla-biznesu/profile/useful-tips', component: UsefulTips},
    { path: '/dla-biznesu/profile/announcements', component: BizProfileAnnouncementsVue},
    { path: '/dla-biznesu/profile/announcements/add', component: BizAddAnnouncementVue},
    { path: '/dla-biznesu/profile/report-error', component: ReportErrorBiz},
    { path: '/confirm-firm', component: FirmInvConfirm},
    { path: '/confirm-newsletter', component: ConfirmNewsletter },
    { path: '/firm-profile', component: FirmUserData},
    { path: '/firm-profile/report-error', component: ReportErrorFirm},
    { path: '/firm-profile/specialists', component: SpecialistsManagement},
    { path: '/firm-profile/specialists/add', component: SpecialistAdd}
];


const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/404', '/dla-biznesu', '/', '/login', '/login/reset', '/login/reset2', '/register', `/blog/entry/${to.params.entryID}`, `/browse/user/${to.params.userId}`, '/blog','/announcements', '/browser', '/statute', '/cookies', '/privacy', '/report', '/spec-browser', '/register2', '/confirm-newsletter'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('userm2VET');
  
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
})

export default router