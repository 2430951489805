<template>
  <div v-if="currentUser">
    <div class="profile-container" v-if="currentUser.data.role === 3">
      <h2>Wybierz zakładkę</h2>
    </div>
  </div>
  <div v-else>
    <h2>Błąd wczytywania. Spróbuj ponownie</h2>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
export default{
  name:'ProfileVue',
  setup () {
    useMeta({title: 'profil użytkownika'})
  },
  data(){
    return{

    }
  },
  props:{

  },
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    }
  },
  methods:{
  },  
  mounted(){
    if(!this.currentUser){
      this.$router.push('/login')
    }else if(this.currentUser.data.role !== 3){
      this.$router.push('/login')
    }else if(this.currentUser){
      this.$router.push('/profile/user-data')
    }
  }
}
</script>

<style scoped lang='scss'>

</style>