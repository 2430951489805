<template>
  <input :type="this.type" :placeholder="this.placeholder" :id="id" @input="() => this.$emit('getInputText', inputText)" class="input" v-model="inputText">
</template>

<script>
export default{
  name:'InputVue',
  data(){
    return{
        inputText: ''
    }
  },
  props:['type', 'placeholder', 'id'],
}
</script>

<style scoped lang='scss'>
.input{
    padding: 7px 15px;
    border-radius: 8px;
    border: none;
}
</style>