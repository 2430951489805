<template>
  <div v-if="this.$route.query.label && this.$route.query.id && this.$route.query.property">
    <div class="report-container" v-if="currentUser">
      <h2>Zgłoś {{ label }}</h2>
      <form @submit.prevent="() => confirmReport = true">
        <label for="comment">*komentarz:</label>
        <textarea required name="comment" v-model="comment" id="comment"></textarea>
        
        <button type="submit">Wyślij</button>
      </form>
      <div class="report-confirm" v-if="confirmReport">
        <span>czy na pewno?</span>
        <span><span @click="report">Tak</span> / <span @click="() => confirmReport = false">Nie</span></span>
      </div>
    </div>
    <div v-else class="not-logged-in">
      <h2>Zaloguj się, aby zgłosić {{ label }}</h2>
      <div>
          <ButtonVue text="Zaloguj się" bg-color="#F37905" color="#000" @click="() => $router.push('/login')"/>
          <ButtonVue text="Zarejestruj się" bg-color="#F37905" color="#000" @click="() => $router.push('/register')"/>
      </div>
    </div>
  </div>
  <div v-else>
    <h2>Błąd, brak parametrów</h2>
    <ButtonVue text="Wróć na stronę główną" bg-color="#F37905" color="#000" @click="() => $router.push('/')"/>
  </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta';
  import axios from 'axios';
    import ButtonVue from '@/components/Button.vue';
import authHeader from '@/services/auth-header';
  export default{
      name:"ReportPage",
      setup () {
          useMeta({title: 'Zgłoś'})
      },
      data(){
          return{
            label: null,
            id: null,
            property: null,
            comment: null,
            confirmReport: false
          }
      },
      props:{
  
      },
      computed: {
          currentUser() {
              return this.$store.state.auth.user;
          }
      },
      mounted(){
        this.getQuery()
      },
      methods:{
        getQuery(){
            this.label = this.$route.query.label
            this.id = this.$route.query.id
            this.property = this.$route.query.property
        },
        report(){
            axios.post(`/Report`, {
                [this.property]: this.id,
                comment: this.comment
            }, authHeader()).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie wysłano zgłoszenie')
              this.$router.push('/')
            }
          })
        }
      },
      components:{ ButtonVue }
  }
  </script>
  
  <style scoped lang="scss">
  .not-logged-in{
      display: flex;
      flex-direction: column;
      align-items: center;
      div{
          display: flex;
          justify-content: center;
          gap: 40px;
      }
  }
  .report-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    form{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 90%;
        max-width: 500px;
        textarea{
            padding: 7px 15px;
            border-radius: 8px;
            border: 1px solid #9b9b9b;
            resize: vertical;
            width: 100%;
        }
        button{
            background-color: #F27A07;
            padding: 5px 20px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
        }
    }
  }
    .report-confirm{
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      gap: 10px;
      span{
        span{
          cursor: pointer;
          text-decoration: underline;
          font-weight: 900;
        }
      }
    }
  </style>