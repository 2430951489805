<template>
<div class="add-sub-plan-wrapper" v-if="currentUser">
    <h2>Dodaj plan</h2>
    <form @submit.prevent="addSubPlan">
        <label for="name">Nazwa planu</label>
        <input type="text" id="name" v-model="newSubData.name" name="name" required>

        <label for="subscription">Opłata abonamentowa (na miesiąc)</label>
        <input type="number" step="0.01" v-model="newSubData.subscriptionM" id="subscription" name="subscription" required>

        <label for="additionalUser">Opłata za dodatkowego użytkownika (na miesiąc)</label>
        <input type="number" step="0.01" v-model="newSubData.additionalUserM" id="additionalUser" name="additionalUser" required>

        <label for="additionalSMS">Opłata za wysłanie SMSa (w złotówkach)</label>
        <input type="number" step="0.01" v-model="newSubData.additionalSMS" id="additionalSMS" name="additionalSMS" required>

        <label for="freeUsers">Ilość darmowych użytkowników przypisanych do konta</label>
        <input type="number" v-model="newSubData.freeUsers" id="freeUsers" name="freeUsers" required>

        <label for="freeSMS">Ilość darmowych SMSów do wysłania</label>
        <input type="number" v-model="newSubData.freeSMS" id="freeSMS" name="freeSMS" required>

        <label for="period">Czas trwania subskrybcji (w miesiącach). <br> W cenniku wyświetlana będzie kwota <br> pomnożona przez podaną tutaj ilość miesięcy.</label>
        <input type="number" v-model="newSubData.period" id="period" name="period" required>

        <button type="submit">Dodaj</button>
    </form>
</div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
name:"AddSubPlan",
data(){
    return{
        newSubData:{
            name: '',
            subscriptionM: '',
            additionalUserM: '',
            additionalSMS: '',
            freeUsers: '',
            freeSMS: '',
            period: ''
        }
    }
},
props:['isVisible'],
mounted(){
    if (!this.currentUser) {
    this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
    this.$router.push('/login');
    }
},
computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
},
methods:{
    addSubPlan(){
        axios.post('/Subscription', this.newSubData, authHeader()).then(() => {
            this.$store.commit('setIsSuccess', 'Pomyślnie dodano plan')
            this.$router.push('/admin-profile/price-list')
        }).catch(() => {
            
        })
    }
}  
}
</script>

<style scoped lang="scss">
.add-sub-plan-wrapper{
    form{
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        input{
            margin-bottom: 10px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #b1b1b1;
            max-width: 400px;
            width: 100%;
        }
        input[type="number"]{
            width: 100px;
        }
        label{
            text-align: center;
        }
        button{
            background-color: #F27A07;
            border: none;
            padding: 10px 30px;
            border-radius: 8px;
            cursor: pointer;
            margin-top: 20px;
        }
    }
}
</style>