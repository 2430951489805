<template>
    <SubscriptionDetailsModal :isVisible="isDetailsModalVisible" @closeEmailModal="isDetailsModalVisible = false" :currentSubscriber="currentSubscriber"/>
    <div class="subscribers" v-if="currentUser">
        <h2>Subskrybenci</h2>
        
        <div class="sub-plan-choose">
            <select name="plan" id="plan" v-model="plan" @change="() => filterUsers()">
                <option value="0">wszyscy</option>
                <option v-for="sub in subscriptions" :value="sub.id" :key="sub.id">{{ sub.name }}</option>
            </select>
            <div class="sms-users">
                <input type="checkbox" id="smsUsers" v-model="smsUsers" @change="filterUsers">
                <label for="smsUsers">pokaż użytkowników z wysłanymi powiadomieniami sms</label>
            </div>
            <span>wyszukaj po:</span>
            <select name="filter" id="filter" v-model="searcher.choosedFilter">
                <option :value="item.value" v-for="item, index in searcher.filters" :key="index">{{ item.name }}</option>
            </select>
            <input type="text" v-model="searcher.searchPhrase" v-if="searcher.choosedFilter" :placeholder="searcher.filters.filter(item => item.value == searcher.choosedFilter)[0].name">
            <button @click="() => {searcher.searchPhrase ? filterUsers() : null}">Szukaj</button>
            <img v-if="searcher.loading" class="loading" src="@/assets/icons/loading.gif" alt="">
            <button @click="() => {
                smsUsers = false
                searcher.searchPhrase = ''
                usersList.page = 1
                getSubscribers()
            }">Resetuj filtry</button>
        </div> 
        <div class="users-displaying-options">
            <div>
                <span>pokaż na stronie:</span>                
                <select name="usersLimit" id="usersLimit" class="users-limit-select" v-model="usersList.limit" @change="() => {
                    filterUsers()
                }">
                    <option value="1">1</option>
                    <option value="10" selected>10</option>
                    <option value="16">16</option>
                    <option value="22">22</option>
                    <option value="28">28</option>
                    <option value="34">34</option>
                </select>
            </div>
            <div>
                <span>strona: {{ usersList.page }}/{{ Math.ceil(usersList.usersCount/usersList.limit) }}</span>
                <div class="users-page-btns">
                    <button @click="() => {
                        if(usersList.page > 1){
                            usersList.page -= 1
                            filterUsers()
                        }
                    }"><img src="@/assets/icons/arrow-left-short.svg" alt=""></button>
                    <button @click="() => {
                        if(usersList.page < Math.ceil(usersList.usersCount/usersList.limit)){
                            usersList.page += 1
                            filterUsers()
                        }
                    }"><img src="@/assets/icons/arrow-right-short.svg" alt=""></button>
                </div>
            </div>
        </div>
        <div class="subscribers__table" v-if="usersList.usersList">
            <table v-if="usersList.usersList.length > 0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>adres e-mail</th>
                        <th>status</th>
                        <th>status sms</th>
                        <th>akcje</th>
                    </tr>  
                </thead>
                <tbody>
                    <tr v-for="user in usersList.usersList" :key="user.id">
                        <td>{{ user.id }}</td>
                        <td>{{ user.email }}</td>
                        <td :class="user.subscription ? 'green-text' : 'red-text'">{{ user.role.id == 2 ? (user.subscription ? 'subskrybent' : 'nie zasubskrybował') : '-' }}</td>
                        <td :class="user.role.id == 2 ? (user.subscription ? (user.sending_sms_active === null || user.sending_sms_active ? 'green-text' : 'red-text') : 'red-text') : 'red-text'">{{ user.role.id == 2 ? (user.subscription ? (user.sending_sms_active === null || user.sending_sms_active ? 'aktywne' : 'nieaktywne') : '-') : '-' }}</td>
                        <td>
                            <RouterLink v-if="user.role.id == 2" :to="`/browse/user/${user.id}`"><button>wyświetl wizytówkę</button></RouterLink>
                            <button v-if="user.role.id == 2 && !user.isAddingSubscription && !user.subscription" class="green" @click="user.isAddingSubscription = true">nadaj subskrybcję</button>
                            <select name="addSubscriptionPlanSelect" v-if="user.isAddingSubscription" id="addSubscriptionPlanSelect" v-model="user.addSubscriptionPlanSelect" @change="addSubscription(user.id, user.addSubscriptionPlanSelect)">
                                <option v-for="sub in subscriptions" :value="sub.id" :key="sub.id">{{ sub.name }}</option>
                            </select>
                            <button v-if="user.isAddingSubscription" @click="user.isAddingSubscription = false"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
                            <button v-if="user.role.id == 2 && user.subscription" class="red" @click="removeSubscription(user.id, user.subscription.subscription.id)">anuluj subskrybcję</button>
                            <button v-if="user.role.id == 2 && user.subscription" class="red" @click="blockSMS(user.id)">zablokuj sms</button>
                            <button v-if="user.role.id == 2 && user.subscription" class="blue" @click="{isDetailsModalVisible = true; currentSubscriber = user}">szczegóły</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2 v-else>Nie znaleziono subskrybentów</h2>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SubscriptionDetailsModal from '@/components/SpecialComponents/SubscriptionDetailsModal.vue'
  import authHeader from '@/services/auth-header';
  export default{
    name:"SubscribersList",
    data(){
        return{
            smsUsers: false,
            isDetailsModalVisible: false,
            plan: '0',
            subscriptions: [],
            usersList: {
                usersList: null,
                page: 1,
                limit: 10,
                usersCount: null
            },
            currentSubscriber: null,
            searcher: {
                searchPhrase: '',
                choosedFilter: '',
                loading: false,
                filters: [
                    {
                        name: 'adres e-mail',
                        value: 'email'
                    },
                    {
                        name: 'imię i nazwisko',
                        value: 'name'
                    }
                ]
            },
        }
    },
    mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
      this.getPlans()
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    methods:{
        getPlans(){
            axios.post('/SubscriptionSearch', {
               page: 1,
               limit: 10 
            }).then(res => {
                this.subscriptions = res.data.items
                this.plan = this.subscriptions[0].id
            }).then(() => {
                this.getSubscribers()
            }).catch(() => {
                
            })
        },
        getSubscribers(){
            const ob = {
                page: this.usersList.page,
                limit: this.usersList.limit,
                subscription_id: this.plan
            }
            if(this.smsUsers){
                ob.have_sent_sms = true
            }
            axios.post('/Users', ob).then((res) => {
                this.usersList.usersCount = res.data.count
                this.usersList.usersList = res.data.items
            })
        },
        filterUsers() {
                this.searcher.loading = true
                const ob = {
                    page: this.usersList.page,
                    limit: this.usersList.limit,
                    subscription_id: this.plan
                };
                switch (this.searcher.choosedFilter) {
                    case 'email':
                        ob.email = this.searcher.searchPhrase
                        break;
                    case 'name':
                        ob.name = this.searcher.searchPhrase
                        break;
                }
                if(this.smsUsers){
                    ob.have_sent_sms = true
                }
                axios.post(`/Users`, ob).then((res) => {
                    this.searcher.loading = false
                    this.usersList.usersCount = res.data.count
                    this.usersList.usersList = res.data.items
                }).catch(() => {
                    
                });
        },
        addSubscription(id, subid){
            axios.post('/AddUserToSubscription', {
                user_id: id,
                subscription_id: subid
            }, authHeader()).then((res) => {
                if(String(res.status).charAt(0) === '2'){
                    this.$store.commit('setIsSuccess', 'Pomyślnie nadano subskrypcję')
                    this.getSubscribers()
                }
            })
        },
        removeSubscription(id, subid){
            axios.post('/DeleteUserFromSubscription', {
                user_id: id,
                subscription_id: subid
            }, authHeader()).then((res) => {
                if(String(res.status).charAt(0) === '2'){
                    this.$store.commit('setIsSuccess', 'Pomyślnie odpięto subskrypcję')
                    this.getSubscribers()
                }
            })
        },
        blockSMS(id){
            axios.get(`/SendingSmsBan/${id}`, authHeader()).then(res => {
                if(String(res.status).charAt(0) === '2'){
                    this.$store.commit('setIsSuccess', 'Pomyślnie zablokowano sms dla użytkownika')
                    this.getSubscribers()
                }
            })
        }
    },
    components: { SubscriptionDetailsModal }  
  }
  </script>
  
  <style scoped lang="scss">
  .sms-users{
    display: flex;
    gap: 10px;
  }
  .sub-plan-choose{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    select{
        padding: 10px 20px;
        border-radius: 8px;
        border: 1px solid #adadad;
    }
    button{
        background-color: #F2790F;
        padding: 8px 30px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
    }
    input[type=text]{
        padding: 8px;
        width: 260px;
        border-radius: 10px;
        border: 1px solid rgb(133, 133, 133);
        box-sizing: border-box;
    }
  }
    .subscribers{
        &__table{
            overflow: scroll;
            table, tr, td, th{
                border: 1px solid #000;
            }
            table{
                border-collapse: collapse;
            }
            td, th{
                padding: 5px 10px;
            }
            td{
                button{
                    margin: 7px 8px;
                    background-color: #F27A07;
                    border: none;
                    border-radius: 8px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
                button.green{
                    background-color: #5EA01A;
                }
                button.red{
                    background-color: #DC3545;
                }
                button.blue{
                    background-color: #007BFF;
                }
            }
        }
        &__table::-webkit-scrollbar{
            width: 3px;
        }
        &__table::-webkit-scrollbar-track {
        background: #fff; 
        }
        &__table::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 4px;
        }
    }
    .red-text{
        color: #DC3545
    }
    .green-text{
        color: #5EA01A;
    }
    .users-displaying-options{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
    .users-displaying-options > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
    .users-limit-select{
    padding: 5px;
    width: 50px;
    border-radius: 8px;
}
.users-page-btns > button{
    background-color: #F2790F;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 10px;
    cursor: pointer;
}
.loading{
    width: 40px;
}
  </style>