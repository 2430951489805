<template>
    <div class="report-container">
        <ButtonVue @click="() => $router.push(`/admin-profile/reports/${id}`)" color="#000" bg-color="#f2790f" text="Podgląd"/>
        <span class="report-container__type">{{ type }}</span>
        <p>{{ comment.length > 300 ? comment.slice(null, -(comment.length - 300)) : comment }}</p>
        <span>{{ date.slice(null, -10).split('T')[0] }}<br>{{ date.slice(null, -10).split('T')[1] }}</span>
        <button class="report-container__delete" @click="() => $emit('deleteReport', id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
    </div>
  </template>
  
  <script>
  import ButtonVue from '../Button.vue';
  export default{
    name:'ReportCard',
    data(){
      return{
        
      }
    },
    methods:{

    },
    props:['comment', 'date', 'id', 'type'],
    components:{
      ButtonVue
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .report-container{
      display: flex;
      background-color: #fff;
      box-shadow: -1px 1px 7px 0px #c9c9c9;
      border-radius: 15px;
      overflow: hidden;
      position: relative;
      width: 80%;
      min-width: 230px;
      align-items: flex-start;
      padding: 20px;
      justify-content: flex-start;
      gap: 30px;
      flex-wrap: wrap;
      p{
        margin: 0;
        max-width: 600px;
      }
      span{
        display: block;
        font-size: 1.5rem;
        width: 80px;
      }
      &__delete{
        background: none;
        border: none;
        cursor: pointer;
        img{
          width: 20px;
          height: 20px;
        }
      }
      &__type{
        font-size: 2rem !important;
        font-weight: bold;
      }
  }

  </style>