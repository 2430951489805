<template>
    <ModalVue :fields="[
      {
        name: 'message',
        tag: 'ta',
        req: true,
        placeholder: 'wiadomość'
      }
    ]" title="wyślij wiadomość" :is-visible="isMessageModalVisible" button-text="wyślij" @closeModal="() => {isMessageModalVisible=false}" @sendData="(data) => sendMessage(data)"/>
    <ModalVue :fields="[
        {
        name: 'rate',
        tag: 'select',
        options: [
            {
                name:'1',
                value: '1'
            },
            {
                name:'2',
                value: '2'
            },
            {
                name:'3',
                value: '3'
            },
            {
                name:'4',
                value: '4'
            },
            {
                name:'5',
                value: '5'
            },
            {
                name:'6',
                value: '6'
            },
            {
                name:'7',
                value: '7'
            },
            {
                name:'8',
                value: '8'
            },
            {
                name:'9',
                value: '9'
            },
            {
                name:'10',
                value: '10'
            },
        ],
        },
    ]" title="wystaw ocenę" :is-visible="isRateModalVisible" button-text="wystaw" @closeModal="() => {isRateModalVisible=false}" @sendData="(data) => sendRate(data)"/>
    <EmailPreviewModalVue @closeEmailModal="() => isEmailModalVisible = false" :email="user ? user.email : null" :isVisible="isEmailModalVisible"/>
  <div class="card-container" v-if="user">
    <div class="card-container__top-bar">
        <img class="card-container__top-bar__img" :src="userImage ? userImage : getImageUrl()" alt="">
        <div class="card-container__top-bar__center-line"></div>
        <div class="card-container__top-bar__inner">
            <div class="card-container__top-bar__inner__left">
                <h2>{{ user.name }}</h2>
                <h4>{{ user.firstname }} {{ user.lastname }}</h4>
                <span v-if="user.category">{{ user.category.name }}, <br><span v-if="user.role.id == 4">pracownik w {{ bizName }}</span></span>
                <span v-if="specFirmList && specFirmList.length > 0">Placówki: 
                    <span v-for="firm in specFirmList" :key="firm.id"><a :href="$router.resolve(`/browse/user/${firm.firm.id}`).href">{{ firm.firm.name }}</a>,&nbsp;&nbsp;</span>
                </span>
            </div>
            <div class="card-container__top-bar__inner__right" v-if="!(user.role.id == 4 || user.role.id == 5)">
                <ButtonVue text="Skontaktuj się" bg-color="#F2790F" color="#000" style="font-size: 1.7rem;" @click="() => openMessageModal()"/>
                <div class="card-container__top-bar__inner__right__rate-contact" v-if="reviews">
                    <img src="@/assets/icons/star-fill.svg" alt="" v-for="item in Math.round(reviews.average)" :key="item">
                    <span>{{ reviews.average }}/10</span>
                </div>
                <ButtonVue v-if="currentUser" text="Wystaw ocenę" bg-color="#F2790F" color="#000" style="font-size: 1.7rem;" @click="() => openRateModal()"/>
            </div>
        </div>
    </div>
    <div class="card-container__details">
        <div class="card-container__details__panel-left">
            <span v-if="user.address"><img src="@/assets/icons/geo-alt.svg" alt="">{{ user.address }}</span>
            <span v-if="user.phone"><img src="@/assets/icons/telephone.svg" alt="">{{ user.phone }} <br> {{ user.phone_2 }}</span>
            <span style="margin-bottom: 40px; cursor: pointer;" role="button" @click="() => isEmailModalVisible = true" v-if="user.email"><img src="@/assets/icons/envelope.svg" alt="">pokaż e-mail</span>
            <a target="blank" v-if="user.terms_and_conditions_url" :href="user.terms_and_conditions_url">regulamin</a>
            <a target="blank" v-if="user.privacy_policy_url" :href="user.privacy_policy_url">polityka prywatności</a>
        </div>
        <div class="card-container__top-bar__center-line"></div>
        <div class="card-container__details__panel-right">
            <p class="card-container__details__panel-right__short-desc" v-if="user.short_description">{{ user.short_description }}</p>
            <p class="card-container__details__panel-right__desc" v-if="user.description">{{ user.description }}</p>
            <div class="card-container__details__panel-right__serv-options" v-if="services">
                <button aria-label="widok siatki" :class="servicesView == 1 ? 'selected' : ''" @click="servicesView = 1">
                    <img src="@/assets/icons/grid.svg" alt="siatka">
                </button>
                <button aria-label="widok listy" :class="servicesView == 2 ? 'selected' : ''" @click="servicesView = 2">
                    <img src="@/assets/icons/list.svg" alt="lista">
                </button>
                <input type="text" name="searchInput" id="searchInput" placeholder="tytuł usługi" v-model="searchInput">
                <button @click="findService()">
                    <img src="@/assets/icons/search.svg">
                </button>
                <button @click="() => {
                    getUserServices();
                    searchInput = ''
                }">reset</button>
            </div>
            <h3 v-if="services" style="margin-bottom: 0;"><span v-if="services.length > 0">Wybierz usługę:</span></h3>
            <ServiceModal/>
            <div class="card-container__details__panel-right__services-grid" v-if="services && states.length > 0 && servicesView == 1">
                <ServiceCardVue v-for="service in services" @openServiceModal="() => $router.push({path: `/browse/user/${$route.params.userId}`, query: {serviceID: service.service.id}})" :price="service.service.price" :key="service.service.id" :img="service.service.photo_url" :is-advance="service.service.advanceAmount ? true : false" :name="service.service.name" :state="states.filter(state => state.value == service.service.state_id)[0].name" :ishighlighted="service.service.active"/>
            </div>
            <div class="card-container__details__panel-right__services-list" v-if="services && states.length > 0 && servicesView == 2">
                <div v-for="service in services" :key="service.service.id">
                    <div>
                        <span>{{ service.service.name }}</span>
                        <img v-if="service.service.advanceAmount" class="advance-icon" src="@/assets/icons/cash.svg" alt="usługa z zaliczką" >
                        <span>{{ service.service.price }} zł</span>
                    </div>
                    <button @click="() => $router.push({path: `/browse/user/${$route.params.userId}`, query: {serviceID: service.service.id}})">szczegóły</button>
                </div>
            </div>
            <h3 v-if="services"><span v-if="services.length == 0">Nie znaleziono usług</span></h3>
            <h3 v-if="firmSpecsList && firmSpecsList.length > 0">Specjaliści:</h3>
            <div class="card-container__details__panel-right__specs-grid" v-if="firmSpecsList && firmSpecsList.length > 0">
                <div class="spec-item" v-for="spec in firmSpecsList" :key="spec.id">
                    <img src="@/assets/photo-placeholder.png" alt="brak zdjęcia">
                    <a :href="$router.resolve(`/browse/user/${spec.business.id}`).href">{{ spec.business.firstname }} {{ spec.business.lastname }}</a>
                </div>
            </div>  
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useMeta } from 'vue-meta';
import ButtonVue from '@/components/Button.vue';
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import ModalVue from '@/components/Modal.vue';
import authHeader from '@/services/auth-header';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import EmailPreviewModalVue from '@/components/SpecialComponents/EmailPreviewModal.vue';

export default{
  name:'BusinessCardVue',
  setup () {
    useMeta({title: 'usługodawca'})
  },
  data(){
    return{
        user: null,
        userImage: null,
        reviews: null,
        services: null,
        states: [],
        isMessageModalVisible: false,
        isRateModalVisible: false,
        bizName: '',
        isEmailModalVisible: false,
        firmSpecsList: null,
        specFirmList: null,
        servicesView: 2,
        searchInput: ''
    }
  },
  props:{

  },
  mounted(){
    this.getUserData()
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  components:{ ButtonVue, ServiceCardVue, ModalVue, ServiceModal, EmailPreviewModalVue },
  methods:{
    getImageUrl() {
        return new URL(`../assets/photo-placeholder.png`, import.meta.url).href;
    },
    getUserServices(){
        axios.get(`/UserService/${this.$route.params.userId}`).then(respns => {
            this.services = respns.data
        })
    },
    getUserData(){
        axios.get(`/User/${this.$route.params.userId}`).then(res => {
            if(res.data.role.id == 3){
                this.$router.push('/')
            }else if(res.data.role.id == 2){
                this.user = res.data
                axios.get(`/UserImage/${res.data.id}`).then(resp => {
                    if(resp.data.length > 0){
                        this.userImage = resp.data[0].photo_url
                    }
                })

                axios.post('/ReviewUserSearch', {page:1, limit: 50, user_id: res.data.id}).then(respn => {
                    this.reviews = respn.data
                })

                axios.get(`/UserService/${res.data.id}`).then(respns => {
                    this.services = respns.data
                })

                axios.get(`/FrontendFirmBusinessSpecialistList/${this.$route.params.userId}`).then(respon => {
                    this.specFirmList = respon.data.items
                })

                axios.get('/State').then(res => {
                    res.data.forEach((item) => {
                        const ob = {
                            name: item.name,
                            value: `${item.id}`
                        }
                        this.states.push(ob)
                    })
                })
            }else if(res.data.role.id == 4){
                this.user = res.data

                axios.get(`/UserImage/${res.data.id}`).then(resp => {
                    if(resp.data.length > 0){
                        this.userImage = resp.data[0].photo_url
                    }
                })

                axios.post('/ReviewUserSearch', {page:1, limit: 50, user_id: res.data.id}).then(respn => {
                    this.reviews = respn.data
                })

                axios.get(`/UserService/${res.data.main_id}`).then(respns => {
                    this.services = respns.data.filter(service => service.service.employee_id == res.data.id)
                })

                axios.get(`/User/${res.data.main_id}`).then(res => {
                    this.bizName = res.data.name
                })

                axios.get('/State').then(res => {
                    res.data.forEach((item) => {
                        const ob = {
                            name: item.name,
                            value: `${item.id}`
                        }
                        this.states.push(ob)
                    })
                })
            }else if(res.data.role.id === 5){
                this.user = res.data
                axios.get(`/UserImage/${res.data.id}`).then(resp => {
                    if(resp.data.length > 0){
                        this.userImage = resp.data[0].photo_url
                    }
                })
                axios.get(`/FrontendFirmBusinessList/${this.$route.params.userId}`).then(respon => {
                    this.firmSpecsList = respon.data.items
                })
            }
        })
    },
    openMessageModal(){
        if(this.currentUser){
            if(this.currentUser.data.user_id !== 3){
                this.isMessageModalVisible = true
            }else{
                this.$store.commit('setIsAlert', 'jesteś usługodawcą i nie możesz wysyłać wiadomości')
            }
        }else{
            this.$store.commit('setIsAlert', 'musisz być zalogowany/a')
        }
    },
    openRateModal(){
        if(this.currentUser){
            if(this.currentUser.data.user_id !== 3){
                this.isRateModalVisible = true
            }else{
                this.$store.commit('setIsAlert', 'jesteś usługodawcą i nie możesz wystawiać ocen')
            }
        }else{
            this.$store.commit('setIsAlert', 'musisz być zalogowany/a')
        }
    },
    sendMessage(data){
        if(data){
            const ob = {
                title: this.currentUser.data.name,
                content: data.message,
                from_id: this.currentUser.data.user_id,
                to_id: this.$route.params.userId
            }
            axios.post('/Message', ob, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.$store.commit('setIsSuccess', 'Wysłano wiadomość')
                    }
                })
        }
    },
    sendRate(data){
        const ob = {
            name: this.currentUser.data.name,
            content: `ocena ${data.rate}`,
            rating: data.rate,
            from_user_id: this.currentUser.data.user_id,
            user_id: this.$route.params.userId
        }
        axios.post(`/ReviewUser`, ob, authHeader())
            .then(res => {
                if(String(res.status).charAt(0) === '2'){
                    this.$store.commit('setIsSuccess', 'Pomyślnie wystawiono ocenę')
                }
            })
    },
    findService(){
        if(this.searchInput && this.searchInput.length > 2){
            this.services = this.services.filter(service => service.service.name.includes(this.searchInput))
        }else{
            this.$store.commit('setIsAlert', 'Wpisz tytuł szukanej usługi (co najmniej 3 litery)') 
        }
    }
  }
}
</script>

<style scoped lang='scss'>
.advance-icon{
    width: 25px;
    object-fit: contain;
    height: 20px;
}
.card-container{
    &__top-bar{
        display: flex;
        justify-content: space-between;
        border-bottom: 2px dashed #B1B1B1;
        &__img{
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        &__inner{
            display: flex;
            justify-content: space-between;
            width: calc(100% - 240px);
            align-items: center;
            &__left{
                h2{
                    font-size: 3.5rem;
                    margin: 10px 0;
                }
            }
            &__right{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: flex-end;
            }
        }
        &__center-line{
            width: 2px;
            background-color: #B1B1B1;
        }
    }
    &__details{
        display: flex;
        justify-content: space-between;
        &__panel-left{
            width: 200px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            max-height: 460px;
            padding-top: 20px;
            span{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 15px;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
            a{
                text-align: center;
                font-size: 1.7rem;
            }
        }
        &__panel-right{
            width: calc(100% - 240px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 20px;
            &__serv-options{
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
                button{
                    background-color: #B1B1B1;
                    padding: 0 15px;
                    border-radius: 8px;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 20px;
                    }
                }
                button.selected{
                    background-color: #636363;
                }
                input{
                    border: 1px solid #B1B1B1;
                    border-radius: 8px;
                    padding: 10px;
                }
            }
            &__short-desc{
                margin: 0;
                font-size: 2.5rem;
            }
            &__desc{
                margin: 0;
            }
            &__services-grid{
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 20px;
                // margin-top: 20px;
            }
            &__services-list > div{
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                border-bottom: 1px solid #F2790F;
                div{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
                button{
                    background: none;
                    border: none;
                    color: #5EA01A;
                    cursor: pointer;
                }
            }
            &__specs-grid{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                .spec-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    border: 2px solid #F2790F;
                    border-radius: 15px;
                    padding: 10px 30px;
                    img{
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                    }
                    button{
                        background-color: #DC3545;
                        border-radius: 8px;
                        cursor: pointer;
                        border: none;
                        padding: 5px 10px;
                        align-self: stretch;
                    }
                }
            }
        }
        &__center-line{
            width: 2px;
            background-color: #B1B1B1;
        }
    }
}

@media (max-width: 700px){
    .card-container{
        &__top-bar{
            flex-direction: column;
            // border: none;
            padding-bottom: 20px;
            &__center-line{
                display: none;
            }
            &__inner{
                flex-wrap: wrap;
                width: 100%;
            }
        }
        &__details{
            flex-direction: column;
            margin-top: 20px;
            &__panel-left{
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
            }
            &__panel-right{
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
</style>