<template>
    <div v-if="currentUser" class="service-verifying">
        <h2>weryfikowanie usług</h2>
        <div class="service-search">
            <input type="text" placeholder="szukaj" v-model="searchInput">
            <button @click="() => searchService()">Szukaj</button>
            <button @click="() => resetSearch()" class="btn-green">resetuj</button>
            <div class="service-search__pages">
              <span>strona:</span>
              <button @click="() => {
                if(page > 1){
                  page -= 1
                  searchService()
                }
              }"><img src="@/assets/message-arrow.png" alt=""></button>
              <span>{{ page }}/{{ Math.ceil(servicesCount/100) }}</span>
              <button @click="() => {
                if(page < Math.ceil(servicesCount/100)){
                  page += 1
                  searchService()
                }
              }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
            </div>
        </div>
        <div class="services-grid">
            <ServiceModal/>
            <div v-if="services && states.length > 0 && categories.length > 0">
                <ServiceCardVue @deleteService="(id, userServiceId) => deleteService(id, userServiceId)" @verify="() => verifyService(item.id)" @openServiceModal="() => $router.push({path: '/admin-profile/service-verify', query: {serviceID: item.id}})" :admin="true" :price="item.price" :is-advance="item.advanceAmount ? true : false" :img="item.photo_url" :id="item.id" :name="item.name" :state="states.filter(state => state.value == item.state_id)[0].name" v-for="item in services.slice(null, 50)" :key="item.id" :ishighlighted="item.active" :category="categories.filter(category => category.id == item.user.category_id)[0].name" :userServiceId="item.user.id"/>
                <div v-if="services.length == 0">
                    <h2>Nie znaleziono usług</h2>
                </div>
            </div>
            <div v-else>
                <h2>Ładowanie...</h2>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import authHeader from '@/services/auth-header';
import axios from 'axios';
export default{
    name:"ServiceVerifying",
    data(){
        return{
            services: null,
            servicePopup: {
                visibility: false,
                serviceId: 0
            },
            searchInput: '',
            states: [],
            categories: [],
            page: 1,
            servicesCount: 0
        }
    },
    components:{ ServiceCardVue, ServiceModal },
    props:['isVisible'],
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    mounted(){
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
        this.getStates()
        this.getServices()
        this.getCategories()
    },
    methods:{
        getServices(){
            axios.post(`/Services`, {page: this.page, limit: 100})
                .then(res => {
                    this.services = res.data.items
                    this.servicesCount = res.data.count
                })

        },
        closePopup(value){
            if (value) {
                this.servicePopup.visibility = false
            }
        },
        showService(value){
            if(value){
                this.servicePopup.serviceId = value[1]
                this.servicePopup.visibility = value[0]
            }
        },
        verifyService(value){
            axios.get(`/ServiceEnableByAdmin/${value}`, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.getServices()
                    }
                })
        },
        searchService(){
            // this.services = this.services.filter(item => item.name.toLowerCase().includes(this.searchInput.toLowerCase()))
            axios.post(`/Services`, {page: this.page, limit: 100, name: this.searchInput})
                .then(res => {
                    this.services = res.data.items
                    this.servicesCount = res.data.count
                })
        },
        resetSearch(){
            this.searchInput = ''
            this.page = 1
            this.getServices()
        },
        getStates(){
        axios.get('/State').then(res => {
            res.data.forEach((item) => {
                const ob = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.states.push(ob)
            })
        })
    },
    getCategories(){
      axios.get('/Categories').then(res => {
        res.data.forEach((item) => {
            const ob  = {
                name: item.name,
                id: `${item.id}`
            }
            this.categories.push(ob)
        })
      })
    },
    deleteService(id, userServiceId){
        axios.get(`/UserService/${userServiceId}`).then(res => {
            axios.delete(`/Service/${id}`, authHeader()).then(() => {
                axios.delete(`/UserService/${res.data.filter(serv => serv.service.id == id)[0].id}`, authHeader()).then(() => {
                    this.$store.commit('setIsSuccess', 'Pomyślnie usunięto usługę')
                    this.getServices()
                })
            }).catch(() => {
                
            })
        }).catch(() => {
            
        })
    }
    }  
}
</script>

<style lang="scss" scoped>
.service-verifying{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.service-search{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.service-search > input{
    padding: 7px;
    border-radius: 10px;
    border: 2px solid #8b8b8b;
    width: 250px;
}
.service-search > button{
    border: none;
    background-color: #f2790f;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
}
.services-grid{
    width: 100%;
}
.services-grid > div{
    width: 90%;
    max-width: 1000px;
    margin: 40px auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 30px 10px;
}
.service-search > .btn-green{
    background-color: #5ea01a !important;
}

.service-search{
    &__pages{
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 10px;
    button{
      background-color: #cbcbcb;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      img{
        width: 15px;
      }
      img.rotate{
        transform: rotate(180deg);
      }
    }
  }
}
</style>