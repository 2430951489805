<template>
    <div v-if="currentUser" class="footer-edit-cont">
        <h2>Edycja stopki</h2>
        <div class="footer-edit-cols">
            <div class="footer-col">
                <h3>Copyright</h3>
                <img :src="firstCol.firstColData.logo_url" alt="M2Vet" class="footer-col-logo-img">
                <p v-html="firstCol.firstColData.text"></p>
                <b v-html="firstCol.firstColData.copyright"></b>
                <button @click="currentEditCol = 1" class="submit-btn">Edytuj kolumnę</button>
                <div class="footer-col-1-edit-cont" v-if="currentEditCol == 1">
                    <button @click="currentEditCol = 0" class="close-edit-btn"><img src="@/assets/icons/x.svg"></button>
                    <h3>Edytuj 1 kolumnę</h3>
                    <form class="footer-col-1-edit-form" @submit="(e) => uploadCol1Img(e)">
                        <label for="firstColText">Tekst</label>
                        <textarea name="firstColText" id="firstColText" cols="40" rows="10" ref="col1Text" class="first-col-text" v-model="firstCol.firstColData.text" required></textarea>
                            
                        <label for="firstColCopy">Copyright</label>
                        <input type="text" name="firstColCopy" id="firstColCopy" class="first-col-copy" ref="colCopy" v-model="firstCol.firstColData.copyright" required>

                        <label for="firstColLogo">Logo</label>
                        <input type="file" accept="image/*" name="firstColLogo" id="firstColLogo" ref="col1Img" v-on:change="selectCol1Image" class="first-col-logo" required>
                    
                        <button type="submit" class="submit-btn">Zatwierdź</button>
                    </form>
                </div>
            </div>
            <div class="footer-col">
                <h3>Media społecznościowe</h3>
                <div class="footer-col-2-icons-cont">
                    <div class="footer-col-2-item" v-for="item in secondCol.secondColData" :key="item.id">
                        <a :href="item.url">
                            <img :src="item.icon_url" :alt="item.name">
                            <span>{{ item.name }}</span>
                        </a>
                        <div>
                            <!-- <button class="edit-social-btn"><img src="@/assets/icons/pencil-square.svg" alt="" @click="() => setupSecColEdit(item)"></button> -->
                            <button class="edit-social-btn"><img src="@/assets/icons/trash.svg" alt="" @click="() => removeSocial(item.id)"></button>
                        </div>
                    </div>
                </div>
                <button @click="currentEditCol = 2" class="submit-btn">Dodaj element</button>
                <div class="footer-col-2-edit-cont" v-if="currentEditCol == 2">
                    <button @click="() => {
                        currentEditCol = 0
                        this.secondCol.newElementName = ''
                        this.secondCol.newElementUrl = ''
                        this.$refs.socialIcon.value = ''
                    }" class="close-edit-btn"><img src="@/assets/icons/x.svg"></button>
                    <form @submit="(e) => sendCol2Data(e, null)">
                        <label for="socialName">nazwa</label>
                        <input type="text" name="socialName" id="socialName" v-model="secondCol.newElementName" required>
    
                        <label for="socialUrl">adres url</label>
                        <input type="text" name="socialUrl" id="socialUrl" v-model="secondCol.newElementUrl" required>
    
                        <label for="socialIcon">ikona w formacie png albo jpg</label>
                        <input type="file" accept="image/*" name="socialIcon" id="socialIcon" @change="selectCol2Image" ref="socialIcon" required>

                        <button type="submit" class="submit-btn">Zatwierdź</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header.js'
export default{
    name:"FooterEdit",
    data(){
        return{
            currentEditCol: 0,
            firstCol:{
                firstColData: {
                    text: '',
                    copyright: ''
                },
                image: null
            },
            secondCol:{
                secondColData:{

                },
                newElementName: '',
                newElementUrl:'',
                newElementImg: null
            },
            thirdCol:{
                cols:[],
                newColName:''
            }
        }
    },
    props:['isVisible'],
    mounted(){
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
        this.getFirstCol()
        this.getSecondCol()
        // this.getThirdColData()
    },
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    methods:{
        getFirstCol(){
            axios.get(`/FooterSettingsGet`)
                .then((res) => {
                    this.firstCol.firstColData = res.data
                })
        },
        getSecondCol(){
            const ob = {
                page:0,
                limit: 50
            }
            axios.post(`/SocialIconSearch`, ob)
                .then(res => {
                    this.secondCol.secondColData = res.data.items
                })
        },
        selectCol1Image(){
            this.firstCol.image = this.$refs.col1Img.files.item(0);
        },
        selectCol2Image(){
            this.secondCol.newElementImg = this.$refs.socialIcon.files.item(0)
        },
        uploadCol1Img(e){
            e.preventDefault()
            if(this.firstCol.image && this.firstCol.firstColData.text && this.firstCol.firstColData.copyright){
                let formData = new FormData()
                const config = { 
                    headers: { 
                        'Authorization': `${authHeader().headers.Authorization}`,
                        'Content-Type': 'multipart/form-data'
                    } 
                }
                formData.append('image', this.firstCol.image)
                const ob = {
                    text: this.firstCol.firstColData.text,
                    copyright: this.firstCol.firstColData.copyright,
                    logo: formData.get('image')
                }
                axios.post(`/FooterSettings`, ob, config)
                    .then(() => {
                        this.currentEditCol = 0
                    })
            }
        },
        sendCol2Data(e, editId){
            e.preventDefault()
            let formData = new FormData()
            const config = {
                headers: { 
                    'Authorization': `${authHeader().headers.Authorization}`,
                    'Content-Type': 'multipart/form-data'
                } 
            }
            formData.append('icon', this.secondCol.newElementImg)
            const ob = {
                name: this.secondCol.newElementName,
                url: this.secondCol.newElementUrl,
                icon: formData.get('icon')
            }
            if(editId){
                ob.id = editId
            }
            axios.post(`/SocialIcon`, ob, config)
                .then(() => {
                    this.getSecondCol()
                    this.secondCol.newElementName = ''
                    this.secondCol.newElementUrl = ''
                    this.$refs.socialIcon.value = ''
                    this.currentEditCol = 0
                })
        },
        removeSocial(id){
            axios.delete(`/SocialIcon/${id}`, authHeader())
                .then()
            this.secondCol.secondColData.forEach(x => {
                if(x.id == id){
                    this.secondCol.secondColData.push(this.secondCol.secondColData.splice(this.secondCol.secondColData.indexOf(x), 1)[0]);
                }
            })
            this.secondCol.secondColData.pop()
        },
        setupSecColEdit(item){
            this.currentEditCol = 2
            this.secondCol.newElementName = item.name
            this.secondCol.newElementUrl = item.url
            this.secondCol.newElementImg = fetch(`http://${item.icon_url}`).then(x => x.this.secondCol.newElementImg());
        }
    }    
}
</script>

<style scoped>
.footer-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-edit-cols{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.footer-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 270px;
    max-width: 350px;
    margin: 30px 10px;
}
.third-footer-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}
.footer-col-1-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    border-top: 2px solid #000;
    padding-top: 10px;
}
.close-edit-btn{
    background: none;
    border: none;
    cursor: pointer;
}
.first-col-text{
    resize: vertical;
    max-height: 200px;
    min-height: 150px;
}
.footer-col-1-edit-form{
    display: flex;
    flex-direction: column;
}
.footer-col-1-edit-form > label{
    font-size: 1.7rem;
    margin-top: 10px;
}
.footer-col-2-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-col-2-edit-cont > form > label{
    font-size: 1.7rem;
    margin-top: 10px;
}
.footer-col-2-edit-cont > form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-col-2-item > a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 1.7rem;
    margin: 10px;
}
.footer-col-2-item > a > img{
    width: 30px;
}
.footer-col-2-item > div{
    display: flex;
    justify-content: center;
}
.footer-col-2-icons-cont{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.submit-btn{
    margin-top: 10px;
}
.edit-social-btn{
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}
.third-footer-col-cols{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.third-footer-col-cols-col{
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.third-footer-col-cols-col > form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.third-footer-col-cols-col > form > input{
    margin: 3px 0;
}
.third-footer-col-add-col{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.third-footer-col-add-col > button{
    margin-top: 10px;
}
.t-f-c-col-header-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-f-c-col-header-cont > button{
    background: none;
    border: none;
    cursor: pointer;
}
.footer-col-logo-img{
    width: 200px;
}
</style>