<template>
    <h2>Zalecenia</h2>
    <button class="new-recomm-btn" @click="() => $router.push('/dla-biznesu/profile/recommendations/add')">Stwórz nowe <img src="@/assets/icons/plus.svg" alt=""></button>
    <div v-if="currentUser" class="recomms">
        <div class="recomms__list">
          <div class="recomms__list__search">
            <input type="text" v-model="searchInput" id="searchInput" placeholder="Szukaj">
            <button @click="searchPet"><img src="@/assets/icons/search.svg" alt="szukaj"></button>
            <button @click="() => {
              getRecommendations()
              searchInput = ''
            }"><img src="@/assets/icons/arrow-clockwise.svg" alt="resetuj"></button>
          </div>
          <div class="recomms__list__list">
            <div v-for="pet in pets" :key="JSON.parse(pet).pet_id" class="recomms__list__list__item">
              <div>
                <b>{{ JSON.parse(pet).name.split(',')[0] }}</b>
                <span>{{ JSON.parse(pet).name.split(',')[1] }}</span>
              </div>
              <button @click="setCurrentPet(JSON.parse(pet).pet_id)"><img src="@/assets/message-arrow.png" alt="szczegóły zalecenia"></button>
            </div>
          </div>
        </div>
        <div class="recomms__pet" v-if="currentPet">
          <div class="recomms__pet__header">
            <img :src="currentPet.photo_url" :alt="currentPet.pet_name">
            <h3>{{ currentPet.pet_name }}</h3>
            <button @click="() => currentPet = null"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
          </div>
          <div class="recomms__pet__recomms">
            <div class="recomms__pet__recomms__recomm" v-for="recomm in currentPet.recommendations" :key="recomm.id">
              <div class="recomms__pet__recomms__recomm__content">
                <span>{{ recomm.content.slice(recomm.content.indexOf('|')+1).length > 50 ? `${recomm.content.slice(recomm.content.indexOf('|')+1).slice(0, 50)}...` : recomm.content.slice(recomm.content.indexOf('|')+1) }}</span>
                <p v-if="recomm.isExpanded && recomm.content.slice(recomm.content.indexOf('|')+1).length > 50">{{ recomm.content.slice(recomm.content.indexOf('|')+1).replace(/\n/g, '<br>') }}</p>
                <a :href="recomm.photo_url" target="_blank" v-if="recomm.isExpanded && recomm.photo_url">zdjęcie 1</a>
                <a :href="recomm.second_photo_url" target="_blank" v-if="recomm.isExpanded && recomm.second_photo_url">zdjęcie 2</a>
              </div>
              <button class="recomms__pet__recomms__recomm__expand" @click="() => recomm.isExpanded = !recomm.isExpanded"><img src="@/assets/message-arrow.png" :style="{'transform': `rotate(${recomm.isExpanded ? '90deg' : '-90deg'})`}" alt="rozwiń"></button>
            </div>
          </div>
        </div>
        <div v-else class="no-selected-pet">
          <span>Wybierz zalecenie</span>
        </div>
    </div>
    <div v-else>
      <h4 class="no-services-info">Brak zaleceń</h4>
    </div>
  </template>
  
  <script>
  import authHeader from '@/services/auth-header';
import axios from 'axios';
import { useMeta } from 'vue-meta';
  export default{
    name:'BizProfileRecommendations',
    setup () {
      useMeta({title: 'Zalecenia'})
    },
    data(){
      return{
        searchInput: '',
        recommendations:[],
        pets: [],
        recommsCount: null,
        currentPet: null
      }
    },
    props:{
  
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    components:{
      
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 2) {
        this.$router.push('/login');
      }
      if(this.$store.state.isSubscribed){
        this.getRecommendations()
      }else{
        this.$router.push('/dla-biznesu/profile/user-data')
      }
    },
    methods:{
      getRecommendations(){
        axios.post(`/RecommendationSearch`, {
          page: 1,
          limit: 1,
          user_id: this.currentUser.data.user_id
        }, authHeader()).then((res) => {
          this.recommsCount = res.data.count
          axios.post(`/RecommendationSearch`, {
            page: Math.floor(res.data.count/50),
            limit: 50,
            user_id: this.currentUser.data.user_id
          }, authHeader()).then((resp) => {
            // this.recommendations = resp.data.items
            resp.data.items.forEach(item => {
              this.pets.push(JSON.stringify({name: item.content.split('|')[0], pet_id: item.pet_id}))
            })
          }).then(() => {
            const petsTemp = [...new Set(this.pets)]
            this.pets = petsTemp
          }).catch(() => {
            
          })
        }).catch(() => {
          
        })
      },
      setCurrentPet(id){
        axios.get(`/Pet/${id}`).then(res => {
          this.currentPet = res.data[0]
          this.currentPet.recommendations.map(x => {
            x.isExpanded = false
          })
        }).catch(() => {
          
        })
      },
      searchPet(){
        axios.post(`/RecommendationSearch`, {
          page: 1,
          limit: this.recommsCount,
          user_id: this.currentUser.data.user_id
        }, authHeader()).then(res => {
          res.data.items.forEach(item => {
            this.pets.push(JSON.stringify({name: item.content.split('|')[0], pet_id: item.pet_id}))
          })
        }).then(() => {
          const petsTemp = [...new Set(this.pets)]
          this.pets = petsTemp
        }).then(() => {
          this.pets = this.pets.filter(item => JSON.parse(item).name.toLowerCase().includes(this.searchInput.toLowerCase()))
        }).catch(() => {
          
        })
      }
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .recomms{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__list{
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      max-width: 350px;
      width: 30%;
      &__search{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        input{
          padding: 5px 10px;
          border-radius: 8px;
          border: 1px solid #9b9b9b;
        }
        button{
          border: none;
          background: #dbdbdb;
          padding: 6px 10px;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      &__list{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        padding: 10px;
        height: 400px;
        overflow-y: scroll;
        &__item{
          display: flex;
          gap: 40px;
          border-left: 3px solid #F27A07;
          padding-left: 8px;
          div{
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          button{
            background: none;
            border: none;
            cursor: pointer;
            img{
              width: 30px;
              height: 30px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &__pet{
      display: flex;
      flex-direction: column;
      max-width: 800px;
      width: 60%;
      min-width: 290px;
      gap: 20px;
      margin: 0 20px;
      &__header{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        img{
          width: 130px;
          height: 130px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
        button{
          background: none;
          margin-left: auto;
          border: none;
          cursor: pointer;
          img{
            width: 50px;
            height: 50px;
          }
        }
      }
      &__recomms{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        &__recomm{
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0px 0px 11px 0px rgba(156, 156, 156, 1);
          button{
            border: none;
            background: none;
            cursor: pointer;
            img{
              width: 20px;
            }
          }
        }
      }
    }
  }
  .new-recomm-btn{
    background-color: #F27A07;
    padding: 7px 20px;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    img{
      width: 30px;
      height: 30px;
    }
  }

  .no-selected-pet{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 800px;
    width: 60%;
    min-width: 290px;
    gap: 20px;
    margin: 0 20px;
  }

  @media(max-width: 890px){
    .recomms{
      &__list{
        width: 100%;
        max-width: none;
        &__list{
          &__item{
            justify-content: space-between;
          }
        }
      }
      &__pet{
        width: 100%;
        margin: 0;
      }
    }
  }
  </style>