<template>
  <div class="buy-plan-wrapper" v-if="this.$route.query.subId">
    {{ getPlan() }}
    {{ getEmployeesCount() }}
    <div class="buy-plan__header">
        <h3>Wykup abonament</h3>
        <button @click="() => {$router.push($route.path); plan = {}; showSummary = false}"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
    </div>
    <div class="buy-plan__body--buy" v-if="!showSummary">
        <span class="label">Wybrany plan</span>
        <span>{{ plan.name }}</span>
        <span class="label">Ilość pracowników w planie</span>
        <input type="number" min="0" v-model="users" placeholder="Podaj liczbę">
        <button class="cta" @click="() => {users >= 0 ? showSummary = true : $store.commit('setIsAlert', 'podaj odpowiednią liczbę pracowników')}">Podsumowanie</button>
    </div>
    <div class="buy-plan__body--summary" v-if="showSummary">
        <span><span>opłata abonamentowa</span><span>{{ plan.subscriptionM }} zł * {{ plan.period }} mies. = {{ plan.subscriptionM*plan.period }} zł</span></span>
        <span><span>opłata za pracownika</span><span>{{ plan.additionalUserM }} zł * {{ users }} = {{ plan.additionalUserM*users }} zł</span></span>
        <span><span>ilość pracowników w sumie</span><span>{{users}} + {{ plan.freeUsers }} darmowych = {{ users+plan.freeUsers }}</span></span>
        <span><span>okres trwania</span><span>{{ plan.period }} mies.</span></span>
        <b>suma: {{ sum }} zł</b>
        <button class="cta" @click="pay()">Zapłać</button>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header'
import axios from 'axios'

export default{
  name:'BuyPlanModal',
  data(){
    return{
        users: 0,
        plan: {},
        showSummary: false,
        sum: 0,
        employeesCount: null
    }
  },
  props:['plans', 'userEmail'],
  computed: {
    currentUser() {
          return this.$store.state.auth.user;
      }
  },
  mounted(){
    this.getEmployeesCount()
  },
  methods:{
    getEmployeesCount(){
      if(!this.employeesCount){
        axios.post(`/Users`, {main_id: this.currentUser.data.user_id, page: 1, limit: 1}, authHeader())
        .then(res => {
            this.employeesCount = res.data.count
        })
      }
    },
    getPlan(){
      if(this.plan != {} && this.plans.length > 0){
        if(this.$route.query.subId){
            this.plan = this.plans.find(plan => plan.id == this.$route.query.subId)
            this.sum = (this.plan.subscriptionM*this.plan.period)+(this.plan.additionalUserM*this.users)
        }
      }
    },
    pay(){
      if(this.users+this.plan.freeUsers >= this.employeesCount){
        const ob = {
          payment_name: `Opłać plan: ${this.plan.name}`,
          subscription_id: this.plan.id,
          users: this.users,
          buyer_email: this.userEmail,
          only_update: false
        }
        axios.post('/GetPaymentUrl', ob, authHeader()).then(res => {
          window.location.replace(res.data.payment_url)
        })
      }else if(this.users+this.plan.freeUsers < this.employeesCount){
        this.$store.commit('setIsAlert', 'Nie możesz wykupić mnijeszej ilości pracowników niż już masz');
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.buy-plan-wrapper{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #B1B1B1;
  z-index: 100;
  overflow: hidden;
  button.cta{
    background-color: #F2790F;
    padding: 5px 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  .buy-plan{
    &__header{
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      border-bottom: 1px solid #B1B1B1;
      padding: 0 20px;
      button{
        border: none;
        background: none;
        cursor: pointer;
        img{
          width: 30px;
        }
      }
    }
    &__body--buy{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin: 30px 0;
      input{
        padding: 8px;
        border: 1px solid #B1B1B1;
        border-radius: 8px;
        width: 100px;
      }
      span.label{
        font-size: 1.7rem;
      }
    }
    &__body--summary{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      margin: 30px 20px;
    }
    &__body--summary > span{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      border-bottom: 1px solid #000;
      padding-bottom: 2px;
      width: 100%;
    }
  }

}
</style>