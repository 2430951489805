<template>
    <h1 class="login-header">Zresetuj swoje hasło</h1>
    <div class="login-form-wrapper">
      <div class="login-form-wrapper__image-aside">
        <img src="@/assets/pies.png" alt="">
      </div>
      <div class="login-form-container">
          <Form @submit="handleReset" :validation-schema="resetSchema">
            <div class="form-group">
              <label for="email">twój login/e-mail</label>
              <Field name="email" type="text" class="form-control" autocomplete="email"/>
              <ErrorMessage name="email" class="error-feedback" />
            </div>
    
            <div class="form-group">
              <button :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border"
                ></span>
                <span>Wyślij e-mail z kodem</span>
              </button>
            </div>
    
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
          </Form>
      </div>
      <div class="login-form-wrapper__image-aside"></div>
    </div>
  </template>
  
  <script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from 'yup';
  import { useMeta } from 'vue-meta';
import axios from "axios";
//   import { RouterLink } from "vue-router";

  export default {
    name: "ResetPass",
    setup () {
        useMeta({
          title: 'Resetuj hasło',
          // meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
        })
    },
    components: {
      Form,
      Field,
      ErrorMessage,
    //   RouterLink
    },
    data() {
      const resetSchema = yup.object().shape({
        email: yup.string().required("login/e-mail jest wymagany!"),
      });
  
      return {
        loading: false,
        message: "",
        resetSchema,
        showPass: false
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    created() {
        if (this.loggedIn) {
          let user = JSON.parse(localStorage.getItem('userm2VET'));
            if(user.data.role == 3){
                this.$router.push(`/profile`);
            }else if(user.data.role == 2){
                this.$router.push(`/dla-biznesu/profile`);
            }else if(user.data.role == 4){
              this.$router.push(`/dla-biznesu/employee-profile`)
            }else if(user.data.role == 1){
              this.$router.push(`/admin-profile`)
            }
      }
    },
    methods: {
        handleReset(user) {
          this.loading = true;
        axios.post('/RemindPassword', user).then(res => {
            if(String(res.status).charAt(0) == '2'){
                this.loading = false
                this.$store.commit('setResetPassEmail', user.email)
                setTimeout(() => {
                    this.$router.push('/login/reset2')
                },500)
            }
        }).catch(() => {
            this.$store.commit('setIsAlert', 'Nieprawidłowy adres e-mail')
            this.loading = false
        })
      },
    },
  };
  </script>
  
  <style lang="scss">
    .login-header{
        text-align: center;
        font-size: 3.5rem;
    }
    .login-form-wrapper{
      display: flex;
      justify-content: center;
      gap: 80px;
      &__image-aside{
        width: 250px;
        img{
          width: 100%;
        }
      }
    }
    .login-form-container{
        display: flex;
        align-items: center;
        justify-content: center;
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            .form-group{
                display: flex;
                flex-direction: column;
                input{
                    padding: 5px;
                    border-radius: 8px;
                    border: 1px solid #969696;
                }
                button{
                    background-color: #F2790F;
                    padding: 8px 15px;
                    border-radius: 8px;
                    border: none;
                }
                span{
                    font-size: 1.6rem;
                }
                span.show-hide-pass{
                  text-decoration: underline;
                  cursor: pointer;
                }
                a{
                  font-size: 1.6rem;
                  color: #000;
                }
                a.reset-pass{
                  margin-top: 10px;
                  text-decoration: none;
                }
            }
        }
    }

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  margin-right: 10px;
}

@media(max-width: 900px){
  .login-form-wrapper{
    &__image-aside{
      display: none;
    }
  }
}
</style>