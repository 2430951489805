<template>
    <div class="blog-edit-cont" v-if="currentUser">
        <h2>Blog</h2>
        <div class="preview-popup" v-if="entries.showPopup">
            <button><img src="@/assets/icons/x.svg" alt="X" @click="() => entries.showPopup = false"></button>
            <div v-html="entries.newEntryContent"></div>
        </div>
        <div class="blog-edit">
            <div class="blog-edit-entries">
                <div class="blog-edit-entries-list">
                    <h3>Lista wpisów</h3>
                    <div>
                        <input type="text" placeholder="wyszukaj wpisy" v-model="searchEntryInput">
                        <button @click="searchEntries">Szukaj</button>
                        <button @click="() => getEntriesData('0')">Resetuj</button>
                    </div>
                    <div class="entry-item" v-for="entry in entries.entries" :key="entry.id">
                        <div class="entry-action-btn-cont">
                            <span style="margin-right: auto; font-size: 1.5rem;" v-if="!entries.editModeList[`id${entry.id}`]">kategoria: {{ entry.category.title }} (id: {{ entry.category.id }})</span>
                            <select v-if="entries.editModeList[`id${entry.id}`]" :value="entry.category.id" style="margin-right: auto; font-size: 1.5rem;" :ref="`${entry.id}newEditCategory`">
                                <option>Kategoria</option>
                                <option :value="cat.id" v-for="cat in categories.categoriesList" :key="cat.id">{{ cat.title }}</option>
                            </select>

                            <button v-if="entries.editModeList[`id${entry.id}`]" @click="() => editEntry(entry.id)">Zatwierdź poprawki</button>
                            <button><img src="@/assets/icons/pencil-square.svg" alt="edytuj" @click="() => {
                                entries.editModeList[`id${entry.id}`] = !entries.editModeList[`id${entry.id}`]
                                entries.visibleList[`id${entry.id}`] = true
                                }"></button>
                            <button><img src="@/assets/icons/trash.svg" alt="usuń" @click="() => deleteEntry(entry.id)"></button>
                        </div>
        
                        <b v-if="!entries.editModeList[`id${entry.id}`]">{{ entry.title }}</b>
                        <input type="text" class="edit-input" :value="entry.title" v-if="entries.editModeList[`id${entry.id}`]" :ref="`${entry.id}newEditTitle`" placeholder="tytuł">

                        <i style="margin-top: 20px;" v-if="!entries.editModeList[`id${entry.id}`]">{{ entry.target }}</i>
                        <!-- <input type="text" class="edit-input" :value="entry.target" v-if="entries.editModeList[`id${entry.id}`]" :ref="`${entry.id}newEditTarget`" placeholder="odbiorcy"> -->
                        <select v-if="entries.editModeList[`id${entry.id}`]" v-model="entry.target">
                            <option>do wszystkich</option>
                            <option>do specjalistów</option>
                            <option>do właścicieli zwierząt</option>
                        </select>

                        <p v-if="!entries.editModeList[`id${entry.id}`]">{{ entry.short_description }}</p>
                        <textarea :value="entry.short_description" v-if="entries.editModeList[`id${entry.id}`]" :ref="`${entry.id}newEditShortDesc`" placeholder="krótki opis"></textarea>

                        <button style="margin-bottom: 20px;" class="entry-item-hidden-data-toggle-btn" @click="() => {
                            entries.visibleList[`id${entry.id}`] = !entries.visibleList[`id${entry.id}`]
                        }">
                        {{ entries.visibleList[`id${entry.id}`] ? 'mniej' : 'więcej...' }}
                        </button>
                        <div class="entry-item-hidden-data" v-if="entries.visibleList[`id${entry.id}`]">
                            <p v-html="entry.description" v-if="!entries.editModeList[`id${entry.id}`]"></p>
                            <!-- <textarea v-if="entries.editModeList[`id${entry.id}`]" :value="entry.description" :ref="`${entry.id}newEditContent`" placeholder="treść"></textarea> -->
                            <ckeditor v-if="entries.editModeList[`id${entry.id}`]" :editor="editor" v-model="entry.description" :config="editorConfig"></ckeditor>

                            <span v-if="!entries.editModeList[`id${entry.id}`]">{{ entry.author }}</span>
                            <input style="margin-top: 20px;" type="text" :value="entry.author" v-if="entries.editModeList[`id${entry.id}`]" :ref="`${entry.id}newEditAuthor`" placeholder="autor">                            

                            <img :src="entry.photo_url" :alt="entry.title" v-if="!entries.editModeList[`id${entry.id}`]">
                            <div v-if="entries.editModeList[`id${entry.id}`]">
                                <label for="newEditFile">Załaduj nowe zdjęcie!</label>
                                <input type="file" accept="image/*" name="newEditFile" :ref="`${entry.id}newEditFile`" id="newEditFile" @change="() => sendEntryEditPhoto(entry.id)">
                            </div>
                        </div>
                    </div>
                    <div class="entries-pagination">
                        <button @click="() => getEntriesData('-1')">Poprzednia strona</button>
                        <button @click="() => getEntriesData('0')">Początek</button>
                        <button @click="() => getEntriesData('+1')">Następna strona</button>
                    </div>
                    <div class="add-entry">
                        <div class="add-entry-heading">
                            <h3>Dodaj wpis</h3>
                        </div>
                        <form class="add-entry-form" @submit="(e) => sendAddEntry(e)">
                            <label for="newEntryTitle">Tytuł</label>
                            <input type="text" name="newEntryTitle" id="newEntryTitle" style="font-size: 2.5rem;" v-model="entries.newEntryTitle" required>
                            
                            <label for="newEntryShortDescription">krótki opis</label>
                            <textarea name="newEntryShortDescription" id="newEntryShortDescription" cols="30" rows="10" v-model="entries.newEntryShortDescription" required></textarea>

                            <label for="newEntryContent">Treść</label>
                            <!--  <br>(o tym jak tworzyć wpisy przeczytasz tutaj: <RouterLink to="/m2vad/BlogTips" target="_blank">Poradnik</RouterLink>) -->
                            <!-- <div>
                                <button class="preview-content-btn" @click="() => entries.showPopup = true" type="button">Podgląd</button>
                            </div>
                            <textarea name="newEntryContent" id="newEntryContent" cols="30" rows="10" v-model="entries.newEntryContent" required></textarea> -->
                            <ckeditor :editor="editor" v-model="entries.newEntryContent" :config="editorConfig"></ckeditor>

                            <label for="newEntryAuthor">Autor</label>
                            <input type="text" name="newEntryAuthor" id="newEntryAuthor" v-model="entries.newEntryAuthor" required>
                            
                            <label for="newEntryTarget">Odbiorcy</label>
                            <select name="newEntryTarget" style="margin-bottom: 20px;" id="newEntryTarget" v-model="entries.newEntryTarget">
                                <option>do wszystkich</option>
                                <option>do specjalistów</option>
                                <option>do właścicieli zwierząt</option>
                            </select>

                            <label for="newEntryCategory">Kategoria</label>
                            <select name="newEntryCategory" id="newEntryCategory" v-model="entries.newEntryCategory" required>
                                <option :value="cat.id" v-for="cat in categories.categoriesList" :key="cat.id">{{ cat.title }}</option>
                            </select>
                            
                            <label for="newEntryPhoto">Zdjęcie</label>
                            <input type="file" accept="image/*" name="newEntryPhoto" id="newEntryPhoto" ref="newEntryPhoto" @change="() => selectEntryPhoto()" required>

                            <button type="submit">Dodaj</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="blog-edit-categories">
                <h3>Lista kategorii</h3>
                <table class="blog-edit-categories-table">
                    <tr v-for="cat in categories.categoriesList" :key="cat.id"><td>{{ cat.title }} <br> <span class="categories-table-short-desc">{{ cat.short_description }}</span> <br> <span style="font-size: 1.5rem; font-weight: 700;">id: {{ cat.id }}</span></td><td class="categories-list-action-btn" @click="() => setupCategoryEdit(cat.id, cat.title, cat.short_description)"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></td><td class="categories-list-action-btn" @click="() => deleteCategory(cat.id)"><img src="@/assets/icons/trash.svg" alt="usuń"></td></tr>
                </table>
                <button @click="() => {
                    categories.isCategoryAdding = !categories.isCategoryAdding
                    categories.newCategoryId = undefined
                    categories.newCategoryTitle = ''
                    categories.newCategoryDesc = ''
                    }" class="category-add-btn">{{ categories.isCategoryAdding ? 'zamknij' : 'dodaj kategorię' }}</button>
                <div v-if="categories.isCategoryAdding" class="addCategoryForm">
                    <input type="text" placeholder="wpisz tutaj tytuł kategorii" name="newCategoryName" id="newCategory" v-model="categories.newCategoryTitle">
                    <textarea type="text" placeholder="wpisz tutaj opis kategorii" name="newCategoryDesc" id="newCategoryDesc" v-model="categories.newCategoryDesc" cols="30" rows="3"></textarea>
                    <button @click="() => editAddCategory()">dodaj/edytuj</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic'
import { Font } from '@ckeditor/ckeditor5-font'
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { List } from '@ckeditor/ckeditor5-list';
import { Image, ImageInsert } from '@ckeditor/ckeditor5-image';

export default{
    name:"BlogEdit",
    data(){
        return{
            categories: {
                newCategoryDesc: '',
                newCategoryTitle: '',
                isCategoryAdding: false,
                categoriesList: [],
                newCategoryId: undefined,
            },
            entries: {
                entries: [],
                visibleList:{},
                newEntryTitle: '',
                newEntryShortDescription: '',
                newEntryContent: '',
                newEntryAuthor: '',
                newEntryCategory: '',
                showPopup: false,
                newEntryPhoto: null,
                editModeList:{},
                editEntryPhotos:{},
                newEntryTarget: 'do wszystkich',
                page: 1
            },
            searchEntryInput: '',
            editor: ClassicEditor,
            editorConfig: {
                plugins: [ Font, Essentials, Bold, Italic, Link, Paragraph, Heading, BlockQuote, List, ImageInsert, Image ],
                fontColor:{
                    colors: [
                        {
                            color: '#ff0000',
                            label: 'red'
                        },
                        {
                            color: '#00ff00',
                            label: 'green'
                        },
                        {
                            color: '#0000ff',
                            label: 'blue'
                        }
                    ]
                },
                toolbar: [
                    'undo', 'redo', 'heading', 'bold', 'italic', 'fontColor', 'blockQuote', 'bulletedList', 'numberedList', 'link', 'insertImage',
                ]
            }
        }
    },
    props:['isVisible'],
    mounted(){
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
        this.getCategories()
        this.getEntriesData('0')
    },
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    methods:{
        getCategories(){
            const ob = {
                page: 0,
                limit: 50
            }
            axios.post(`/BlogCategorySearch`, ob)
                .then(res => {
                    this.categories.categoriesList = res.data.items
                })
        },
        deleteCategory(id){
            axios.delete(`/BlogCategory/${id}`, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) == '2'){
                        this.getCategories()
                    }
                })
        },
        editAddCategory(){
            if(this.categories.newCategoryTitle !== '' && this.categories.newCategoryDesc !== ''){
                const ob = {
                    title: this.categories.newCategoryTitle,
                    short_description: this.categories.newCategoryDesc
                }
                if(this.categories.newCategoryId !== undefined){
                    ob.id = this.categories.newCategoryId
                }

                axios.post(`/BlogCategory`, ob, authHeader())
                    .then(res => {
                        if(String(res.status).charAt(0) == '2'){
                            this.getCategories()
                            this.categories.isCategoryAdding = false
                            this.categories.newCategoryId = undefined
                            this.categories.newCategoryTitle = ''
                            this.categories.newCategoryDesc = ''
                        }
                    })
            }
        },
        setupCategoryEdit(id, title, desc){
            this.categories.isCategoryAdding = true
            this.categories.newCategoryId = id
            this.categories.newCategoryTitle = title
            this.categories.newCategoryDesc = desc
        },
        getEntriesData(value){
            switch(value){
                case '-1':
                    if(this.page >= 1){
                        this.page -= 1 
                    }
                    break
                case '0':
                    this.page = 1
                    break
                case '+1':
                    this.page += 1 
                    break
            }
            const ob = {
                page: this.page,
                limit: 3
            }
            axios.post(`/BlogPostSearch`, ob, authHeader())
                .then(res => {
                    this.entries.entries = res.data.items
                    this.entries.entries.forEach(x => {
                        this.entries.visibleList[`id${x.id}`] = false
                        this.entries.editModeList[`id${x.id}`] = false
                    })
                })
        },
        selectEntryPhoto(){
            this.entries.newEntryPhoto = this.$refs.newEntryPhoto.files.item(0)
        },
        sendAddEntry(e){
            e.preventDefault()
            // let formData = new FormData()
            const specialconfig = { 
                headers: { 
                    'Authorization': `${authHeader().headers.Authorization}`,
                    'Content-Type': 'multipart/form-data'
                } 
            }
            // formData.append('image', this.entries.newEntryPhoto)
            const ob = {
                title: this.entries.newEntryTitle,
                short_description: this.entries.newEntryShortDescription,
                description: this.entries.newEntryContent,
                author: this.entries.newEntryAuthor,
                target: this.entries.newEntryTarget,
                blog_category_id: Number(this.entries.newEntryCategory),
                photo: this.entries.newEntryPhoto
            }
            axios.post(`/BlogPost`, ob, specialconfig)
                .then(res => {
                    if(String(res.status).charAt(0) === "2"){
                        this.resetEntryAddForm()
                        this.getEntriesData('0')
                        this.$store.commit('setIsSuccess', 'Pomyślnie dodano wpis')
                    }
                })
        },
        resetEntryAddForm(){
            this.entries.newEntryTitle = ''
            this.entries.newEntryAuthor = ''
            this.entries.newEntryCategory = ''
            this.entries.newEntryContent = ''
            this.$refs.newEntryPhoto.value = ''
            this.entries.newEntryTarget = 'do wszystkich'
            this.entries.newEntryShortDescription = ''
            this.entries.newEntryPhoto = null
        },
        editEntry(id){
            const category = this.$refs[`${id}newEditCategory`][0].value
            const title = this.$refs[`${id}newEditTitle`][0].value
            const shortDesc = this.$refs[`${id}newEditShortDesc`][0].value
            const content = this.entries.entries.find(entry => entry.id == id).description
            const target = this.entries.entries.find(entry => entry.id == id).target
            const author = this.$refs[`${id}newEditAuthor`][0].value
            const photo = this.entries.editEntryPhotos[`photo${id}`]
            if(category !== 'Kategoria' && title !== '' && shortDesc !== '' && content !== '' && author !== '' && photo !== undefined){
                const specialconfig = { 
                    headers: { 
                        'Authorization': `${authHeader().headers.Authorization}`,
                        'Content-Type': 'multipart/form-data'
                    } 
                }
                let formData = new FormData()
                formData.append('photo', photo)
                const ob = {
                    id: id,
                    title: title,
                    short_description: shortDesc,
                    description: content,
                    target: target,
                    photo: formData.get('photo'),
                    author: author,
                    blog_category_id: Number(category)
                }
                axios.post(`/BlogPost`, ob, specialconfig)
                    .then(res => {
                        if(String(res.status).charAt(0) == '2'){
                            this.getEntriesData('0')
                            this.entries.editModeList[`id${id}`] = false
                            this.entries.visibleList[`id${id}`] = false
                        }
                    })
            }else{
                // Swal.fire('Uzupełnij wszystki pola', 'pamiętaj również o wybraniu zdjęcia', 'warning')
                this.$store.commit('setIsAlert', 'Uzupełnij wszystki pola i pamiętaj o wybraniu zdjęcia')
            }
        },
        sendEntryEditPhoto(id){
            this.entries.editEntryPhotos[`photo${id}`] = this.$refs[`${id}newEditFile`][0].files.item(0)
        },
        async deleteEntry(id){
            axios.delete(`/BlogPost/${id}`, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.getEntriesData('0')
                    }
                })
        },
        searchEntries(){
            if(this.searchEntryInput.length > 0){
                axios.post(`/BlogPostSearch`, {page: 1, limit: 1000}, authHeader())
                    .then(res => {
                        this.entries.entries = res.data.items.filter(entry => entry.title.toLowerCase().includes(this.searchEntryInput.toLowerCase()))
                        this.entries.entries.forEach(x => {
                            this.entries.visibleList[`id${x.id}`] = false
                            this.entries.editModeList[`id${x.id}`] = false
                        })
                        this.searchEntryInput = ''
                    }).catch(() => {
                        
                    })
            }else{
                this.$store.commit('setIsAlert', 'wpisz co najmniej 1 znak')
            }
        }
    }  
}
</script>

<style scoped>
.blog-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog-edit-cont > textarea{
    resize: vertical;
}
.blog-edit{
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
.blog-edit-categories{
    width: 25%;
    min-width: 275px;
}
.categories-table-short-desc{
    font-size: 1.5rem;
}
.blog-edit-categories-table > tr > td{
    border-bottom: 1px solid black;
    padding: 10px 0;
}
.addCategoryForm > textarea{
    resize: vertical;
    margin: 10px 0;
}
.addCategoryForm > input{
    margin-top: 10px;
}
.addCategoryForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.category-add-btn{
    margin-top: 10px;
}
.categories-list-action-btn{
    cursor: pointer;
}
.entry-item{
    background-color: #eb8b38;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}
.entry-item > b{
    font-size: 1.8rem;
}
.entry-item > p{
    font-size: 1.6rem;
}
.entry-item-hidden-data-toggle-btn{
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.entry-item-hidden-data-toggle-btn > img{
    margin-left: 10px;
}
.blog-edit-entries{
    width: calc(75% - 20px);
    margin-right: 20px;
}
.blog-edit-entries-list{
    display: flex;
    flex-direction: column;
}
.entry-item-hidden-data{
    display: flex;
    flex-direction: column;
}
.entry-item-hidden-data > p{
    font-size: 1.6rem;
}
.entry-item-hidden-data > span{
    font-size: 1.4rem;
}
.entry-item-hidden-data > img{
    width: 80%;
    margin-top: 20px;
}
.entry-action-btn-cont{
    display: flex;
    justify-content: flex-end;
}
.entry-action-btn-cont > button{
    cursor: pointer;
    background: none;
    border: none;
    padding: 10px;
}
.add-entry-form{
    display: flex;
    flex-direction: column;
}

.add-entry-form > input, textarea{
    margin-bottom: 20px;
}
.add-entry-form > textarea{
    resize: vertical;
}
.add-entry-form > textarea#newEntryShortDescription{
    height: 70px;
}
.add-entry-form > button[type='submit']{
    padding: 15px;
}
.add-entry-form > label{
    font-size: 1.7rem;
}
.add-entry{
    margin-top: 30px;
}
.add-entry-heading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.preview-content-btn{
    margin-left: 10px;
    background-color: #0d6efd;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    margin: 10px 0;
}
.preview-popup{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.678);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
}
.preview-popup > div{
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
}
.preview-popup > button{
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
}
.preview-popup > button > img{
    width: 40px;
    height: 40px;
}
.edit-input{
    margin: 10px 0;
}
.entries-pagination > button{
    margin: 0 10px;
}
</style>