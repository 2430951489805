<template>
    <div v-if="currentUser" class="partners-edit-cont">
        <h2>Edycja partnerów</h2>
        <div class="partner-edit" v-if="isEdit">
            <h3>Edycja Partnera</h3>
            <form @submit="(e) => editPartner(e)" @reset="() => isEdit = false">
                <label for="editPartnerName">Nazwa</label>
                <input type="text" id="editPartnerName" name="editPartnerName" v-model="editPartnerName" required>

                <label for="editPartnerUrl">Odnośnik (jeśli partner nie ma strony, wpisz tutaj "/")</label>
                <input type="text" id="editPartnerUrl" name="editPartnerUrl" v-model="editPartnerUrl" required>

                <label for="editPartnerLogo">Logo</label>
                <input type="file" accept="image/*" id="editPartnerLogo" name="editPartnerLogo" ref="editPartnerLogo" @change="() => uploadEditImage()">

                <div style="margin-top: 10px;">
                    <button type="submit" style="margin-right: 10px;">Edytuj</button>
                    <button type="reset">Anuluj</button>
                </div>
            </form>
        </div>
        <div class="partners-list">
            <div class="partner-item" v-for="item in partnersData" :key="item.id">
                <a :href="item.url">
                    <img :src="item.logo_url" :alt="item.name">
                    <span>{{ item.name }}</span>
                </a>
                <div>
                    <button class="delete-partner-btn" @click="() => deletePartner(item.id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
                    <button class="delete-partner-btn" @click="() => {isEdit = true; editPartnerName = item.name; editPartnerUrl = item.url; editPartnerId = item.id; scrollTop()}"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
                </div>
            </div>
        </div>
        <button @click="() => {showAddPanel = !showAddPanel}">{{ !showAddPanel ? 'Dodaj partnera' : 'zamknij' }}</button>
        <div class="add-partner-cont" v-if="showAddPanel">
            <form @submit="(e) => addPartner(e)">
                <label for="newPartnerName">Nazwa</label>
                <input type="text" id="newPartnerName" name="newPartnerName" v-model="newPartnerName" required>

                <label for="newPartnerUrl">Odnośnik (jeśli partner nie ma strony, wpisz tutaj "/")</label>
                <input type="text" id="newPartnerUrl" name="newPartnerUrl" v-model="newPartnerUrl" required>

                <label for="newPartnerLogo">Logo</label>
                <input type="file" accept="image/*" id="newPartnerLogo" name="newPartnerLogo" ref="newPartnerLogo" @change="() => uploadImage()">

                <button type="submit">Dodaj</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import authHeader from '@/services/auth-header';
export default{
    name:"PartnersEdit",
    data(){
        return{
            showAddPanel: false,
            newPartnerName:'',
            newPartnerUrl: '',
            newPartnerLogo: null,
            partnersData: null,
            editPartnerName: '',
            editPartnerUrl: '',
            isEdit: false,
            editPartnerLogo: null,
            editPartnerId: null
        }
    },
    props:['isVisible'],
    computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
    mounted(){
        if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 1) {
      this.$router.push('/login');
    }
       this.getPartnersData()
    },
    methods:{
        addPartner(e){
            e.preventDefault()
            if(this.newPartnerLogo !== null && this.newPartnerName !== '' && this.newPartnerUrl !== ''){
                let formData = new FormData()
                formData.append('logo', this.newPartnerLogo)
                const ob = {
                    name: this.newPartnerName,
                    url: this.newPartnerUrl,
                    logo: formData.get('logo')
                }
                const config = { 
                    headers: { 
                        'Authorization': `${authHeader().headers.Authorization}`,
                        'Content-Type': 'multipart/form-data'
                    } 
                }
                axios.post(`/Partner`, ob, config)
                    .then(res => {
                        if(String(res.status).charAt(0) === '2'){
                            this.newPartnerName = ''
                            this.newPartnerUrl = ''
                            this.newPartnerLogo = null
                            this.$refs.newPartnerLogo.value = ''
                            this.showAddPanel = false
                            this.getPartnersData()
                        }
                    })
            }
        },
        uploadImage(){
            this.newPartnerLogo = this.$refs.newPartnerLogo.files.item(0)
        },
        uploadEditImage(){
            this.editPartnerLogo = this.$refs.editPartnerLogo.files.item(0)
        },
        getPartnersData(){
            const ob = {
                page: 1,
                limit: 1
            }
            axios.post(`/PartnerSearch`, ob, authHeader())
                .then(res => {
                    const ob = {
                        page: 1,
                        limit: res.data.count
                    }
                    axios.post(`${process.env.VUE_APP_API_BASE_URL}PartnerSearch`, ob, authHeader())
                        .then(res => {
                            this.partnersData = res.data.items
                        })
                })
        },
        deletePartner(id){
            axios.delete(`/Partner/${id}`, authHeader())
                .then(res => {
                    if(String(res.status).charAt(0) === '2'){
                        this.getPartnersData()
                    }
                })
        },
        editPartner(e){
            e.preventDefault()
            if(this.editPartnerName !== '' && this.editPartnerUrl !== ''){
                let formData = new FormData()
                formData.append('editlogo', this.editPartnerLogo)
                const ob = {
                    id: this.editPartnerId,
                    name: this.editPartnerName,
                    url: this.editPartnerUrl,
                }
                if(this.editPartnerLogo){
                    ob.logo = formData.get('editlogo')
                }
                const config = { 
                    headers: { 
                        'Authorization': `${authHeader().headers.Authorization}`,
                        'Content-Type': 'multipart/form-data'
                    } 
                }
                axios.post(`/Partner`, ob, config)
                    .then(res => {
                        if(String(res.status).charAt(0) === '2'){
                            this.$store.commit('setIsSuccess', 'Pomyślnie edytowano partnera')
                            this.isEdit = false
                            this.editPartnerLogo = null
                            this.$refs.editPartnerLogo.value = ''
                            this.getPartnersData()
                        }
                    })
            }
        },
        scrollTop(){
            window.scrollTo(0,0)
        }
    }  
}
</script>

<style scoped>
.partners-edit-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-partner-cont > form{
    display: flex;
    flex-direction: column;
}
.add-partner-cont > form > label{
    font-size: 1.7rem;
    margin-top: 10px;
}
.add-partner-cont > form > button{
    margin-top: 10px;
}
.partner-item > a{
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
}
.partner-item > a > img{
    width: 100%;
}
.partner-item > a > span{
    text-align: center;
}
.partner-item{
    margin: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.partners-list{
    max-width: 645px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.delete-partner-btn{
    background: none;
    border: none;
    cursor: pointer;
}
.partner-edit > form{
    display: flex;
    flex-direction: column;
}
.partner-edit > form > label{
    font-size: 1.7rem;
    margin-top: 10px;
}
.partner-edit > form > button{
    margin-top: 10px;
}
</style>