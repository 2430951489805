<template>
    <div v-if="currentUser" class="announcements-verify">
        <h2>Weryfikowanie ogłoszeń</h2>
        <div class="announcements-search">
            <input type="text" placeholder="szukaj" v-model="searchInput">
            <button @click="() => searchAnnouncement()">Szukaj</button>
            <button @click="() => resetSearch()" class="announcements-search__btn-green">resetuj</button>
            <div class="announcements-search__pages">
              <span>strona:</span>
              <button @click="() => {
                if(page > 1){
                  page -= 1
                  getAnnouncements()
                }
              }"><img src="@/assets/message-arrow.png" alt=""></button>
              <span>{{ page }}/{{ Math.ceil(announcementsCount/100) }}</span>
              <button @click="() => {
                if(page < Math.ceil(announcementsCount/100)){
                  page += 1
                  getAnnouncements()
                }
              }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
            </div>
        </div>
        <div class="announcements-container">
          <AnnouncementCardVue :showActive="true" :showVerify="true" @verifyAnnouncement="(id) => verifyAnnouncement(id)" @deleteAnnouncement="(id) => deleteAnnouncement(id)" v-for="announcement in announcements.slice(null, 50)" :key="announcement.id" :active="announcement.active" :contact="announcement.contact" :target="announcement.target" :category="announcement.category" :img="announcement.photo_url" :longContent="announcement.description" :id="announcement.id" :title="announcement.title" :content="announcement.short_description" :photo="announcement.photo" :removable="true" />
          <div v-if="announcements.length == 0">
            <h2>Nie znaleziono usług</h2>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AnnouncementCardVue from '@/components/SpecialComponents/AnnouncementCard.vue';
import authHeader from '@/services/auth-header';
export default{
  name:'AnnouncementsVerify',
  data(){
    return{
      announcements: [],
      announcementsCount: null,
      searchInput: '',
      page: 1,
    }
  },
  mounted(){
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 1) {
        this.$router.push('/login');
      }
      this.getAnnouncements()
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
  props:{

  },
  methods:{
    searchAnnouncement(){
        if(this.searchInput.length > 2){
          axios.post('/AnnouncementSearch', {
              page: 1,
              limit: 1000,
          }).then(res => {
              if(res.data.count > 1000){
                  axios.post('/AnnouncementSearch', {
                      page: 1,
                      limit: res.data.count,
                  }).then(resp => { 
                      this.announcements = resp.data.items.filter(item => item.title.toLowerCase().includes(this.searchInput.toLowerCase()))
                  })
              }else{
                this.announcements = res.data.items.filter(item => item.title.toLowerCase().includes(this.searchInput.toLowerCase()))
              }
          })
        }else{
          this.$store.commit('setIsAlert', 'wpisz co najmniej 3 litery')
        }
      },
        getAnnouncements(){
            this.announcements = []
            const ob = {
                page: this.page,
                limit: 100
            }
            axios.post('/AnnouncementSearch', ob).then(res => {
                this.announcementsCount = res.data.count
                res.data.items.forEach(item => {
                    const freshAnnouncement = {
                        title: item.title,
                        description: item.description,
                        id: item.id,
                        photo_url: item.photo_url,
                        short_description: item.short_description,
                        target: item.target,
                        category: item.category,
                        contact: item.contact,
                        active: item.active
                    }
                    this.announcements.push(freshAnnouncement)
                })
            })
        },
        resetSearch(){
            this.searchInput = ''
            this.getAnnouncements()
        },
        deleteAnnouncement(id){
          axios.delete(`/Announcement/${id}`, authHeader()).then(res => {
            if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie usunięto ogłoszenie')
                this.getAnnouncements()
            }
        }).catch(() => {
                
            })
        },
        verifyAnnouncement(data){
          axios.get(`/AnnouncementEnableByAdmin/${data.id}`, authHeader())
          .then(res => {
            if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zweryfikowano ogłoszenie')
                this.getAnnouncements()
            }
          }).catch(() => {
                
            })
        }
  },
  components:{ AnnouncementCardVue }
}
</script>

<style scoped lang='scss'>
.announcements-verify{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.announcements-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.announcements-search{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    input{
        padding: 7px;
        border-radius: 10px;
        border: 2px solid #8b8b8b;
        width: 250px;
    }
    button{
        border: none;
        background-color: #f2790f;
        padding: 8px 15px;
        border-radius: 8px;
        cursor: pointer;
    }
    &__btn-green{
        background-color: #5ea01a !important;
    }
    &__pages{
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 10px;
    button{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #cbcbcb;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      img{
        width: 15px;
      }
      img.rotate{
        transform: rotate(180deg);
      }
    }
  }
}
</style>