<template>
        <ModalVue :fields="[
                  {
                    name: 'email',
                    type: 'email',
                    tag: 'input',
                    placeholder: 'adres e-mail',
                    req: true,
                    value: editUser.email,
                    label: 'adres e-mail'
                  }
                ]" title="edytuj dane" button-text="zatwierdź" :isVisible="editUser.showModal" @closeModal="() => {editUser.showModal=false}" @sendData="(data) => handleEditUser(data)"/>
    <div v-if="currentUser" class="users-list">
        <div class="users-list-header">
            <div class="users-header-top">
                <h2>Użytkownicy</h2>
                <!-- <button @click="() => $router.push('/admin-profile/users/add')">Dodaj użytkowników seryjnie <img src="@/assets/icons/plus.svg" alt=""></button> -->
                <!-- <button @click="downloadUsers">Pobierz dane użytkowników do pliku CSV<img src="@/assets/icons/download.svg" alt=""><img v-if="downloading" src="@/assets/icons/loading.gif"></button> -->
            </div>
            <span>wyszukaj po:</span>
            <select name="filter" id="filter" v-model="searcher.choosedFilter">
                <option :value="item.value" v-for="item, index in searcher.filters" :key="index">{{ item.name }}</option>
            </select>
            <input type="text" v-model="searcher.searchPhrase" v-if="searcher.choosedFilter" :placeholder="searcher.filters.filter(item => item.value == searcher.choosedFilter)[0].name">
            <button @click="filterUsers">Szukaj</button>
            <img v-if="searcher.loading" class="loading" src="@/assets/icons/loading.gif" alt="">
            <button @click="() => {
                searcher.searchPhrase = ''
                usersList.page = 1
                getUsersData()
            }">Resetuj filtry</button>
        </div>
        <div class="users-displaying-options">
            <div>
                <span>pokaż na stronie:</span>                
                <select name="usersLimit" id="usersLimit" class="users-limit-select" v-model="usersList.limit" @change="() => {
                    filterUsers()
                }">
                    <option value="10" selected>10</option>
                    <option value="16">16</option>
                    <option value="22">22</option>
                    <option value="28">28</option>
                    <option value="34">34</option>
                </select>
            </div>
            <div>
                <span>strona: {{ usersList.page }}/{{ Math.ceil(usersList.usersCount/usersList.limit) }}</span>
                <div class="users-page-btns">
                    <button @click="() => {
                        if(usersList.page > 1){
                            usersList.page -= 1
                            filterUsers()
                        }
                    }"><img src="@/assets/icons/arrow-left-short.svg" alt=""></button>
                    <button @click="() => {
                        if(usersList.page < Math.ceil(usersList.usersCount/usersList.limit)){
                            usersList.page += 1
                            filterUsers()
                        }
                    }"><img src="@/assets/icons/arrow-right-short.svg" alt=""></button>
                </div>
            </div>
        </div>
        <div class="users-list-body">
            <!-- <div class="user-item" v-for="user in usersList.usersList" :key="user.id">
                <div class="user-photo">
                    <img src="@/assets/photo-placeholder.png" alt="">
                </div>
                <div class="user-data">
                    <span style="margin-bottom: 5px; font-size: 1.6rem;">{{ user.role.id == 2 ? 'specjalista' : user.role.id == 3 ? 'usługobiorca' : user.role.id == 4 ? 'pracownik' : 'placówka' }}</span>
                    <b v-if="user.role.id !== 5">{{ user.firstname }} {{ user.lastname }}</b>
                    <b v-if="user.role.id == 5">{{ user.name }}</b>
                    <div>
                        <img src="@/assets/icons/envelope.svg" alt="">
                        <span>{{ user.email }}</span>
                    </div>
                    <div>
                        <img src="@/assets/icons/geo-alt.svg" alt="">
                        <span>{{ user.address ? user.address : 'brak adresu' }}</span>
                    </div>
                    <div>
                        <img src="@/assets/icons/telephone.svg" alt="">
                        <span>{{ user.phone ? user.phone : 'brak numeru telefonu' }}</span>
                    </div>
                </div>
                <div class="user-actions">
                    <button class="red" @click="() => deleteUser(user.id)">usuń</button>
                    <button class="yellow" @click="() => {editUser.showModal = true; editUser.id = user.id; editUser.email = user.email}">edytuj</button>
                    <button v-if="user.role.id == 2 || user.role.id == 4 || user.role.id == 5" @click="() => {$router.push(`/browse/user/${user.id}`)}">Zobacz profil</button>
                </div>
            </div> -->
            <table class="users-table">
                <tr>
                    <th>id</th>
                    <th>typ konta</th>
                    <th>e-mail</th>
                    <th>tel</th>
                    <th>akcje</th>
                </tr>
                <tr v-for="user in usersList.usersList" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.role.id == 2 ? 'specjalista' : user.role.id == 3 ? 'usługobiorca' : user.role.id == 4 ? 'pracownik' : 'placówka' }}</td>
                    <td><RouterLink :to="`/browse/user/${user.id}`">{{ user.email }}</RouterLink></td>
                    <td>{{ user.phone ? user.phone : 'brak' }}</td>
                    <td class="user-actions-btns">
                        <button class="red" @click="() => deleteUser(user.id)">usuń</button>
                        <button class="yellow" @click="() => {editUser.showModal = true; editUser.id = user.id; editUser.email = user.email}">edytuj</button>
                        <button @click="() => sendActivationMail(user.id)">mail aktyw.</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import ModalVue from '@/components/Modal.vue';
import authHeader from '@/services/auth-header';
import axios from 'axios';

export default{
    name: "UsersList",
    data() {
        return {
            searcher: {
                searchPhrase: '',
                choosedFilter: '',
                loading: false,
                filters: [
                    {
                        name: 'adres e-mail',
                        value: 'email'
                    },
                    {
                        name: 'imię i nazwisko',
                        value: 'name'
                    }
                ]
            },
            usersList: {
                usersList: null,
                page: 1,
                limit: 10,
                usersCount: null
            },
            downloading: false,
            editUser:{
                id: null,
                showModal: false,
                email: ''
            }
        };
    },
    props: ['isVisible'],
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 1) {
            this.$router.push('/login');
        }
        this.getUsersData();
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        getUsersData() {
            const ob = {
                page: this.usersList.page,
                limit: this.usersList.limit
            };
            axios.post(`/Users`, ob)
                .then(res => {
                this.usersList.usersList = res.data.items;
                this.usersList.usersCount = res.data.count;
            }).catch(() => {

            });
        },
        filterUsers() {
            this.searcher.loading = true
                const ob = {
                    page: this.usersList.page,
                    limit: this.usersList.limit
                };
                switch (this.searcher.choosedFilter) {
                    case 'email':
                        ob.email = this.searcher.searchPhrase
                        break;
                    case 'name':
                        ob.name = this.searcher.searchPhrase
                        break;
                }
                axios.post(`/Users`, ob).then((res) => {
                    this.searcher.loading = false
                    this.usersList.usersCount = res.data.count
                    this.usersList.usersList = res.data.items
                }).catch(() => {
                    
                });
        },
        downloadUsers() {
            let csv = '';
            this.downloading = true;
            for (let x = 1; x <= Math.ceil(this.usersList.usersCount / 50); x++) {
                setTimeout(() => {
                    const ob = {
                        limit: 50,
                        page: x
                    };
                    axios.post('/Users', ob).then(res => {
                        res.data.items.forEach(item => {
                            Object.keys(item).forEach(key => {
                                csv = `${csv}${csv == '' || csv.split('')[csv.length - 1] == '\n' ? '' : ','}${item[key] == null || typeof item[key] === 'object' ? '' : item[key]}`;
                            });
                            csv = `${csv}\n`;
                        });
                        if (x == Math.ceil(this.usersList.usersCount / 50)) {
                            this.downloading = false;
                            return this.sendToCSV(csv);
                        }
                    }).catch(() => {
                        
                    });
                }, x * 3000);
            }
        },
        sendToCSV(csv) {
            var encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csv}`);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "users.csv");
            document.body.appendChild(link); // Required for FF
            link.click();
        },
        deleteUser(id) {
            const input = prompt('potwierdź wpisując tak');
            if (input == 'tak') {
                axios.delete(`/User/${id}`, authHeader()).then(() => {
                    this.$store.commit('setIsSuccess', 'Pomyślnie usunięto użytkownika');
                    setTimeout(() => {
                        this.getUsersData();
                    }, 500);
                });
            }
        },
        handleEditUser(data){
            axios.get(`/User/${this.editUser.id}`).then(res => {
                const ob = {
                    id: res.data.id,
                    email: data.email,
                    firstname: res.data.firstname,
                    lastname: res.data.lastname,
                    name: res.data.name,
                    phone: res.data.phone,
                    address: res.data.address,
                    nip: res.data.nip,
                    phone_2: res.data.phone2,
                    description: res.data.description,
                    short_description: res.data.short_description,
                    krs: res.data.krs,
                    regon: res.data.regon,
                    pzw_number: res.data.pzw
                }
                res.data.category ? ob.category_id = res.data.category.id : ob.category_id = null;
                res.data.state ? ob.state_id = res.data.state.id : ob.state_id = null;
                axios.post('/UserProfile', ob, authHeader()).then(() => {
                    this.$store.commit('setIsSuccess', 'Pomyślnie zmieniono dane');
                    this.usersList.usersList.find(user => user.id == res.data.id).email = data.email
                }).catch(() => {
                    
                });
            }).catch(() => {
                
            });
        },
        sendActivationMail(id){
            axios.get(`/SendActivationMail/${id}`, authHeader()).then(() => {
                this.$store.commit('setIsSuccess', 'Pomyślnie wysłano maila aktywacyjnego');
            })
        }
    },
    components: { ModalVue }
}
</script>

<style scoped>
.users-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.users-list-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.users-list-header > select{
    padding: 8px;
    width: 260px;
    border-radius: 10px;
}
.users-list-header > input{
    padding: 8px;
    width: 260px;
    border-radius: 10px;
    border: 1px solid rgb(133, 133, 133);
    box-sizing: border-box;
}
.users-list-header > button{
    background-color: #F2790F;
    padding: 8px 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.users-list-body{
    width: 90%;
    max-width: 1225px;
    margin-top: 50px;
    overflow-x: scroll;
    /*     display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1225px;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 50px; */
}
.user-item{
    display: flex;
    max-width: 600px;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: 49%;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #b6b6b6;
    border-radius: 10px;
}
.user-photo{
    border-radius: 50%;
    overflow: hidden;
    width: 130px;
    height: 130px;
}
.user-photo > img{
    width: 130px;
    height: 130px;
    object-fit: cover;
}
.user-data{
    display: flex;
    flex-grow: 3;
    flex-direction: column;
    min-width: 200px;
}
.user-data > b{
    margin-bottom: 15px;
}
.user-data > div{
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    gap: 8px;
}
td.user-actions-btns{
    width: 450px;
}
.users-table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
}
.users-table > tr, td, th{
    border: 1px solid #000;
}
.users-table td{
    padding: 10px;
}
.user-actions-btns > button{
    background-color: #F2790F;
    padding: 3px 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 8px;
}
.user-actions-btns > button.red{
    background-color: #DC3545;
}
.user-actions-btns > button.yellow{
    background-color: #FFC107;
}
.users-displaying-options{
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1225px;
    margin-top: 50px;
}
.users-displaying-options > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.users-limit-select{
    padding: 5px;
    width: 50px;
    border-radius: 8px;
}
.users-page-btns > button{
    background-color: #F2790F;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 10px;
    cursor: pointer;
}
.users-header-top{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}
.users-header-top > button{
    background-color: #5EA01A;
    border-radius: 8px;
    border: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
.loading{
    width: 40px;
}
.users-header-top > button > img{
    width: 30px;
    height: 30px;
}
@media(max-width: 1200px){
    .user-item{
        width: 90%;
    }
}
@media(max-width: 440px){
    .user-data{
        align-items: center;
    }
}
</style>