<template>
  <div class="register-page-wrapper">
    <h1>Zarejestruj konto w m2VET</h1>
    <a href="#info" class="register-page-wrapper__link">Jak się zarejestrować?</a>
    <div class="register-page-wrapper__mode-button">
      <button :class="selectedMode=='priv' ? 'register-page-wrapper__mode-button__selected' : ''" @click="() => {selectedMode='priv'; firmRegistration=false}">Właściciel zwierzęcia</button>
      <button :class="selectedMode=='biz' ? 'register-page-wrapper__mode-button__selected' : ''" @click="() => {selectedMode='biz'; firmRegistration=false}">Usługodawca</button>
      <button :class="selectedMode=='firm' ? 'register-page-wrapper__mode-button__selected' : ''" @click="() => {selectedMode='firm'; firmRegistration=true}">Placówka</button>
    </div>
    <div class="register-page-wrapper__content">
      <Form @submit="handleRegister" :validation-schema="schema" v-if="!firmRegistration">
        <div>
          <div class="form-group">
            <label for="email">email</label>
            <Field name="email" type="email" class="form-control" autocomplete="email"/>
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="firstname">imię</label>
            <Field name="firstname" type="text" class="form-control" autocomplete="name"/>
            <ErrorMessage name="firstname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="lastname">nazwisko</label>
            <Field name="lastname" type="text" class="form-control" autocomplete="family-name"/>
            <ErrorMessage name="lastname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">hasło</label>
            <Field name="password" type="password" class="form-control" autocomplete="new-password"/>
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="passwordConfirmation">Potwierdź hasło</label>
            <Field
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              class="form-control"
            />
            <ErrorMessage name="passwordConfirmation" class="error-feedback" />
          </div>

          <div class="form-group" v-if="selectedMode=='biz'">
            <label for="name">Nazwa działalności</label>
            <Field name="name" type="text" class="form-control" autocomplete="organization"/>
            <ErrorMessage name="name" class="error-feedback" />
          </div>
          <div class="form-group" v-if="selectedMode=='biz'">
            <label for="phone">Numer telefonu</label>
            <Field name="phone" type="tel" class="form-control" autocomplete="tel"/>
            <ErrorMessage name="phone" class="error-feedback" />
          </div>
          <div class="form-group" v-if="selectedMode=='biz'">
            <label for="category">Kategoria</label>
            <Field name="category_id" as="select" class="form-control" v-model="selectedCategory">
              <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
            </Field>
            <ErrorMessage name="category" class="error-feedback" />
          </div>


          <div class="form-group">
            <label style="margin: 10px 0; font-size: 1.8rem;">Rejestrując się, akceptujesz <RouterLink to="/statute">regulamin</RouterLink> i <RouterLink to="/privacy">politykę pywatności</RouterLink> serwisu.</label>
            <span style="margin-top: 10px; font-size: 1.8rem;">{{!isRecommendEmailOpen ? 'Czy ktoś polecił Ci m2VET?' : 'Wpisz adres e-mail osoby polecającej'}}</span>
            <span v-if="!isRecommendEmailOpen" style="margin-bottom: 10px;" class="recommend-open-btn" role="button" @click="isRecommendEmailOpen = true">Tak</span>
            <input v-if="isRecommendEmailOpen" style="margin-bottom: 10px;" type="text" class="recommend-email" placeholder="adres e-mail" v-model="recommendEmail">
            <button :disabled="loading">
              <span
              v-show="loading"
              class="spinner-border"
              ></span>
              Zarejestruj się
            </button>
          </div>
        </div>
      </Form>
      <Form @submit="handleFirmRegister" v-if="firmRegistration" :validation-schema="schemaFirm">
        <div>
          <div class="form-group">
            <label for="email">email</label>
            <Field name="email" type="email" class="form-control" autocomplete="email"/>
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="name">Nazwa działalności</label>
            <Field name="name" type="text" class="form-control" autocomplete="organization"/>
            <ErrorMessage name="name" class="error-feedback" />
          </div>
          <div class="form-group">
              <label for="password">hasło</label>
              <Field name="password" type="password" class="form-control" autocomplete="new-password"/>
              <ErrorMessage name="password" class="error-feedback" />
            </div>
            <div class="form-group">
              <label for="passwordConfirmation">Potwierdź hasło</label>
              <Field
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                class="form-control"
              />
              <ErrorMessage name="passwordConfirmation" class="error-feedback" />
          </div>
  
          <div class="form-group">
            <label style="margin: 10px 0; font-size: 1.8rem;">Rejestrując się, akceptujesz <RouterLink to="/statute">regulamin</RouterLink> i <RouterLink to="/privacy">politykę pywatności</RouterLink> serwisu.</label>
            <span style="margin-top: 10px; font-size: 1.8rem;">{{!isRecommendEmailOpen ? 'Czy ktoś polecił Ci m2VET?' : 'Wpisz adres e-mail osoby polecającej'}}</span>
            <span v-if="!isRecommendEmailOpen" style="margin-bottom: 10px;" class="recommend-open-btn" role="button" @click="isRecommendEmailOpen = true">Tak</span>
            <input v-if="isRecommendEmailOpen" style="margin-bottom: 10px;" type="text" class="recommend-email" placeholder="adres e-mail" v-model="recommendEmail">
            <button :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border"
              ></span>
              Zarejestruj się
            </button>
          </div>
        </div>
      </Form>
      <div class="info-container" id="info">
        <h3>Właściciel zwierzęcia</h3>
        <p>
          Jeśli chcesz mieć dostęp do funkcji przeznaczonych dla właścicieli zwierząt, 
          (wizyty, ogłoszenia, wiadomości, zalecenia) ten rodzaj konta jest dla Ciebie! 
          Dzięki temu kontu umówisz się na wizyty, umieścisz ogłoszenia sprzedaży i przejrzysz 
          zalecenia dla twojego pupila.
        </p>
        <h3>Usługodawca</h3>
        <p>
          Prowadzisz działalność lub po prostu przyjmujesz k
          lientów w branży zwierzęcej? Ten rodzaj konta jest 
          dla Ciebie! Dzięki kontu usługodawcy w m2VET będziesz 
          mógł/a dodawać usługi i prowadzić do nich kalendarz. 
          Możliwe będzie dodawanie ogłoszeń i zaleceń dla Twoich klientów. 
          Ponadto będziesz posiadał/a własną wizytówkę biznesu.
        </p>
        <h3>Placówka</h3>
        <p>
          Ten rodzaj konta skierowany jest do placówek, w których przyjmują specjaliści. Konto to daje 
          możliwość stworzenia własnej wizytówki i przypisania specjalistów, którzy przyjmują w placówce.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from 'axios';
import { RouterLink } from 'vue-router';

export default{
  name:'RegistrationPage',
  setup () {
    useMeta({
      title: 'Zarejestruj się',
      meta: [{ name: 'robots', Name: 'robots', content: 'all' }]
    })
  },
  data(){
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("email jest wymagany")
        .email("email jest nieprawidłowy")
        .max(50, "maksymalnie 50 znaków")
        .min(6, "minimum 6 znaków"),
      firstname: yup
        .string()
        .required("imię jest wymagane")
        .min(3, "minimum 3 znaki")
        .max(30, "maksymalnie 30 znaków"),
      lastname: yup
        .string()
        .required("nazwisko jest wymagane")
        .min(3, "minimum 3 znaki")
        .max(30, "maksymalnie 40 znaków"),
      password: yup
        .string()
        .required("hasło jest wymagane")
        .min(8, "minimum 8 znaków")
        .max(40, "maksymalnie 40 znaków"),
      passwordConfirmation: yup
        .string()
        .required("potwierdź hasło")
        .oneOf([yup.ref('password')], 'Hasła nie są takie same'),
      name: yup
        .string()
        .min(3, "minimum 3 znaki")
        .max(20, "maksymalnie 20 znaków"),
      phone: yup
        .string()
        .length(9, "wpisz numer telefon bez kierunkowego i bez spacji")
        .matches(/(^[0-9]*$)/, 'nieprawidłowy numer telefonu')
    });
    const schemaFirm = yup.object().shape({
      email: yup
        .string()
        .required("email jest wymagany")
        .email("email jest nieprawidłowy")
        .max(50, "maksymalnie 50 znaków")
        .min(6, "minimum 6 znaków"),
      password: yup
        .string()
        .required("hasło jest wymagane")
        .min(8, "minimum 8 znaków")
        .max(40, "maksymalnie 40 znaków"),
      passwordConfirmation: yup
        .string()
        .required("potwierdź hasło")
        .oneOf([yup.ref('password')], 'Hasła nie są takie same'),
      name: yup
        .string()
        .required()
        .min(3, "minimum 3 znaki")
        .max(20, "maksymalnie 20 znaków"),
    })
    return{
      selectedMode: 'biz',
      loading: false,
      schema,
      schemaFirm,
      categories: [],
      selectedCategory: '',
      firmRegistration: false,
      isRecommendEmailOpen: false,
      recommendEmail: ''
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    RouterLink
  },
  props:{

  },
  computed:{
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted(){
    if (this.loggedIn) {
          let user = JSON.parse(localStorage.getItem('userm2VET'));
            if(user.data.role == 3){
                this.$router.push(`/profile`);
            }else if(user.data.role == 2){
                this.$router.push(`/dla-biznesu/profile`);
            }
      }
      this.getCategories()
  },
  methods:{
    handleRegister(user, {resetForm}) {
      this.selectedMode=='priv' ? user.role_id=3 : user.role_id=2
      if(this.recommendEmail){
        user.recommender_email = this.recommendEmail
      }
      if(user.role_id == 2 && (!user.name && !user.phone)){
        this.$store.commit('setIsAlert', 'Wypełnij nazwę firmy i telefon')
      }else{
        this.loading = true;
        this.$store.dispatch("auth/register", user).then(
          () => {
            this.loading = false;
            resetForm()
            this.$store.commit('setIsSuccess', 'Na twój adres e-mail właśnie wysłaliśmy link potwierdzający. Kliknij go.')
            this.$router.push('/')
          },
          (error) => {
            this.loading = false;
            if(String(error.response.status).charAt(0) == '4'){
              this.$store.commit('setIsAlert', 'Wypełnij wszystkie pola')
            }else if(String(error.response.status).charAt(0) == '5'){
              this.$store.commit('setIsAlert', 'Użytkownik o podanym adresie email już istnieje')
            }
          }
        );
      }
    },
    handleFirmRegister(user, {resetForm}){
      user.role_id = 5
      if(this.recommendEmail){
        user.recommender_email = this.recommendEmail
      }
      this.loading = true
      this.$store.dispatch("auth/register", user).then(
        () => {
          this.loading = false
          resetForm()
          this.$store.commit('setIsSuccess', 'Na twój adres e-mail właśnie wysłaliśmy link potwierdzający. Kliknij go.')
          this.$router.push('/')
        },
        (error) => {
          this.loading = false
          if(String(error.response.status).charAt(0) == '5'){
            this.$store.commit('setIsAlert', 'Użytkownik o podanym adresie email już istnieje')
          }
        }
      )
    },
    getCategories(){
      axios.get('/Categories').then(res => {
          res.data.forEach((item) => {
              const ob  = {
                  name: item.name,
                  id: `${item.id}`
              }
              this.categories.push(ob)
          })
          this.selectedCategory = this.categories[this.categories.length-1].id
        })
    }
  }
}
</script>

<style scoped lang='scss'>
.register-page-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__link{
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  &__mode-button{
    width: 600px;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    button{
      width: calc(50% - 5px);
      background: none;
      padding: 10px 0;
      border-radius: 8px;
      border: 1px solid #949494;
      cursor: pointer;
    }
    &__selected{
      background-color: #F2790F !important;
      border: none !important;
    }
  }
  &__content{
    width: 600px;
    margin-top: 30px;
    form{
      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .form-group{
          width: 100%;
          gap: 5px;
          input:not(.recommend-email){
            width: 90%;
            padding: 5px;
            border-radius: 8px;
            border: 1px solid #949494;
          }
          .error-feedback{
            font-size: 1.5rem;
          }
          select{
            width: 90%;
            padding: 5px;
            border-radius: 8px;
            border: 1px solid #949494;
          }
        }
        .form-group:last-child{
          button{
            background-color: #F2790F;
            padding: 8px 20px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  margin-right: 10px;
}

.info-container{
  margin-top: 50px;
}

.recommend-open-btn{
  background-color: #F2790F;
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.recommend-email{
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #949494;
}
</style>