<template>
    <div class="email-preview-modal-container" v-if="isVisible">
        <button @click="() => $emit('closeEmailModal')"><img src="@/assets/icons/x.svg" alt="zamknij"></button>
        <table>
            <tr><th>subskrypcja</th><td>{{ currentSubscriber.subscription.subscription.name }}</td></tr>
            <tr><th>email</th><td>{{ currentSubscriber.email }}</td></tr>
            <tr><th>dodatkowi użytkownicy</th><td>{{ currentSubscriber.subscription.users }}</td></tr>
            <tr><th>data wygaśnięcia</th><td>{{ currentSubscriber.subscription.date }}</td></tr>
            <tr><th>wysłane sms</th><td>{{ currentSubscriber.sent_sms ? currentSubscriber.sent_sms : '0' }}</td></tr>
            <tr><th>do zapłaty (sms)</th><td>{{ currentSubscriber.sent_sms ? currentSubscriber.sent_sms*currentSubscriber.additionalSMS : '0' }} zł</td></tr>
        </table>
    </div>
  </template>
  
  <script>
  export default{
    name:'SubscriptionDetailsModal',
    data(){
      return{
  
      }
    },
    props: ['isVisible', 'currentSubscriber'],
    mounted(){
  
    },
    methods:{
  
    }
  }
  </script>
  
  <style scoped lang='scss'>
  .email-preview-modal-container{
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 15px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      border: 1px solid #000;
      overflow-x: scroll;
      width: 80%;
      max-width: 500px;
      table{
        width: 300px;
        border: 1px solid #000;
        border-collapse: collapse;
        tr, td{
            border: 1px solid #000;
        }
        td{
            padding: 5px;
        }
      }
      button{
          background: none;
          border: none;
          cursor: pointer;
          img{
              width: 25px;
          }
      }
      @media(max-width: 430px){
          justify-content: flex-start;
      }
  }
  </style>