<template>
  <div class="report-error-wrapper" v-if="currentUser">
    <h2>Zgłoś błąd, sugestię</h2>
    <p>W celu poprawy jakości serwisu jesteśmy otwarci na nowe propozycje. Jeśli napotkałeś/aś błąd, zgłoś go tutaj. Z góry dziękujemy za wszystkie zgłoszenia.</p>
    <form @submit.prevent="sendReport">
      <input type="text" placeholder="Temat" v-model="subject" name="subject" required>
      <textarea name="content" id="content" placeholder="Opis" v-model="content" required></textarea>
      <button type="submit">Wyślij <img v-if="loading" src="@/assets/icons/loading.gif" alt="ładowanie..."></button>
      <span>{{ message }}</span>
    </form>
  </div>
  <div v-else>
    <h4>Ładowanie...</h4>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default{
  name:'ReportErrorBiz',
  setup () {
    useMeta({ title: 'Zgłoś błąd' });
  },
  data(){
    return{
      subject: '',
      content: '',
      message: '',
      loading: false
    }
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 2) {
      this.$router.push('/login');
    }
  },
  methods:{
      sendReport(){
          this.loading = true
          axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
              const userData = `email: ${res.data.email} | id: ${this.currentUser.data.user_id}`
              axios.post('/SendEmail', {
                  email: 'pomoc@m2vet.pl',
                  subject: `${this.subject} - ${res.data.email}`,
                  title: `${this.currentUser.data.name} | ${userData} pisze:`,
                  content: `${this.content}`
              }, authHeader()).then(() => {
                  this.message = 'Właśnie otrzymaliśmy twoje zgłoszenie! Odpowiemy na nie jak najszybciej.'
                  this.resetForm()
              }).catch(() => {
                  
                  this.resetForm()
              })
          }).catch(() => {
              
          })
      },
      resetForm(){
          setTimeout(() => {
              this.subject = ''
              this.content = ''
              this.message = ''
              this.loading = false
          }, 3000)
      }
  },
  components:{
      
  }
}
</script>

<style scoped lang='scss'>
.report-error-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  p{
      max-width: 700px;
      text-align: center;
  }
  form{
      background-color: #F3F3F3;
      box-shadow: -1px 1px 7px 0px #c9c9c9;
      border-radius: 15px;
      max-width: 700px;
      width: 90%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
      input, textarea{
          border-radius: 8px;
          border: 1px solid #B1B1B1;
          width: calc(100% - 20px);
          padding: 8px;
      }
      textarea{
          height: 250px;
      }
      button{
          background-color: #F2790F;
          border: none;
          padding: 8px 40px;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          img{
              width: 20px;
          }
      }
      span{
          color: #5EA01A;
          font-size: 1.8rem;
      }
  }
}
</style>