<template>
    <ModalVue :fields="[
                  {
                    name: 'firstname',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'imię',
                    req: true,
                    value: firstName,
                    label: 'imię'
                  },
                  {
                    name: 'lastname',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'nazwisko',
                    req: true,
                    value: lastName,
                    label: 'nazwisko'
                  },
                  {
                    name: 'businessName',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'nazwa działalności',
                    req: true,
                    minlength: 3,
                    maxlength: 50,
                    value: name,
                    label: 'nazwa działalności'
                  },
                  {
                    name: 'phone',
                    type: 'tel',
                    tag: 'input',
                    placeholder: 'numer telefonu',
                    req: true,
                    htmlPattern: '[0-9]{9}',
                    value: phone,
                    label: 'numer telefonu'
                  },
                  {
                    name: 'phone2',
                    type: 'tel',
                    tag: 'input',
                    placeholder: 'numer telefonu 2',
                    req: false,
                    htmlPattern: '[0-9]{9}',
                    value: phone2,
                    label: 'numer telefonu 2'
                  },
                  // options
                  {
                    name: 'state',
                    tag: 'select',
                    options: states,
                    value: state,
                    label: 'województwo'
                  },
                  {
                    name: 'address',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'adres',
                    req: false,
                    value: address,
                    label: 'adres'
                  },
                  {
                    name: 'category',
                    tag: 'select',
                    options: categories,
                    value: category,
                    label: 'kategoria'
                  },
                  {
                    name: 'nip',
                    type: 'text',
                    tag: 'input',
                    placeholder: 'nip',
                    req: false,
                    value: nip,
                    label: 'nip'
                  },
                ]" title="edytuj dane" button-text="zatwierdź" :isVisible="isModalVisible" @closeModal="() => {isModalVisible=false}" @sendData="(data) => editUser(data)"/>
    <ModalVue :fields="[
      {
        name:'photo',
        type:'file',
        tag: 'input',
        req: true
      }
    ]" title="prześlij zdjęcie" button-text="wyślij" :is-visible="isPhotoModalVisible" @closeModal="() => {isPhotoModalVisible=false}" @sendData="(data) => sendPhoto(data)"/>
    <ModalVue :fields="[
      {
        name: 'oldpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'obecne hasło'
      },
      {
        name: 'newpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'nowe hasło',
        minlength: 8
      },
      {
        name: 'repeatpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'powtórz nowe hasło',
        minlength: 8
      }
    ]" title="zmnień hasło" :is-visible="isPassModalVisible" button-text="zmień" @closeModal="() => {isPassModalVisible=false}" @sendData="(data) => changePass(data)"/>
    <ModalVue :fields="[
      {
        name: 'merchantId',
        type: 'text',
        tag: 'input',
        req: true,
        placeholder: 'Klucz do zamówień',
        value: merchantId
      },
      {
        name: 'reportsKey',
        type: 'text',
        tag: 'input',
        req: true,
        placeholder: 'Klucz do raportów',
        value: reportsKey
      },
      {
        name: 'crc',
        type: 'text',
        tag: 'input',
        req: true,
        placeholder: 'klucz do CRC',
        value: crc
      }
    ]" title="Dane do bramki płatności" :is-visible="isAPIkeysModalVisible" button-text="zmień" @closeModal="() => {isAPIkeysModalVisible=false}" @sendData="(data) => addAPIkeys(data)"/>
    <div v-if="currentUser" class="priv-profile-user-data" title="zmień hasło">
      <div class="user-data-wrapper" v-if="currentUser.data.role === 2">
        <h2>Twoje dane</h2>
        <div class="user-data-notifications" v-if="!description || !short_description || !address || !state || !nip || !krs || !pzw || (!merchantId || !crc || !reportsKey) || !terms_and_conditions_url || !privacy_policy_url">
          <img src="@/assets/icons/exclamation-triangle-fill.svg" alt="">
          <span>Uzupełnij:</span>
          <ul>
            <li v-if="!description || !short_description">Opisy działalności - zachęć potencjalnych klientów</li>
            <li v-if="!address">Adres gabinetu</li>
            <li v-if="!state">Województwo</li>
            <li v-if="!nip">NIP</li>
            <li v-if="!krs">KRS</li>
            <li v-if="!pzw">numer prawa wykonywania zawodu (jeśli obowiązuje)</li>
            <li v-if="!merchantId || !crc || !reportsKey">Dane do bramki płatności</li>
            <li v-if="!terms_and_conditions_url">Dodaj regulamin</li>
            <li v-if="!privacy_policy_url">Dodaj politykę prywatności</li>
          </ul>
        </div>
        <div class="user-data-container">
          <div class="user-data-container__inner user-data-container__inner--small">
            <button @click="() => isModalVisible=true"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
            <button class="image-btn" @click="() => {
              if(!userPhotoUrl){
                isPhotoModalVisible=true
              }else if(userPhotoUrl){
                removePhoto()
              }
            }">
              <img class="image-btn__edit" src="@/assets/icons/pencil-square.svg" alt="edytuj" v-if="!userPhotoUrl">
              <img class="image-btn__delete" src="@/assets/icons/trash.svg" alt="edytuj" v-if="userPhotoUrl">
              <img class="image-btn__img" :src="userPhotoUrl ? userPhotoUrl : getImageUrl()" alt="">
            </button>
            <b>{{ firstName }} {{ lastName }}</b>
            <span>{{ email }}</span>
            <span style="font-size: 1.7rem;" :class="!name ? 'no-value' : ''">{{ name ? name : 'brak nazwy biznesu' }}</span>
            <span style="font-size: 1.7rem;" :class="!address ? 'no-value' : ''">{{ address ? address : 'brak adresu' }}</span>
            <span style="font-size: 1.7rem;" :class="!phone ? 'no-value' : ''">tel: {{ phone ? phone : 'brak' }}</span>
            <span style="font-size: 1.7rem;" :class="!phone2 ? 'no-value' : ''">tel 2: {{ phone2 ? phone2 : 'brak' }}</span>
            <span style="font-size: 1.7rem;" :class="!category ? 'no-value' : ''">{{ category ? category.name : 'brak kategorii' }}</span>
            <span style="font-size: 1.7rem;" :class="!state ? 'no-value' : ''">{{ state ? state.name : 'brak województwa' }}</span>
            <span style="font-size: 1.7rem;" :class="!nip ? 'no-value' : ''">NIP: {{ nip ? nip : 'brak' }}</span>
            <button class="change-pass-btn" @click="() => isPassModalVisible = true">Zmień hasło</button>
            <button class="open-business-card-btn" @click="() => $router.push(`/browse/user/${currentUser.data.user_id}`)">Twoja Wizytówka</button>
            <button class="open-business-card-btn" @click="() => isAPIkeysModalVisible = true">Dane do bramki płatności</button>
            <button class="delete-account-btn" @click="() => confirmDelete = true">Usuń konto</button>
            <div class="delete-account-confirm" v-if="confirmDelete">
              <span>czy na pewno?</span>
              <span><span @click="deleteAccount">Tak</span> / <span @click="() => confirmDelete = false">Nie</span></span>
            </div>
          </div>
          <div class="user-data-container__inner user-data-container__inner--big">
            <form @submit="(e) => sendDescriptions(e)">
              <label for="shortDescription">Krótki opis działalności</label>
              <textarea name="shortDescription" id="shortDescription" v-model="short_description" maxlength="350" minlength="20"></textarea>
  
              <label for="description">Opis działalności</label>
              <textarea name="description" id="description" v-model="description" maxlength="3000" minlength="80"></textarea>

              <button type="submit">Zatwierdź</button>
            </form>
            <form @submit.prevent="editKrs">
              <label for="krs">KRS</label>
              <input type="text" id="krs" v-model="krs" pattern="[0-9]*$" minlength="9" maxlength="14">
              
              <button type="submit">Zatwierdź krs</button>
            </form>
            <!-- <button class="reset-data-btn" @click="() => {
              resetData({krs: ''})
              krs = ''
            }">resetuj krs</button> -->
            <form @submit.prevent="editRegon">
              <label for="regon">regon</label>
              <input type="text" id="regon" v-model="regon" pattern="^[0-9]{9}">

              <button type="submit">Zatwierdź regon</button>
            </form>
            <form @submit.prevent="editPzw">
              <label for="pzw">Numer prawa wykonywania zawodu (jeśli obowiązuje)</label>
              <input type="text" id="pzw" v-model="pzw" pattern="[0-9]{7}">

              <button type="submit">Zatwierdź PZW</button>
            </form>
            <form @submit.prevent="editFv">
              <label for="fvNip"><b>Dane do faktury (uzupełnij, jeśli są inne niż powyżej)</b></label>
              
              <input type="text" id="fvNip" v-model="fv.nip" placeholder="nip">
              <input type="text" id="fvAddress" v-model="fv.address" placeholder="adres">
              <input type="text" id="fvName" v-model="fv.name" placeholder="nazwa działalności">

              <button type="submit">Zatwierdź</button>
            </form>
            <form @submit.prevent="sendDocuments">
              <label><b>Żeby pobierać zaliczki od klientów, musisz dodać regulamin i politykę prywatności. Wzory tych dokumentów dostępne są pod tymi linkami: regulamin, polityka prywatności. Wszystkie dokumenty muszą być w formacie pdf.</b></label>
              <label v-if="!terms_and_conditions_url" for="termsAndConditions">regulamin</label>
              <a target="blank" :href="terms_and_conditions_url" v-if="terms_and_conditions_url">regulamin</a>
              <div>
                <span style="margin-right: 10px;">{{ terms_and_conditions_url ? 'zmień' : 'dodaj' }} dokument:</span>
                <input @input="(e) => loadTermsAndConditions(e)" type="file" id="termsAndConditions" accept=".pdf">
              </div>
              <label v-if="!privacy_policy_url" for="privacyPolicy">polityka prywatności</label>
              <a target="blank" :href="privacy_policy_url" v-if="privacy_policy_url">polityka prywatności</a>
              <div>
                <span style="margin-right: 10px;">{{ privacy_policy_url ? 'zmień' : 'dodaj' }} dokument:</span>
                <input @input="(e) => loadPrivacyPolicy(e)" type="file" id="privacyPolicy" accept=".pdf">
              </div>
              <button v-if="!privacy_policy_url || !terms_and_conditions_url" type="submit">Wyślij</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>Błąd wczytywania. Spróbuj ponownie</h2>
    </div>
  </template>
  
  <script>
  import ModalVue from '@/components/Modal.vue'
  import authHeader from '@/services/auth-header';
  import axios from 'axios';
  import { useMeta } from 'vue-meta'
  
  export default{
      name: 'BizProfileUserData',
      data() {
          return {
              userPhotoUrl: '',
              userPhotoId: '',
              firstName: '',
              lastName: '',
              email: '',
              isModalVisible: false,
              isPhotoModalVisible: false,
              isPassModalVisible:false,
              name: '',
              address: '',
              description: '',
              short_description: '',
              phone: '',
              category: null,
              state: null,
              states:[],
              categories: [],
              nip: '',
              krs: '',
              regon: '',
              terms_and_conditions_url: '',
              privacy_policy_url: '',
              terms_and_conditions: null,
              privacy_policy: null,
              confirmDelete: false,
              merchantId: null,
              reportsKey: null,
              crc: null,
              fv: {
                nip: '',
                address: '',
                name: ''
              },
              pzw: null,
              phone2: null,
              isAPIkeysModalVisible: false
          };
      },
      setup() {
          useMeta({ title: 'twoje dane' });
      },
      props: {},
      computed: {
          currentUser() {
              return this.$store.state.auth.user;
          }
      },
      mounted() {
          if (!this.currentUser) {
              this.$router.push('/login');
          }
          else if (this.currentUser.data.role !== 2) {
              this.$router.push('/login');
          }
          this.getUserData()
      },
      methods: {
          getImageUrl() {
              return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
          },
          getUserData(){
            axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
              this.firstName = res.data.firstname
              this.lastName = res.data.lastname
              this.email = res.data.email
              this.name = res.data.name
              this.address = res.data.address
              this.description = res.data.description
              this.short_description = res.data.short_description
              this.phone = res.data.phone
              this.category = res.data.category
              if(res.data.state){
                this.state = res.data.state
              }else{
                this.state = {
                  name: null,
                  id: null
                }
              }
              this.nip = res.data.nip
              this.krs = res.data.krs
              this.regon = res.data.regon
              this.terms_and_conditions_url = res.data.terms_and_conditions_url
              this.privacy_policy_url = res.data.privacy_policy_url
              this.merchantId = res.data.merchantId
              this.reportsKey = res.data.reportsKey
              this.crc = res.data.crc
              if(res.data.fv){
                if(res.data.fv.nip){
                  this.fv.nip = res.data.fv.nip
                }
                if(res.data.fv.address){
                  this.fv.address = res.data.fv.address
                }
                if(res.data.fv.name){
                  this.fv.name = res.data.fv.name
                }
              }
              this.pzw = res.data.pzw_number
              this.phone2 = res.data.phone_2
            })
  
            axios.get(`/UserImage/${this.currentUser.data.user_id}`).then(res => {
              if(res.data.length > 0){
                this.userPhotoUrl = res.data[0].photo_url
                this.userPhotoId = res.data[0].id
              }
            })

            axios.get('/State').then(res => {
              res.data.forEach((item) => {
                  const ob = {
                      name: item.name,
                      value: `${item.id}`
                  }
                  this.states.push(ob)
              })
            })

            axios.get('/Categories').then(res => {
              res.data.forEach((item) => {
                const ob  = {
                    name: item.name,
                    value: `${item.id}`
                }
                this.categories.push(ob)
              })
            })
          },
          editUser(data){
            axios.post(`/UserProfile`, {
              id: this.currentUser.data.user_id,
              firstname: data.firstname,
              lastname: data.lastname,
              email: this.email,
              name: data.businessName,
              phone: data.phone,
              category_id: data.category,
              state_id: data.state,
              address: data.address,
              nip: data.nip,
              phone_2: data.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane')
                this.firstName = data.firstname
                this.lastName = data.lastname
                this.name = data.businessName
                this.address = data.address
                this.phone = data.phone
                this.category = this.categories.filter(item => item.value == data.category)[0]
                this.state = this.states.filter(item => item.value == data.state)[0]
                this.nip = data.nip
                this.phone2 = data.phone2
              }
            })
          },
          sendPhoto(data){
            axios.post('/UserImage', {
              user_id: this.currentUser.data.user_id,
              photo: data.photo
            },{ 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }
            ).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie dodano zdjęcie')
                this.userPhotoUrl = URL.createObjectURL(data.photo)
              }
            })
          },
          removePhoto(){
            axios.delete(`/UserImage/${this.userPhotoId}`, authHeader).then((res) => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zdjęcie')
                this.userPhotoUrl = ''
              }
            }).catch(() => {
              
              setTimeout(() => {
                location.reload()
              }, 500)
            })
          },
          loadTermsAndConditions(event){
            this.terms_and_conditions = event.target.files[0]
            axios.post('/UserPdf', {
              user_id: this.currentUser.data.user_id,
              terms_and_conditions: this.terms_and_conditions
            }, { 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }).then(() => {
              this.$store.commit('setIsSuccess', 'Pomyślnie wgrano dokument')
              this.terms_and_conditions_url = URL.createObjectURL(this.terms_and_conditions)
            }).catch(() => {
                
            })
          },
          loadPrivacyPolicy(event){
            this.privacy_policy = event.target.files[0]
            axios.post('/UserPdf', {
              user_id: this.currentUser.data.user_id,
              privacy_policy: this.privacy_policy
            }, { 
                headers:{
                  Authorization: `${authHeader().headers.Authorization}`,
                  ['Content-Type']: 'multipart/form-data'
                }
              }).then(() => {
              this.$store.commit('setIsSuccess', 'Pomyślnie wgrano dokument')
              this.privacy_policy_url = URL.createObjectURL(this.privacy_policy)
            }).catch(() => {
                
            })
          },
          sendDescriptions(e){
            e.preventDefault()
            axios.post(`/UserProfile`, {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano opisy')
              }
            })
          },
          changePass(data){
            axios.post('/ChangePassword', {
              oldpassword: data.oldpassword,
              newpassword: data.newpassword,
              repeatpassword: data.repeatpassword
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) === '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano hasło')
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }
            }).catch(() => {
              
            })
          },
          editKrs(){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano numer krs')
              }
            })
          },
          editRegon(){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano numer regon')
              }
            })
          },
          editPzw(){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano numer PZW')
              }
            })
          },
          deleteAccount(){
            axios.delete(`/User/${this.currentUser.data.user_id}`, authHeader()).then(() => {
              this.$router.push('/')
              localStorage.removeItem('userm2VET');
              window.location.reload()
            })
          },
          editFv(){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              fv:{
                nip: this.fv.nip,
                address: this.fv.address,
                name: this.fv.name
              },
              merchantId: this.merchantId,
              reportsKey: this.reportsKey,
              crc: this.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane do faktury')
              }
            })
          },
          resetData(){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zresetowano')
              }
            })
          },
          addAPIkeys(data){
            axios.post('/UserProfile', {
              id: this.currentUser.data.user_id,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              name: this.name,
              phone: this.phone,
              category_id: this.category.id,
              state_id: this.state.id,
              address: this.address,
              nip: this.nip,
              phone_2: this.phone2,
              description: this.description,
              short_description: this.short_description,
              krs: this.krs,
              regon: this.regon,
              pzw_number: this.pzw,
              merchantId: data.merchantId,
              reportsKey: data.reportsKey,
              crc: data.crc
            }, authHeader()).then(res => {
              if(String(res.status).charAt(0) == '2'){
                this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane do bramki płatności')
                this.crc = data.crc
                this.merchantId = data.merchantId
                this.reportsKey = data.reportsKey
              }
            })
          }
      },
      components: { ModalVue },
  }
  </script>
  
  <style scoped lang='scss'>

  .user-data-notifications{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
    gap: 20px;
    *{
      margin: 0;
    }
    img{
      width: 30px;
      height: 30px;
    }
    span{
      font-weight: bold;
      font-size: 2.3rem;
    }
  }
  .user-data-container{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    flex-wrap: wrap;
    &__inner{
      background-color: #f2790f33;
      border-radius: 20px;
      &--small{
        width: calc(33% - 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        gap: 20px;
        padding-bottom: 35px;
        min-width: 256px;
        max-width: 300px;
        button{
          align-self: flex-end;
          border: none;
          background: none;
          cursor: pointer;
          img{
            width: 15px;
            height: 15px;
            border-radius: 0;
          }
        }
        // img{
        //   width: 120px;
        //   height: 120px;
        //   object-fit: cover;
        //   border-radius: 50%;
        // }
        .image-btn{
          width: 120px;
          height: 120px;
          align-self: center;
          cursor: pointer;
          position: relative;
          &__img{
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 50%;
          }
          &__edit{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
          &__delete{
            width: 20px !important;
            height: 20px !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
          }
        }
        .image-btn:hover{
          .image-btn__edit{
            opacity: 1;
          }
          .image-btn__delete{
            opacity: 1;
          }
        }
      }
      &--big{
        width: calc(66% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 15px;
        min-width: 610px;
        max-width: 780px;
        form{
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          textarea{
            width: 100%;
            max-width: 500px;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #B1B1B1;
            resize: vertical;
            font-size: 1.7rem;
            height: 100px;
          }
          label{
            margin-top: 20px;
          }
          button{
            width: 150px;
            padding: 5px 0;
            border: none;
            background-color: #f2790f;
            border-radius: 8px;
            margin-top: 20px;
            cursor: pointer;
          }
          input[type="file"]{
            width: 100%;
          }
        }
      }
    }
    .no-value{
      color: #B1B1B1;
    }
    .change-pass-btn{
      background-color: #f2790f;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
    .open-business-card-btn{
      background-color: #ffffff;
      border: 1px solid #f2790f;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
    .delete-account-btn{
      background-color: #C82333;
      border: none;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
    .delete-account-confirm{
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      gap: 10px;
      span{
        span{
          cursor: pointer;
          text-decoration: underline;
          font-weight: 900;
        }
      }
    }
  }
  
  .reset-data-btn{
    background-color: #C82333;
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 1.7rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 660px){
    .user-data-container{
      &__inner{
        &--big{
          width: 100%;
          min-width: 0;
        }
        &--small{
          width: 100%;
          min-width: 0;
        }
      }
    }
  }
  </style>