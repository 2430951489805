<template>
    <h2>Zalecenia</h2>
    <div v-if="currentUser && pets">
        <div class="pet-cards-list" v-if="pets.length > 0">
            <div class="pet-cards-list__pet-card" :key="pet.id" v-for="pet in pets">
              <img :src="pet.photo_url ? pet.photo_url : getImageUrl()" alt="">
              <button @click="() => {
                currentPetId = pet.id
                getPetData()
              }" :style="{fontWeight: currentPetId == pet.id ? '900' : '400'}">{{ pet.pet_name }}</button>
            </div>
        </div>
        <div v-if="currentPet">
            <div class="recomms" v-if="currentPet.recommendations.length > 0">
                <div class="recomms__recomm" v-for="recomm in currentPet.recommendations" :key="recomm.id">
                    <div class="recomms__recomm__content">
                    <span>{{ recomm.content.slice(recomm.content.indexOf('|')+1).length > 50 ? `${recomm.content.slice(recomm.content.indexOf('|')+1).slice(0, 50)}...` : recomm.content.slice(recomm.content.indexOf('|')+1) }}</span>
                    <p v-if="recomm.isExpanded && recomm.content.slice(recomm.content.indexOf('|')+1).length > 50">{{ recomm.content.slice(recomm.content.indexOf('|')+1).replace(/\n/g, '<br>') }}</p>
                    <a :href="recomm.photo_url" target="_blank" v-if="recomm.isExpanded && recomm.photo_url">zdjęcie 1</a>
                    <a :href="recomm.second_photo_url" target="_blank" v-if="recomm.isExpanded && recomm.second_photo_url">zdjęcie 2</a>
                    </div>
                    <button class="recomms__recomm__expand" @click="() => recomm.isExpanded = !recomm.isExpanded"><img src="@/assets/message-arrow.png" :style="{'transform': `rotate(${recomm.isExpanded ? '90deg' : '-90deg'})`}" alt="rozwiń"></button>
                </div>
            </div>
            <div class="no-recomms" v-else>
                <h3>Brak zaleceń dla tego zwierzęcia</h3>
            </div>
        </div>
    </div>
    <div v-else>
      <h4>Brak zwierząt</h4>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMeta } from 'vue-meta';
  export default{
    name:'PrivRecommendations',
    setup () {
      useMeta({ title: 'Zalecenia' });
    },
    data(){
      return{
          pets: null,
          currentPetId: null,
          currentPet: null
      }
    },
    computed: {
      currentUser() {
          return this.$store.state.auth.user;
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/login');
      }
      else if (this.currentUser.data.role !== 3) {
        this.$router.push('/login');
      }
      this.getPets()
    },
    methods:{
      getPets(){
        axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            this.pets = res.data.pets
            }).catch(() => {
                
            })
      },
      getImageUrl() {
            return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
        },
      getPetData(){
        axios.get(`/Pet/${this.currentPetId}`).then(res => {
            this.currentPet = res.data[0]
            this.currentPet.recommendations.map(x => {
                x.isExpanded = false
            })
        }).catch(() => {
            
        })
      }
    },
    components:{

    }
  }
  </script>
  
  <style scoped lang='scss'>
    .pet-cards-list{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        &__pet-card{
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border: 2px solid #fff;
            border-radius: 20px;
            padding: 10px 0;
            img{
                width: 120px;
                height: 120px;
                object-fit: cover;
                border-radius: 50%;
            }
            button{
                border: none;
                background: none;
                cursor: pointer;
                    img{
                        border-radius: 0;
                        width: 15px;
                        height: 15px;
                    }
            }
        }
    }
    .recomms{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        &__recomm{
          display: flex;
          justify-content: space-between;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0px 0px 11px 0px rgba(156, 156, 156, 1);
          button{
            border: none;
            background: none;
            cursor: pointer;
            img{
              width: 20px;
            }
          }
        }
    }
    .no-recomms{
        text-align: center;
    }
  </style>