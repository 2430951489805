<template>
  <ModalVue :fields="[
                {
                  name: 'firstname',
                  type: 'text',
                  tag: 'input',
                  placeholder: 'imię',
                  value: firstName,
                  req: true
                },
                {
                  name: 'lastname',
                  type: 'text',
                  tag: 'input',
                  placeholder: 'nazwisko',
                  value: lastName,
                  req: true
                },
                {
                  name: 'phone',
                  type: 'tel',
                  tag: 'input',
                  placeholder: 'numer telefonu',
                  req: true,
                  htmlPattern: '[0-9]{9}',
                  value: phone,
                },
              ]" title="edytuj dane" button-text="zatwierdź" :isVisible="isModalVisible" @closeModal="() => {isModalVisible=false}" @sendData="(data) => editUser(data)"/>
  <ModalVue :fields="[
    {
      name:'photo',
      type:'file',
      tag: 'input',
      req: true
    }
  ]" title="prześlij zdjęcie" button-text="wyślij" :is-visible="isPhotoModalVisible" @closeModal="() => {isPhotoModalVisible=false}" @sendData="(data) => sendPhoto(data)"/>
      <ModalVue :fields="[
      {
        name: 'oldpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'obecne hasło'
      },
      {
        name: 'newpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'nowe hasło',
        minlength: 8
      },
      {
        name: 'repeatnewpassword',
        type: 'password',
        tag: 'input',
        req: true,
        placeholder: 'powtórz nowe hasło',
        minlength: 8
      }
    ]" title="zmnień hasło" :is-visible="isPassModalVisible" button-text="zmień" @closeModal="() => {isPassModalVisible=false}" @sendData="(data) => changePass(data)"/>
    <ModalVue :fields="[
    {
      name: 'petName',
      type: 'text',
      tag: 'input',
      req: true,
      placeholder: 'imię zwierzęcia',
      maxlength: 30
    },
    {
      name:'photo',
      type:'file',
      tag: 'input',
      req: true
    }
  ]" title="dodaj zwierzaka" button-text="dodaj" :is-visible="isPetModalVisible" @closeModal="() => {isPetModalVisible=false}" @sendData="(data) => addPet(data)"/>
  <div v-if="currentUser" class="priv-profile-user-data">
    <div class="user-data-wrapper" v-if="currentUser.data.role === 3">
      <h2>Twoje dane</h2>
      <div class="user-data-container">
        <div class="user-data-container__inner user-data-container__inner--small">
          <button @click="() => isModalVisible=true"><img src="@/assets/icons/pencil-square.svg" alt="edytuj"></button>
          <button class="image-btn" @click="() => {
            if(!userPhotoUrl){
              isPhotoModalVisible=true
            }else if(userPhotoUrl){
              removePhoto()
            }
          }">
            <img class="image-btn__edit" src="@/assets/icons/pencil-square.svg" alt="edytuj" v-if="!userPhotoUrl">
            <img class="image-btn__delete" src="@/assets/icons/trash.svg" alt="edytuj" v-if="userPhotoUrl">
            <img class="image-btn__img" :src="userPhotoUrl ? userPhotoUrl : getImageUrl()" alt="">
          </button>
          <b>{{ firstName }} {{ lastName }}</b>
          <span>{{ email }}</span>
          <span :style="{color: phone ? '#000' : '#898989'}">{{ phone ? phone : 'brak numeru telefonu' }}</span>
          <button class="change-pass-btn" @click="() => isPassModalVisible = true">Zmień hasło</button>
          <button class="delete-account-btn" @click="() => confirmDelete = true">Usuń konto</button>
            <div class="delete-account-confirm" v-if="confirmDelete">
              <span>czy na pewno?</span>
              <span><span @click="deleteAccount">Tak</span> / <span @click="() => confirmDelete = false">Nie</span></span>
            </div>
        </div>
        <div class="user-data-container__inner user-data-container__inner--big">
          <div v-if="pets.length > 0" class="pet-cards-list">
            <div class="user-data-container__inner--big__pet-card" :key="pet.id" v-for="pet in pets">
              <button @click="() => removePet(pet.id)"><img src="@/assets/icons/trash.svg" alt="usuń"></button>
              <img :src="pet.photo_url ? pet.photo_url : getImageUrl()" alt="">
              <span>{{ pet.pet_name }}</span>
            </div>
            <div class="user-data-container__inner--big__pet-card add-pet-to-list" @click="() => isPetModalVisible = true">
              <button><img src="@/assets/icons/plus.svg" alt="dodaj"></button>
              <span>Dodaj zwierzę</span>
            </div>
          </div>
          <div v-else class="user-data-container__inner--big__no-pets"> 
            <button @click="() => isPetModalVisible = true">
              <span>dodaj zwierzaka</span>
              <img src="@/assets/icons/plus.svg" alt="+">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h2>Błąd wczytywania. Spróbuj ponownie</h2>
  </div>
</template>

<script>
import ModalVue from '@/components/Modal.vue'
import authHeader from '@/services/auth-header';
import axios from 'axios';
import { useMeta } from 'vue-meta'

export default{
    name: 'PrivProfileUserData',
    data() {
        return {
            userPhotoUrl: '',
            userPhotoId: '',
            firstName: '',
            lastName: '',
            email: '',
            pets: [],
            phone: '',
            isModalVisible: false,
            isPhotoModalVisible: false,
            isPassModalVisible: false,
            isPetModalVisible: false,
            confirmDelete: false
            // {
            //   id: 1,
            //   photo_url: 'https://cdn.pixabay.com/photo/2019/08/19/07/45/corgi-4415649_1280.jpg',
            //   name: 'Cooper'
            // }
        };
    },
    setup() {
        useMeta({ title: 'twoje dane' });
    },
    props: {},
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 3) {
            this.$router.push('/login');
        }
        this.getUserData()
    },
    methods: {
        getImageUrl() {
            return new URL(`../../assets/photo-placeholder.png`, import.meta.url).href;
        },
        getUserData(){
          axios.get(`/User/${this.currentUser.data.user_id}`).then(res => {
            this.firstName = res.data.firstname
            this.lastName = res.data.lastname
            this.email = res.data.email
            this.pets = res.data.pets
            this.phone = res.data.phone
          })

          axios.get(`/UserImage/${this.currentUser.data.user_id}`).then(res => {
            if(res.data.length > 0){
              this.userPhotoUrl = res.data[0].photo_url
              this.userPhotoId = res.data[0].id
            }
          })
        },
        editUser(data){
          axios.post(`/UserProfile`, {
            id: this.currentUser.data.user_id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: this.email,
            phone: data.phone
          }, authHeader()).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano dane')
              this.firstName = data.firstname
              this.lastName = data.lastname
              this.phone = data.phone
            }
          })
        },
        sendPhoto(data){
          axios.post('/UserImage', {
            user_id: this.currentUser.data.user_id,
            photo: data.photo
          },{ 
              headers:{
                Authorization: `${authHeader().headers.Authorization}`,
                ['Content-Type']: 'multipart/form-data'
              }
            }
          ).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie dodano zdjęcie')
              this.userPhotoUrl = URL.createObjectURL(data.photo)
            }
          })
        },
        removePhoto(){
          axios.delete(`/UserImage/${this.userPhotoId}`, authHeader).then((res) => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zdjęcie')
              this.userPhotoUrl = ''
            }
          }).catch(() => {
            
            setTimeout(() => {
              location.reload()
            }, 500)
          })
        },
        changePass(data){
          axios.post('/ChangePassword', {
            oldpassword: data.oldpassword,
            newpassword: data.newpassword,
            repeatpassword: data.repeatnewpassword
          }, authHeader()).then(res => {
            if(String(res.status).charAt(0) === '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie zaktualizowano hasło')
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }).catch(() => {
            
          })
        },
        addPet(data){
          axios.post('/Pet', {
            pet_name: data.petName,
            photo: data.photo
          },{ 
              headers:{
                Authorization: `${authHeader().headers.Authorization}`,
                ['Content-Type']: 'multipart/form-data'
              }
            }
          ).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie dodano zdjęcie')
              // this.userPhotoUrl = URL.createObjectURL(data.photo)
              this.pets.push({pet_name: data.petName, photo_url: URL.createObjectURL(data.photo)})
            }
          })
        },
        removePet(id){
          axios.delete(`/Pet/${id}`, authHeader()).then(res => {
            if(String(res.status).charAt(0) == '2'){
              this.$store.commit('setIsSuccess', 'Pomyślnie usunięto zwierzę')
              this.pets = this.pets.filter(pet => pet.id !== id)
            }
          }).catch(() => {
            
          })
        },
        deleteAccount(){
            axios.delete(`/User/${this.currentUser.data.user_id}`, authHeader()).then(() => {
              this.$router.push('/')
              localStorage.removeItem('userm2VET');
              window.location.reload()
            })
          }
    },
    components: { ModalVue },
}
</script>

<style scoped lang='scss'>
.user-data-container{
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
  flex-wrap: wrap;
  &__inner{
    background-color: #f2790f33;
    border-radius: 20px;
    &--small{
      width: calc(33% - 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
      gap: 20px;
      padding-bottom: 35px;
      min-width: 256px;
      max-width: 300px;
      button{
        align-self: flex-end;
        border: none;
        background: none;
        cursor: pointer;
        img{
          width: 15px;
          height: 15px;
          border-radius: 0;
        }
      }
      // img{
      //   width: 120px;
      //   height: 120px;
      //   object-fit: cover;
      //   border-radius: 50%;
      // }
      .image-btn{
        width: 120px;
        height: 120px;
        align-self: center;
        cursor: pointer;
        position: relative;
        &__img{
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
        }
        &__edit{
          width: 20px !important;
          height: 20px !important;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: opacity .2s;
        }
        &__delete{
          width: 20px !important;
          height: 20px !important;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: opacity .2s;
        }
      }
      .image-btn:hover{
        .image-btn__edit{
          opacity: 1;
        }
        .image-btn__delete{
          opacity: 1;
        }
      }
    }
    &--big{
      width: calc(66% - 10px);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 15px;
      min-width: 610px;
      max-width: 780px;
      &__pet-card{
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border: 2px solid #fff;
        border-radius: 20px;
        padding: 10px 0;
        img{
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
        }
        button{
          border: none;
          background: none;
          cursor: pointer;
          img{
            border-radius: 0;
            width: 15px;
            height: 15px;
          }
        }
      }
      &__pet-card.add-pet-to-list{
        justify-content: center;
        cursor: pointer;
      }
      &__no-pets{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 150px;
        button{
          background: none;
          border: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          img{
            width: 30px;
            height: 30px;
          }
        }
      }
    }
        .delete-account-btn{
      background-color: #C82333;
      border: none;
      align-self: center;
      padding: 5px 10px;
      font-size: 1.7rem;
      border-radius: 8px;
    }
    .delete-account-confirm{
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      gap: 10px;
      span{
        span{
          cursor: pointer;
          text-decoration: underline;
          font-weight: 900;
        }
      }
    }
  }
  .change-pass-btn{
    background-color: #f2790f;
    align-self: center;
    padding: 5px 10px;
    font-size: 1.7rem;
    border-radius: 8px;
  }
}

.pet-cards-list{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 660px){
  .user-data-container{
    &__inner{
      &--big{
        width: 100%;
        min-width: 0;
      }
      &--small{
        width: 100%;
        min-width: 0;
      }
    }
  }
}
</style>