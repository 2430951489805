<template>
  <h2>Ulubione usługi</h2>
  <ServiceModal/>
  <div v-if="currentUser && favServices.length > 0" class="services-grid">
    <ServiceCardVue @deleteFavService="(id) => deleteFavService(id)" :id="service.itemId" :fav="true" v-for="service in favServices" @openServiceModal="() => $router.push({path: `/profile/fav-services`, query: {serviceID: service.id}})" :key="service.id" :is-advance="service.advanceAmount ? true : false" :price="service.price" :img="service.photo_url" :name="service.name" :state="service.state_name" :ishighlighted="service.active"/>
  </div>
  <div v-else>
    <h4>Brak ulubionych usług</h4>
  </div>
</template>

<script>
import axios from 'axios';
import { useMeta } from 'vue-meta';
import ServiceCardVue from '@/components/SpecialComponents/ServiceCard.vue';
import ServiceModal from '@/components/SpecialComponents/ServiceModal.vue';
import authHeader from '@/services/auth-header';
export default{
  name:'PrivProfileFavServices',
  setup () {
    useMeta({ title: 'Ulubione usługi' });
  },
  data(){
    return{
        favServices: []
    }
  },
  computed: {
    currentUser() {
        return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    else if (this.currentUser.data.role !== 3) {
      this.$router.push('/login');
    }
    this.getFavServices()
  },
  methods:{
    getFavServices(){
        axios.get(`/UserService/${this.currentUser.data.user_id}`)
            .then(res => {
                res.data.forEach(item => {
                  if(item.service){
                    item.service.itemId = item.id
                    this.favServices.push(item.service)
                  }
                })
                axios.get('/State').then(res => {
                    this.favServices.forEach(service => {
                        service.state_name = res.data.filter(state => state.id == service.state_id)[0].name
                    })
                })
            })
    },
    deleteFavService(id){
      axios.delete(`/UserService/${id}`, authHeader()).then(() => {
        this.favServices = this.favServices.filter(serv => serv.itemId !== id)
      })
    }
  },
  components:{
    ServiceCardVue,
    ServiceModal
  }
}
</script>

<style scoped lang='scss'>
.services-grid{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
}
</style>