<template>
    <div class="spec-browser-bar">
        <form @submit.prevent="() => searchSpecialists()" @reset.prevent="resetSearch()">
            <input type="text" placeholder="imię/nazwa działalności" id="name" v-model="name">
            <select id="category" v-model="category" required>
                <option value="0">--wybierz specjalizację--</option>
                <option :value="category.id" :key="category.id" v-for="category in categories">{{ category.name }}</option>
            </select>
            <input type="text" placeholder="miasto" id="city" autocomplete="address-level2" v-model="city">
            <button type="submit">szukaj</button>
            <button type="reset">resetuj</button>
        </form>
    </div>
    <div class="spec-browser-pages">
        <!-- <select name="itemsDisplayed" id="itemsDisplayed" v-model="itemsDisplayed">
            <option value="9">9</option>
            <option value="15">15</option>
            <option value="21">21</option>
            <option value="27">27</option>
            <option value="33">33</option>
            <option value="39">39</option>
        </select> -->
        <div class="spec-browser-pages__pages-select" v-if="showPages">
            <button><img src="@/assets/message-arrow.png" @click="() => {
            if(itemsPage > 1){
                itemsPage -= 1
                searchSpecialists()
            }
            }" alt=""></button>
            <span>{{ itemsPage }}/{{ Math.ceil(itemsCount/Number(itemsDisplayed)) }}</span>
            <button @click="() => {
            if(itemsPage < Math.ceil(itemsCount/Number(itemsDisplayed))){
                itemsPage += 1
                searchSpecialists()
            }
            }"><img class="rotate" src="@/assets/message-arrow.png" alt=""></button>
        </div>
    </div>
    <div class="spec-browser-specs" v-if="users.length > 0">
        <UserCard v-for="user in users" :id="user.id" :key="user.id" :name="user.role_id == 2 || user.role_id == 5 ? user.name : `${user.firstname} ${user.lastname}`" :category="user.category ? user.category.name : 'brak'" :city="user.address ? user.address : 'brak'" :role="user.role_id" :email="user.email" :image-url="user.image ? user.image.photo_url : null"/>
    </div>
    <div v-else class="loading-specs">
        <h2 v-if="loadingSpecs">Ładowanie...</h2>
        <img v-if="loadingSpecs" src="@/assets/icons/loading.gif" alt="">
        <h2 v-if="!loadingSpecs">Brak wyników</h2>
    </div>
    <div class="info">
        <div>
            <img src="@/assets/icons/info-lg.svg" alt="">
            <h3>Kolory kafelek</h3>
        </div>
        <div>
            <span style="background-color: #ffdbbc; color: #ffdbbc;">kolor</span><span> - Specjalista</span>
        </div>
        <div>
            <span style="background-color: #f3f3f3; color: #f3f3f3;">kolor</span><span> - Pracownik</span>
        </div>
        <div>
            <span style="background-color: #c5c5c5; color: #c5c5c5;">kolor</span><span> - Placówka</span>
        </div>
    </div>
</template>

<script>
import UserCard from '@/components/SpecialComponents/UserCard.vue'
import axios from 'axios'
import { useMeta } from 'vue-meta'
export default{
    name: 'SpecBrowserVue',
    setup() {
        useMeta({ title: 'Specjaliści' });
    },
    data() {
        return {
            name: '',
            category: '0',
            city: '',
            categories: null,
            itemsDisplayed: 30,
            itemsPage: 2,
            itemsCount: '',
            users: [],
            showPages: true,
            loadingSpecs: false
        };
    },
    props: {},
    mounted() {
        this.getCategories();
        this.getSpecialists()
    },
    // components:{ ButtonVue, ServiceCardVue, ServiceModal },
    methods: {
        getCategories() {
            axios.get('/Categories').then(res => {
                this.categories = res.data;
            }).catch(() => {
                
            });
        },
        getSpecialists() {
            this.users = [];
            this.loadingSpecs = true
            const reqParams = {
                page: this.itemsPage,
                limit: this.itemsDisplayed
            };
            axios.post('/Users', reqParams).then(res => {
                this.users = res.data.items.filter(item => item.role.id == 2 || item.role.id == 4 || item.role.id == 5);
                this.itemsCount = res.data.count;
                this.loadingSpecs = false
            }).catch(() => {
            });
        },
        searchSpecialists() {
            this.loadingSpecs = true
            this.users = []
            const ob = {
                page: this.itemsPage,
                limit: this.itemsDisplayed
            }
            if(this.city){
                ob.city = this.city
            }
            if(this.name){
                ob.name = this.name
            }
            if(this.category){
                ob.category_id = this.category
            }
            axios.post('/Users', ob).then(res => {
                this.users = res.data.items.filter(item => item.role_id == 2 || item.role_id == 4 || item.role_id == 5)
                this.loadingSpecs = false
                this.itemsCount = res.data.count;
            })
        },
        resetSearch(){
            this.name = ''
            this.category = '0'
            this.city = ''
            this.showPages = true
            this.getSpecialists()
        }
    },
    components: { UserCard }
}
</script>

<style scoped lang='scss'>
.spec-browser-bar{
    form{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        input, select, button{
            border-radius: 8px;
            padding: 5px 15px;
            border: 1px solid #b1b1b1;
        }
        button{
            cursor: pointer;
        }
        select{
            max-width: 290px;
        }
    }
}
.spec-browser-pages{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    select{
        border-radius: 8px;
        padding: 5px 15px;
        border: 1px solid #b1b1b1;
    }
    &__pages-select{
        button{
            background-color: #F2790F;
            border: none;
            border-radius: 50%;
            margin: 0 10px;
            width:30px;
            height: 30px;
            cursor: pointer;
            img{
                width: 15px;
                height: 15px;
                transform: translate(-1px, 1px);
            }
            img.rotate{
                transform: rotate(180deg) translate(-2px, -1px);
            }
        }
    }
}
.spec-browser-specs{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-evenly;
}
.loading-specs{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 50px;
    }
}

.info{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    border: 2px solid #F2790F;
    padding: 20px;
    border-radius: 10px;
    div:first-child{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img{
            width: 50px;
        }
    }
    h3{
        margin: 0;
    }
    div{
        margin-top: 10px;
        span:first-child{
            box-shadow: -1px 1px 7px 0 #c9c9c9;
        }
    }
}
</style>