<template>
<div v-if="currentUser.data.role === 5" class="spec-management">
    <h2>Specjaliści</h2>
    <RouterLink to="/firm-profile/specialists/add"><button class="spec-management__add-btn">Dodaj specjalistę</button></RouterLink>
    <form class="show-only-active-form">
        <input type="checkbox" name="showOnlyActive" id="showOnlyActive" v-model="showOnlyActive" @change="getSpecialists()">
        <span>Pokaż tylko spacjlistów, którzy zaakceptowali zaproszenie.</span>
    </form>
    <div class="spec-management__grid">
        <div class="spec-item" :style="{borderColor: spec.active ? '#5EA01A' : '#F2790F'}" v-for="spec in specialists" :key="spec.id">
            <img src="@/assets/photo-placeholder.png" alt="brak zdjęcia">
            <RouterLink :to="`/browse/user/${spec.business.id}`">{{ spec.business.firstname }} {{ spec.business.lastname }}</RouterLink>
            <button @click="removeSpecialist(spec.business.id)">Usuń</button>
        </div>
    </div>
</div>
<div v-else>
    <h2>Błąd wczytywania. Spróbuj ponownie</h2>
</div>
</template>

<script>
import authHeader from '@/services/auth-header';
import axios from 'axios';
import { useMeta } from 'vue-meta'

export default{
    name: 'SpecialistsManagement',
    data() {
        return {
            showOnlyActive: false,
            specialists: null,
            restSpecialists: null
        };
    },
    setup() {
        useMeta({ title: 'specjaliści' });
    },
    props: {},
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        else if (this.currentUser.data.role !== 5) {
            this.$router.push('/login');
        }
        this.getSpecialists()
    },
    methods: {
        getSpecialists(){
            if(this.specialists && this.showOnlyActive){
                this.specialists = this.specialists.filter(item => item.active)
            }else{
                axios.get(`/FirmBusinessList/${this.currentUser.data.user_id}`, authHeader()).then(res => {
                    this.specialists = res.data.items
                })
            }
        },
        removeSpecialist(id){   
            axios.delete(`/FirmBusiness/${id}`, authHeader()).then(res => {
                if(res && String(res.status).charAt(0) === '2'){
                    this.$store.commit('setIsSuccess', 'Pomyślnie usunięto specjalistę')
                    this.getSpecialists()
                }
            })
        }
    },
    components: {},
}
</script>

<style scoped lang='scss'>
.spec-management{
    &__add-btn{
        background-color: #F2790F;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        padding: 10px 20px;
        font-size: 1.8rem;
    }
    &__grid{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .spec-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border: 2px solid #F2790F;
            border-radius: 15px;
            padding: 10px 30px;
            img{
                border-radius: 50%;
                width: 100px;
                height: 100px;
            }
            button{
                background-color: #DC3545;
                border-radius: 8px;
                cursor: pointer;
                border: none;
                padding: 5px 10px;
                align-self: stretch;
            }
        }
    }
}
.show-only-active-form{
    margin-bottom: 30px;
    margin-top: 10px;
    input{
        margin-right: 5px;
    }
}
</style>